// @flow
import React from "react";
import { withRouter } from "react-router-dom";
import { Container } from "reactstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { LinearProgress, Typography } from "@material-ui/core";

class Loading extends React.Component {
  render() {

    return (
      <Container>
        <div id="circular_progress" 
          style={{
            margin: "24px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%"
          }}>
          <LinearProgress color="primary" style={{
            margin: "24px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            width: "300px" 
          }} />
          <Typography>Carregando...</Typography>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Loading)
);
