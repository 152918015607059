// @flow
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import classNames from "classnames";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import {
  withWidth,
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  Snackbar,
  Button
} from "@material-ui/core";

import { Add as AddIcon, Refresh as RefreshIcon } from "@material-ui/icons";

import Loading from "../../containers/loading";

import {
  doLoadJobs,
  doUpdateJobCandidates,
  doResetJobCandidates,
  closeSnackbar,
  doResetJobState
} from "../../modules/job";

import styles from "./styles";
import Persona from "../../components/Persona";

class Jobs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      jobs: [],
      storedJobs: [],
      loading: true
    };
  }

  componentDidMount() {
    this.props.doResetJobCandidates();
    this.loadJobs();
  }

  handleChangeSearchBox = (evt) => {
    if (evt.target.value === "") {
      this.setState({ jobs: this.state.storedJobs })
    } else {

      const newJobList = this.state.jobs.filter(item => 
      item.name.toLowerCase().includes(evt.target.value.toLowerCase()));

      if (newJobList.length > 0) {
        this.setState({ jobs: newJobList });
      }
    }
  }

  handleNewJob = () => {
    const { history, doResetJobState } = this.props;
    doResetJobState();
    history.push("/job");
  };

  loadJobs = async () => {
    
    const doLoadJobsResult = await doLoadJobs(this.props.token);
    this.setState({
      jobs: doLoadJobsResult,
      storedJobs: doLoadJobsResult,
      loading: false
    });

    doLoadJobsResult.forEach((job, index) => {
      if (this.state.jobs[index].candidates !== undefined) {
        this.props.doUpdateJobCandidates(job.id, this.state.jobs[index].candidates);
      }
    })
  };

  handleRefreshClick = () => {
    this.setState({
      loading: true
    });
    this.loadJobs();
  };

  render() {
    const { classes, width } = this.props;
    const isMobile = ["xs", "sm", "md"].includes(width);

    return (
      <div
        className={classNames(classes.root, {
          [classes.rootFulls]: isMobile
        })}
      >
      <div id="option_button" style={{ width: isMobile ? "100%" : "30%", display: "flex", justifyContent: "space-between" }}>
        <Button
            onClick={() => this.handleNewJob()}
            color="primary"
            variant="contained"
        >
          <AddIcon />  Criar vaga
        </Button>

        {!this.state.loading && this.state.jobs.length > 0 && (
        <>
          <Button
            onClick={this.handleRefreshClick}
            color="primary"
            variant="contained"
          >
            <RefreshIcon /> Atualizar vagas
          </Button>
        </>
        )}
        </div>
        {this.state.loading && <Loading />}

        {!this.state.loading && this.state.jobs.length === 0 && (
          <Persona text={`Clique em "Criar Vaga" para criar uma nova vaga`} />
        )}

        {!this.state.loading &&
          this.state.jobs.length > 0 &&
          this.state.jobs.map((job, key) => (
            <Card
              style={{ cursor: "pointer" }}
              key={key}
              className={classes.card}
              onClick={() => {
                this.props.history.push("/job-summary/" + job.id);
              }}
            >
              <CardHeader
                className={classNames(classes.cardHeader, {
                  [classes.cardHeaderInactive]: !job.active
                })}
                title={job.name}
                subheader={
                  <small>Status: {job.active ? "Ativa" : "Inativa"}</small>
                }
              />
              <CardContent className={classes.cardContent}>
                {job.city && (
                  <p>
                    {job.city.name} - {job.state.initials}
                  </p>
                )}
                {this.props.jobCandidates[job.id] ? (
                  <span className={classes.badge}>
                    Candidatos:{" "}
                    {this.props.jobCandidates[job.id].candidatos.length}
                  </span>
                ) : (
                  <CircularProgress
                    className={classNames(classes.progress)}
                    size={20}
                  />
                )}
              </CardContent>
            </Card>
          ))}

        <Snackbar
          open={this.props.showSnackbar}
          autoHideDuration={3000}
          onClose={this.props.closeSnackbar}
          ContentProps={{
            "aria-describedby": "snackbar-fab-message-id",
            className: classes.snackbarContent
          }}
          message={
            <span id="snackbar-fab-message-id">Vaga criada com sucesso!</span>
          }
          className={classes.snackbar}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.login.token,
  showSnackbar: state.job.showSnackbar,
  jobCandidates: state.job.jobCandidates,
  userInitialized: true
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closeSnackbar,
      doUpdateJobCandidates,
      doResetJobCandidates,
      doResetJobState
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(compose(withStyles(styles), withWidth())(Jobs))
);
