// Material helpers
import {
  Snackbar,
  withStyles,
  withWidth,
  Dialog,
  Slide,
  LinearProgress,
  AppBar,
  Toolbar,
  Typography,
} from "@material-ui/core";

import CircularProgress from '@mui/material/CircularProgress';

import { DataGrid } from '@mui/x-data-grid';

import i18n from "../../util/i18n";

//Externals
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import { bindActionCreators } from "redux";
import Footer from "../../components/Layout/Footer";
import FooterMobile from "../../components/Layout/FooterMobile";
import Sidebar from "../../components/Layout/Sidebar";
//Layout Components
import Topbar from "../../components/Layout/Topbar";
import { doUpdateProfile, forceReloadProfile } from "../../modules/profile";
import { doGetProfile, doGetAllProfileRequests } from "../../modules/user";
import { doUpdateAvailableCredits } from "../../modules/job";
//Component styles
import styles from "./styles";

import { store } from "../../util/store";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const columns = [
  { field: 'user_id', headerName: 'Candidato', width: 100, align: 'center', headerAlign: 'center' },
  { field: 'job', headerName: 'Vaga', width: 110, align: 'center', headerAlign: 'center' },
  { field: 'requested_on', headerName: 'Solicitado em', width: 130, align: 'center', headerAlign: 'center' }
];

class DefaultLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      loadingProfile: true,
      isSnackbarOpen: false,
      current_plan: {
        access_plan: {
          name: '',
          monthly_credits: 0
        },
        available_credits: 0
      },
      openRequestHistory: false,
      openAvailableCredits: false,
      profileRequests: []
    };
  }

  handleChange = () => {
    this.setState({ openRequestHistory: !this.state.openRequestHistory });
  }

  handleModalAvailableCredits = () => {
    this.setState({ openAvailableCredits: !this.state.openAvailableCredits });
  }

  componentDidMount() {

    const isMobile = ["xs", "sm", "md"].includes(this.props.width);
    this.setState({
      isOpen: !isMobile
    });

    if (!this.props.profile) {
      this.getProfile();
    } else {
      this.setState({ loadingProfile: false });
    }
  }

  componentWillMount() {
    const url = `${process.env.REACT_APP_SERVER_URL}/user`;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: store.getState().login.token
      }
    })
    .then((result) => {
      if (result.ok) {
        result.json().then(e => {
          const currentPlanResponse = e.current_plan;
          if (e.current_plan != null) {
            this.setState({ current_plan : currentPlanResponse });
          }
        });
      } else {
        result.text().then(e => {
        });
      }
    })
    .catch(function(error) {

    });

    doGetAllProfileRequests(this.props.token)
    .then(p => {
      const formattedRequests = [];
      p.forEach((element) => {
        formattedRequests.push({
          id: element.id,
          user_id: element.user.publicId,
          job: element.job.name,
          requested_on: element.requested_on
        })
      })

      this.setState({ profileRequests: formattedRequests });
    })
    .catch(error => {
      this.setState({ profileRequests: [] });
      console.error(error);
    });
  }

  getProfile = () => {
    this.setState({ loadingProfile: true });
    doGetProfile(this.props.token)
      .then(p => {
        this.props.doUpdateProfile(p);
        this.setState({ loadingProfile: false });
      })
      .catch(error => {
        console.error(error);
      });
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  handleCloseModal = () => {
    this.setState({ openRequestHistory: false });
  }

  handleCloseAvailableCreditsModal = () => {
    this.setState({ openAvailableCredits: false });
  }

  handleIsVerified = (data) => {
    this.setState({ isSnackbarOpen: !data.verified })
  }

  handleToggleOpen = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  render() {
    const {
      classes,
      children,
      location,
      history,
      profile
    } = this.props;

    const { isOpen, loadingProfile, openRequestHistory, openAvailableCredits, profileRequests, isSnackbarOpen, current_plan } = this.state;

    const isMobile = ["xs", "sm", "md"].includes(this.props.width);
    const shiftTopbar = isOpen && !isMobile;
    const shiftContent = isOpen && !isMobile;
    const shiftSidebar = isMobile;
    const hasChat =
      location.pathname !== "/profile" &&
      location.pathname.search("chat/trabalho") === -1 &&
      location.pathname.search("chat/trabalhador") === -1;

    const today = new Date();
    const futureDate = new Date(today.setMonth(today.getMonth()+1));

    let totalUsedRequests;

    if (profile) {
      totalUsedRequests = profile.userType === "COMPANY" ? current_plan.access_plan.monthly_credits - current_plan.available_credits : 0;
    } else {
      totalUsedRequests = 0;
    }
  
    return (
      <Fragment>
          <Dialog
            TransitionComponent={Transition}
            classes={{ paper: isMobile ? classes.paperMobile : classes.paper }}
            maxWidth={"md"}
            open={openRequestHistory}
            onClose={this.handleCloseModal}
            aria-labelledby="responsive-dialog-title"
          >
            <AppBar position="static">
              <Toolbar>
                <Typography variant="h6" color="inherit" className={classes.title}>
                  {i18n.t("profileRequestsMade")}
                </Typography>
              </Toolbar>
            </AppBar>
            <div id="catt" style={{ height: 500, width: '100%' }}>
              <DataGrid
                rows={profileRequests}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableColumnMenu={true}
              />
            </div>
          </Dialog>

          <Dialog
            TransitionComponent={Transition}
            classes={{ paper: isMobile ? classes.paperMobileCredits : classes.paper }}
            maxWidth={"md"}
            open={openAvailableCredits}
            onClose={this.handleCloseAvailableCreditsModal}
            aria-labelledby="responsive-dialog-title"
          >
            <AppBar position="static">
              <Toolbar>
                <Typography variant="h6" color="inherit" className={classes.title}>
                  { i18n.t("availableCredits") }
                </Typography>
              </Toolbar>
            </AppBar>
            <div className={classes.planItem} style={{ position: "relative", top: "30px" }}>
              <Typography className={classes.listItemText} variant="body1">
                <span className={classes.textLeft} style={{ position: "relative", left: "20px", bottom: "10px" }}><b>{i18n.t("plan")} {current_plan.access_plan.name}</b></span> <span className={classes.textRight} style={{ position: "relative", bottom: "10px", right: "20px" }}>({current_plan.access_plan.monthly_credits} {i18n.t("requests")})</span> 
              </Typography>
            
              <br />
            
              <LinearProgress className={classes.linearBar} variant="determinate" value={(totalUsedRequests * 100) / current_plan.access_plan.monthly_credits} />

              <Typography className={classes.listItemText} variant="body1">
                <span className={classes.textLeft} style={{ position: "relative", left: "25px" }} ><b>{i18n.t("totalUsage")}</b></span> <span className={classes.textRight} style={{ position: "relative", right: "25px" }}>{totalUsedRequests}/{current_plan.access_plan.monthly_credits}</span>
              </Typography>
            
              <br />
            
              <Typography className={classes.listItemText} style={{ position: "relative", top: "15px" }} variant="caption">
                <span className={classes.textLeft} style={{ position: "relative", right: "35px" }}>{i18n.t("nextRecharge")}</span> <span className={classes.textRight}>01/{futureDate.getMonth() + 1}/{futureDate.getFullYear()}</span>
              </Typography>
            </div>
          </Dialog>
        {loadingProfile ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              height: "100vh"
            }}
          >
            <CircularProgress style={{ margin: "15px" }} />
            <img
              src={require("../../images/logo.png")}
              alt="Jobspot logo"
              style={{ maxWidth: "180px" }}
            />
          </div>
        ) : (
          <Fragment>
            <Snackbar
              open={isSnackbarOpen}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              message={i18n.t("accountNotVerifiedMessage")}
              onClose={() => this.setState({ isSnackbarOpen: false })}
              onClick={() => this.setState({ isSnackbarOpen: false })}
              style={{ cursor: "pointer" }}
            />
            <Topbar
              className={classNames(classes.topbar, {
                [classes.topbarShift]: shiftTopbar
              })}
              isSidebarOpen={isOpen}
              onToggleSidebar={this.handleToggleOpen}
              token={this.props.token}
              title="JobSpot"
              updatedData={this.handleIsVerified}
              profileType={this.props.profile.userType}
            />
            <Sidebar
              profile={profile}
              externalProps={this.props}
              current_plan={current_plan}
              openRequestHistory={this.state.openRequestHistory}
              userInitialized={true}
              onChange={this.handleChange}
              className={classNames(classes.sidebar, {
                [classes.sidebarShift]: shiftSidebar
              })}
            />
            <main
              className={classNames(classes.content, {
                [classes.contentShift]: shiftContent,
                [classes.contentFull]: !hasChat
              })}
            >
              {children}
              {location.pathname !== "/profile" &&
                location.pathname.search("chat/trabalho") === -1 &&
                location.pathname.search("chat/trabalhador") === -1 && (
                  <Fragment>
                    {isMobile ? (
                      <FooterMobile
                        location={location}
                        history={history}
                        userInitialized={true}
                        userType={this.props.profile.userType}
                        openRequestHistory={this.handleChange}
                        openAvailableCredits={this.handleModalAvailableCredits}
                      />
                    ) : (
                      <Footer />
                    )}
                  </Fragment>
                )}
            </main>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

DefaultLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  width: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  token: state.login.token,
  userInitialized: true,
  profile: state.profile.profile,
  current_plan: state.profile.profile ? state.profile.profile.current_plan : null
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doUpdateProfile,
      forceReloadProfile,
      doUpdateAvailableCredits
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(compose(withStyles(styles), withWidth())(DefaultLayout))
);
