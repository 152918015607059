import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Alert from '@mui/material/Alert';

import { withStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Fab,
  Chip,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Avatar,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Slide,
  Snackbar
} from "@material-ui/core";

import {
  ExpandMore as ExpandMoreIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  Clear as ClearIcon,
  Save as SaveIcon
} from "@material-ui/icons";

//Component styles
import styles from "./styles";

import {
  doGetJsonProfile,
  doSaveProfile,
  doUpdateJsonProfile
} from "../../../../../../modules/user";
import {
  doRemoveQualification,
  doRemoveOcupation,
  showConfirmDialog,
  hideConfirmDialog,
  doUpdateProfile,
  forceReloadProfile
} from "../../../../../../modules/profile";

import AddProfissao from "../../../AddProfissao";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class ProfileQualificacao extends Component {
  state = {
    edit: false,
    openDialog: false,
    selectedToDelete: null,
    showMsg: false,
    addProfissao: false
  };

  handleSetEdit = () => {
    this.setState({ edit: true });
  };

  handleCancelEdit = () => {
    const { refreshProfile, refreshData } = this.props;
    refreshProfile();
    refreshData();
    this.setState({ edit: false });
  };

  handleCancelDelete = () => {
    this.setState({ openDialog: false, selectedToDelete: null });
  };

  handleDialogConfirm = item => {
    this.setState({ openDialog: true, selectedToDelete: item });
  };

  handleDoDelete = () => {
    const {
      doRemoveQualification,
      doRemoveOcupation,
      field,
      refreshData
    } = this.props;
    const { selectedToDelete } = this.state;

    if (field === "QUALIFICACAO") {
      doRemoveQualification(selectedToDelete);
    } else {
      doRemoveOcupation(selectedToDelete);
    }
    refreshData();
    this.setState({ openDialog: false, selectedToDelete: null });
  };

  handleSaveEdit = () => {
    const {
      profile,
      token,
      doUpdateJsonProfile,
      forceReloadProfile,
      refreshData
    } = this.props;
    const userJsonData = doGetJsonProfile(profile);

    doSaveProfile(userJsonData, token).then(result => {
      doUpdateJsonProfile(result);
      forceReloadProfile(token);

      this.setState({
        showSnackbar: true,
        showMsg: true,
        edit: false
      });

      refreshData();
    });
  };

  handleAddNew = () => {
    const { history, field } = this.props;

    if (field === "QUALIFICACAO") {
      history.replace("/profile-add-qualificacao");
    } else {
      this.handleAddProfissao();
    }
  };

  handleAddProfissao = () => {
    this.setState({ addProfissao: true });
  };

  handleCancelAddProfissao = () => {
    this.setState({ addProfissao: false });
  };

  render() {
    const { classes, title, Icon, data, refreshData, fieldEdited, isIncomplete } = this.props;
    const { edit, openDialog, showMsg, addProfissao } = this.state;

    return (
      <Fragment>
        <Accordion defaultExpanded={fieldEdited}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1c-content"
            id="panel1c-header"
            className={classes.summary}
          >
            <Avatar className={classes.avatar} style={{ backgroundColor: this.props.backgroundColor }}>{Icon}</Avatar>
            <Typography className={classes.secondaryHeading}>
              {title}
            </Typography>
            {isIncomplete && (
              <Alert severity="warning" style={{ opacity: '0.80', height: '30px' }}>Obrigatório</Alert>
            )}
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <List className={classes.subList}>
              {data.map((item, key) => {
                return (
                  <ListItem
                    key={key}
                    style={{
                      paddingTop: 0,
                      paddingBottom: 0,
                      flexWrap: "wrap"
                    }}
                  >
                    <ListItemText
                      primary={
                        <p className={classes.subListTitle}>{item.title}</p>
                      }
                      secondary={
                        <Fragment>
                          {edit && (
                            <IconButton
                              className={classes.button}
                              aria-label="Delete"
                              onClick={() =>
                                this.handleDialogConfirm(item.uuid)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </Fragment>
                      }
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                      }}
                    />
                    <div className={classes.subListItem}>
                      {item.subitens.map((subitem, subkey) => {
                        return (
                          <Chip
                            key={subkey}
                            size="small"
                            color="secondary"
                            label={subitem.text}
                            className={classes.chip}
                          />
                        );
                      })}
                    </div>
                  </ListItem>
                );
              })}
            </List>

            {!edit && (
              <div
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  display: "flex",
                  paddingTop: "15px"
                }}
              >
                <Fab
                  variant="extended"
                  size="medium"
                  color="secondary"
                  aria-label="Edit"
                  className={classes.fab}
                  onClick={() => this.handleAddNew()}
                >
                  <AddIcon className={classes.editIcon} />
                  Adicionar
                </Fab>
                <Fab
                  variant="extended"
                  size="medium"
                  color="primary"
                  aria-label="Edit"
                  className={classes.fab}
                  onClick={this.handleSetEdit}
                >
                  <ClearIcon className={classes.editIcon} />
                  Remover
                </Fab>
              </div>
            )}
            {edit && (
              <div
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  display: "flex",
                  paddingTop: "15px"
                }}
              >
                <Fab
                  size="medium"
                  color="default"
                  aria-label="Remove"
                  className={classes.fab}
                  onClick={this.handleCancelEdit}
                  style={{ backgroundColor: "#e0e0e0" }}
                >
                  <ClearIcon />
                </Fab>
                <Fab
                  variant="extended"
                  size="medium"
                  color="primary"
                  aria-label="Edit"
                  className={classes.fab}
                  onClick={this.handleSaveEdit}
                  style={{ margin: "3px 0" }}
                >
                  <SaveIcon className={classes.editIcon} />
                  Salvar
                </Fab>
              </div>
            )}
          </AccordionDetails>
        </Accordion>

        <Dialog
          open={openDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleCancelDelete()}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Confirmação"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Você tem certeza que deseja remover o registro?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleCancelDelete()} color="primary" variant="outlined">
              Cancelar
            </Button>
            <Button onClick={() => this.handleDoDelete()} color="primary" variant="outlined">
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>

        {addProfissao && (
          <AddProfissao
            CloseDialog={() => this.handleCancelAddProfissao()}
            refreshData={() => refreshData()}
          />
        )}

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          open={showMsg}
          onClose={() => {
            this.setState({ showMsg: false });
          }}
          autoHideDuration={3000}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">Editado com sucesso!</span>}
        />
      </Fragment>
    );
  }
}

ProfileQualificacao.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  refreshData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  token: state.login.token,
  userInitialized: true,
  editing: state.profile.editing,
  qualificationOpen: state.profile.qualificationOpen,
  ocupationOpen: state.profile.ocupationOpen,
  specialNeedsOpen: state.profile.specialNeedsOpen,
  placesInterestOpen: state.profile.placesInterestOpen,
  contractingModalitiesOpen: state.profile.contractingModalitiesOpen,
  shiftPreferencesOpen: state.profile.shiftPreferencesOpen,
  skillsOpen: state.profile.skillsOpen,
  confirmOpen: state.profile.confirmOpen,
  confirmTitle: state.profile.confirmTitle,
  confirmText: state.profile.confirmText,
  confirmKey: state.profile.confirmKey,
  confirmObjId: state.profile.confirmObjId,
  profile: state.profile.profile,
  profileQualification: state.profile.profileQualification,
  profileOcupation: state.profile.profileOcupation,
  profileSpecialNeeds: state.profile.profileSpecialNeeds,
  profilePlacesInterest: state.profile.profilePlacesInterest,
  profileContractingModalities: state.profile.profileContractingModalities,
  profileShiftPreferences: state.profile.profileShiftPreferences,
  profileSkills: state.profile.profileSkills
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doUpdateJsonProfile,
      doRemoveQualification,
      doRemoveOcupation,
      showConfirmDialog,
      hideConfirmDialog,
      doUpdateProfile,
      forceReloadProfile
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ProfileQualificacao))
);
