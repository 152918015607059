// @flow
import moment from "moment";
import { replace } from "react-router-redux";
import i18n from "../util/i18n";
import { isCpfCnpjValid, isPasswordValid, isEmailValid } from "../util/validator";
import { doLoadUser, LOAD_DATA_USER } from "./form-data";
import { LOGIN_SUCCESSFULL } from "./login";

export const CREATE_ACCOUNT_REQUESTED =
  "createAccount/CREATE_ACCOUNT_REQUESTED";
export const CREATE_ACCOUNT_ERROR = "createAccount/CREATE_ACCOUNT_ERROR";
export const CREATE_ACCOUNT_STEP_2_STARTED =
  "createAccount/CREATE_ACCOUNT_STEP_2_STARTED";
export const CREATE_ACCOUNT_STEP_2_REQUESTED =
  "createAccount/CREATE_ACCOUNT_STEP_2_REQUESTED";
export const CREATE_ACCOUNT_STEP_2_ERROR =
  "createAccount/CREATE_ACCOUNT_STEP_2_ERROR";
export const CREATE_ACCOUNT_STEP_2_SUCCESS =
  "createAccount/CREATE_ACCOUNT_STEP_2_SUCCESS";
export const CREATE_ACCOUNT_UPDATE_DATA =
  "createAccount/CREATE_ACCOUNT_UPDATE_DATA";
export const CLEAR_CREATE_ACCOUNT_MESSAGE =
  "createAccount/CLEAR_CREATE_ACCOUNT_MESSAGE";
export const CREATE_ACCOUNT_STEP_2_COMPANY_ERROR =
  "createAccount/CREATE_ACCOUNT_STEP_2_COMPANY_ERROR";
export const CREATE_ACCOUNT_STEP_2_COMPANY_REQUESTED =
  "createAccount/CREATE_ACCOUNT_STEP_2_COMPANY_REQUESTED";
export const CREATE_ACCOUNT_STEP_2_COMPANY_SUCCESS =
  "createAccount/CREATE_ACCOUNT_STEP_2_COMPANY_SUCCESS";
export const CREATE_ACCOUNT_STEP_3_COMPANY_POLICY =
  "createAccount/CREATE_ACCOUNT_STEP_3_COMPANY_POLICY";

const initialState = {
  phone: "",
  companyName: "",
  password: "",
  passwordConfirmation: "",
  firstNameLastName: "",
  socialNumber: "",
  dateOfBirth: null,
  gender: "",
  state: "",
  city: "",
  email: "",
  emailConfirmation: "",
  isCreating: false,
  message: "",
  showMessage: false,
  userType: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ACCOUNT_UPDATE_DATA:
      return {
        ...state,
        ...action.data
      };

    case CREATE_ACCOUNT_REQUESTED:
      return {
        ...state,
        isCreating: true,
        showMessage: false,
        message: ""
      };

    case CREATE_ACCOUNT_ERROR:
      return {
        ...state,
        isCreating: false,
        showMessage: true,
        message: action.message
      };

    case CREATE_ACCOUNT_STEP_2_STARTED:
      return {
        ...state,
        isCreating: false,
        showMessage: false,
        message: ""
      };

    case CREATE_ACCOUNT_STEP_2_REQUESTED:
      return {
        ...state,
        isCreating: true,
        showMessage: false,
        message: ""
      };
    
    case CREATE_ACCOUNT_STEP_3_COMPANY_POLICY:
      return {
        ...state,
        isCreating: false,
        showMessage: false,
        message: ""
      };

    case CREATE_ACCOUNT_STEP_2_COMPANY_REQUESTED:
      return {
        ...state,
        isCreating: true,
        showMessage: false,
        message: ""
      };

    case CREATE_ACCOUNT_STEP_2_SUCCESS:
      return {
        ...state,
        token: action.token,
        isCreating: true,
        showMessage: false,
        message: ""
      };

    case CREATE_ACCOUNT_STEP_2_COMPANY_SUCCESS:
      return {
        ...state,
        token: action.token,
        isCreating: true,
        showMessage: false,
        message: ""
      };

    case CREATE_ACCOUNT_STEP_2_ERROR:
      return {
        ...state,
        isCreating: false,
        showMessage: true,
        message: action.message
      };

    case CREATE_ACCOUNT_STEP_2_COMPANY_ERROR:
      return {
        ...state,
        isCreating: false,
        showMessage: true,
        message: action.message
      };

    case CLEAR_CREATE_ACCOUNT_MESSAGE:
      return {
        ...state,
        isCreating: false,
        showMessage: false,
        message: ""
      };

    default:
      return state;
  }
};

export const doUpdateData = data => {
  return dispatch => {
    dispatch({
      type: CREATE_ACCOUNT_UPDATE_DATA,
      data: data
    });
  };
};

export const doCreateAccount = () => {
  return (dispatch, getState) => {
    let data = getState().createAccount;
    if (
      !data.email ||
      !data.emailConfirmation ||
      !data.password ||
      !data.passwordConfirmation
    ) {
      dispatch({
        type: CREATE_ACCOUNT_ERROR,
        message: i18n.t("allFieldsRequired")
      });
    } 
    //else if (data.email.replace(/[^0-9]/g, "").length < 11) {
    //  dispatch({
    //    type: CREATE_ACCOUNT_ERROR,
    //    message: i18n.t("invalidPhone")
    //  });
    // }
    else if (data.email !== data.emailConfirmation) {
      dispatch({
        type: CREATE_ACCOUNT_ERROR,
        message: i18n.t("emailDoNotMatch")
      });
    } else if (data.password !== data.passwordConfirmation) {
      dispatch({
        type: CREATE_ACCOUNT_ERROR,
        message: i18n.t("nonMatchingPasswords")
      });
    } else if (!isPasswordValid(data.password)) {
      dispatch({
        type: CREATE_ACCOUNT_ERROR,
        message: i18n.t("minLengthPassword")
      });
    } else if (
      data.email &&
      data.emailConfirmation &&
      data.password &&
      data.passwordConfirmation &&
      data.email === data.emailConfirmation &&
      data.password === data.passwordConfirmation
    ) {
      dispatch({
        type: CREATE_ACCOUNT_REQUESTED
      });

      doFindByField(
        "/user/find-email/",
        data.email
      ).then(result => {
        if (result.error) {
          dispatch({
            type: CREATE_ACCOUNT_ERROR,
            message: i18n.t("genericError")
          });
        } else if (result.found) {
          dispatch({
            type: CREATE_ACCOUNT_ERROR,
            message: "E-mail já cadastrado"
          });
        } else {
          dispatch({
            type: CREATE_ACCOUNT_STEP_2_STARTED
          });
          if (data.userType === "PERSON")
            dispatch(replace("/create-account-step-2"));
          else if (data.userType === "COMPANY")
            dispatch(replace("/create-account-step-2-company"));
        }
      });
    } else {
      dispatch({
        type: CREATE_ACCOUNT_ERROR,
        message: i18n.t("genericError")
      });
    }
  };
};

export const doClearCreateAccountMessage = () => {
  return (dispatch, getState) => {
    dispatch({
      type: CLEAR_CREATE_ACCOUNT_MESSAGE
    });
  };
};

export const validateFields = async data => {
  return new Promise((resolve, reject) => {
    let url = process.env.REACT_APP_SERVER_URL + "/user/validate-fields";
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(function(result) {
        if (result.ok) {
          result.json().then(e => resolve(e));
        } else {
          result.text().then(e => reject(e));
        }
      })
      .catch(e => reject(e));
  });
};

export const doFindByField = async (urlSuffix, data) => {
  return new Promise((resolve, reject) => {
    var url = process.env.REACT_APP_SERVER_URL + urlSuffix + data;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(result => {
        if (result.ok) {
          result.text().then(data => {
            if (data) {
              resolve({
                found: true,
                error: false
              });
            } else {
              resolve({
                found: false,
                error: false
              });
            }
          });
        } else {
          resolve({
            found: false,
            error: true
          });
        }
      })
      .catch(function(error) {
        resolve({
          found: false,
          error: true
        });
      });
  });
};

export const doCreateAccountStep2Company = (method) => {
  return async (dispatch, getState) => {
    const data = getState().createAccount;
    if (
      !data.companyName ||
      !data.email ||
      !data.firstNameLastName ||
      !data.state ||
      !data.city ||
      !data.socialNumber ||
      !data.userType
    ) {
      dispatch({
        type: CREATE_ACCOUNT_STEP_2_COMPANY_ERROR,
        message: i18n.t("allFieldsRequired")
      });
    } else if (
      data.socialNumber.replace(/[^0-9]/g, "").length !== 14 ||
      !isCpfCnpjValid(data.socialNumber)
    ) {
      dispatch({
        type: CREATE_ACCOUNT_STEP_2_COMPANY_ERROR,
        message: i18n.t("invalidCompanySocialNumber")
      });
    } else if (!isEmailValid(data.email)) {
      dispatch({
        type: CREATE_ACCOUNT_STEP_2_COMPANY_ERROR,
        message: i18n.t("invalidEmail")
      });
    } else if (
      data.companyName &&
      data.email &&
      data.firstNameLastName &&
      data.state &&
      data.city &&
      data.socialNumber &&
      data.userType
    ) 
    
  //  {
  //    dispatch({
  //      type: CREATE_ACCOUNT_STEP_3_COMPANY_POLICY
  //    });
  //    dispatch(replace("/create-account-jobspot-policy"));
  //  } else if (
  //    data.companyName &&
  //    data.email &&
  //    data.firstNameLastName &&
  //    data.state &&
  //    data.city &&
  //    data.socialNumber &&
  //    data.userType &&
  //    finish 
  //   ) 
    
    {
      dispatch({
        type: CREATE_ACCOUNT_STEP_2_COMPANY_REQUESTED
      });
      try {
        const validated = await validateFields({
          socialNumber: data.socialNumber.replace(/[^0-9]/g, ""),
          email: data.email.toLowerCase()
        });
        if (validated.length >= 0) {
          const createRequest = await fetch(
            process.env.REACT_APP_SERVER_URL + `/user?method=email`,
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
              },
              body: JSON.stringify({
                phone: `${data.phone.replace(/[^0-9]/g, "")}`,
                password: data.password,
                firstNameLastName: data.firstNameLastName,
                socialNumber: data.socialNumber.replace(/[^0-9]/g, ""),
                state: data.state.id,
                city: data.city.id,
                email: data.email.toLowerCase(),
                companyName: data.companyName,
                userType: data.userType
              })
            }
          );

          if (createRequest.ok) {
            createRequest.text().then(async token => {

              await doLoadUser(token).then(userData => {
                dispatch({
                  type: CREATE_ACCOUNT_STEP_2_COMPANY_SUCCESS,
                  token: token
                });

                dispatch({
                  type: LOAD_DATA_USER,
                  user: userData
                });

                dispatch({
                  type: LOGIN_SUCCESSFULL,
                  token: token
                });

                fetch(`${process.env.REACT_APP_SERVER_URL}/account`, {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                  },
                  body: JSON.stringify({
                    name: data.firstNameLastName,
                    user_id: userData.id,
                    email: data.email.toLowerCase()
                  })
                })
                  .then(resultNode => {
                    dispatch(replace("/jobs"));
                  })
                  .catch(function(error) {
                    dispatch(replace("/jobs"));
                  });
              });
            });
          } else {
            const errorMessage = await createRequest.json();

            dispatch({
              type: CREATE_ACCOUNT_STEP_2_COMPANY_ERROR,
              message: errorMessage.error_text
            });
          }
        } else {
          let u = validated[0];

          if (u.email === data.email) {
            dispatch({
              type: CREATE_ACCOUNT_STEP_2_COMPANY_ERROR,
              message: "E-mail já cadastrado"
            });
          }
          //else if (
          //  u.socialNumber.replace(/[^0-9]/g, "") ===
          //  data.socialNumber.replace(/[^0-9]/g, "")
          //) {
          //  dispatch({
          //    type: CREATE_ACCOUNT_STEP_2_COMPANY_ERROR,
          //    message: "CNPJ já cadastrado"
          //  });
          // }
        }
      } catch (e) {
        dispatch({
          type: CREATE_ACCOUNT_STEP_2_COMPANY_ERROR,
          message: i18n.t("genericError")
        });
      }
    }
  };
};

export const doCreateAccountStep2 = (method) => {
  return (dispatch, getState) => {
    let data = getState().createAccount;

    if (
      !data.firstNameLastName ||
      !data.socialNumber ||
      !data.dateOfBirth ||
      !data.gender ||
      !data.state ||
      !data.city ||
      !data.email ||
      !data.userType
    ) {
      dispatch({
        type: CREATE_ACCOUNT_STEP_2_ERROR,
        message: i18n.t("allFieldsRequired")
      });
    } else if (
      data.socialNumber.replace(/[^0-9]/g, "").length !== 11 ||
      !isCpfCnpjValid(data.socialNumber)
    ) {
      dispatch({
        type: CREATE_ACCOUNT_STEP_2_ERROR,
        message: i18n.t("invalidSocialNumber")
      });
    } else if (data.dateOfBirth.replace(/[^0-9]/g, "").length !== 8) {
      dispatch({
        type: CREATE_ACCOUNT_STEP_2_ERROR,
        message: i18n.t("invalidDateOfBirth")
      });
    } else if (!moment(data.dateOfBirth, "DD/MM/YYYY", true).isValid()) {
      dispatch({
        type: CREATE_ACCOUNT_STEP_2_ERROR,
        message: i18n.t("invalidDateOfBirth")
      });
    } else if (!isEmailValid(data.email)) {
      dispatch({
        type: CREATE_ACCOUNT_STEP_2_ERROR,
        message: i18n.t("invalidEmail")
      });
    } else if (
      data.firstNameLastName &&
      data.socialNumber &&
      data.dateOfBirth &&
      data.gender &&
      data.state &&
      data.city &&
      data.email &&
      data.userType
    ) {
      dispatch({
        type: CREATE_ACCOUNT_STEP_2_REQUESTED
      });

      let phoneNumberClean = `${data.phone.replace(/[^a-zA-Z0-9]/g, '')}`;

      validateFields({
        socialNumber: data.socialNumber.replace(/[^0-9]/g, ""),
        email: data.email.toLowerCase()
      })
        .then(result => {
          if (result.length === 0) {
            let url = `${process.env.REACT_APP_SERVER_URL}/user?method=${method}`;
            fetch(url, {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
              },
              body: JSON.stringify({
                phone: phoneNumberClean,
                password: data.password,
                firstNameLastName: data.firstNameLastName,
                socialNumber: data.socialNumber.replace(/[^0-9]/g, ""),
                dateOfBirth: data.dateOfBirth,
                gender: data.gender,
                state: data.state.id,
                city: data.city.id,
                email: data.email.toLowerCase(),
                userType: data.userType,
                fullAddress: data.fullAddress ? data.fullAddress.trim() : '',
                neighborhood: data.neighborhood ? data.neighborhood.trim() : ''
              })
            })
              .then(result => {
                if (result.ok) {
                  result.text().then(async token => {
                    await doLoadUser(token).then(userData => {
                      dispatch({
                        type: CREATE_ACCOUNT_STEP_2_SUCCESS,
                        token: token
                      });

                      dispatch({
                        type: LOAD_DATA_USER,
                        user: userData
                      });

                      dispatch({
                        type: LOGIN_SUCCESSFULL,
                        token: token
                      });

                      dispatch(replace("/profile"));
                    });
                  });
                } else {
                  dispatch({
                    type: CREATE_ACCOUNT_STEP_2_ERROR,
                    message: i18n.t("genericError")
                  });
                }
              })
              .catch(function(error) {
                dispatch({
                  type: CREATE_ACCOUNT_STEP_2_ERROR,
                  message: i18n.t("genericError")
                });
              });
          } else {
            let u = result[0];

            if (u.email === data.email) {
              dispatch({
                type: CREATE_ACCOUNT_STEP_2_ERROR,
                message: "E-mail já cadastrado"
              });
            } else if (
              u.socialNumber.replace(/[^0-9]/g, "") ===
              data.socialNumber.replace(/[^0-9]/g, "")
            ) {
              dispatch({
                type: CREATE_ACCOUNT_STEP_2_ERROR,
                message: "CPF já cadastrado"
              });
            }
          }
        })
        .catch(error => {
          dispatch({
            type: CREATE_ACCOUNT_STEP_2_ERROR,
            message: i18n.t("genericError")
          });
        });
    }
  };
};
