// @flow
import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Slide,
  SnackbarContent,
  Switch,
  Toolbar,
  Typography,
  withStyles
} from "@material-ui/core";
// Material icons
import { Close as CloseIcon, Warning as WarningIcon } from "@material-ui/icons";
import React, { Component, Fragment } from "react";
//Component styles
import styles from "./styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class DialogLevel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openDialog: true,
      selectedOption: null
    };
  }

  componentDidMount() {
    this.doLoadCard();
    if (this.props.defaultValuesFn && !this.props.jobId)
      this.props.defaultValuesFn(this.props.subOptions);
  }

  doLoadCard = () => {
    const { jobId, active } = this.props;
    if (!jobId && !active) {
      this.handleActiveCard();
    }
  };

  capitalizeFirstLetter = string => {
    if (string)
      return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);

    return "";
  };

  handleClose = () => {
    this.setState({ openDialog: false });
    this.props.actionCard();
  };

  handleActiveCard = () => {
    this.props.toggleCheckboxFn();

    if (!this.props.active) {
      if (this.props.cardTitle === "Faixa Etária") {
        this.props.subOptionsSelectGradeFn("_14a17", 0.5);
        this.props.subOptionsSelectGradeFn("_18a24", 0.5);
        this.props.subOptionsSelectGradeFn("_25a39", 0.5);
        this.props.subOptionsSelectGradeFn("_40a59", 0.5);
        this.props.subOptionsSelectGradeFn("_60Mais", 0.5);
      }

      if (this.props.cardTitle === "Disponibilidade") {
        this.props.subOptionsSelectGradeFn("_HORARIO_COMERCIAL", 0.5);
        this.props.subOptionsSelectGradeFn("_MADRUGADA", 0.5);
        this.props.subOptionsSelectGradeFn("_MANHA", 0.5);
        this.props.subOptionsSelectGradeFn("_NOITE", 0.5);
        this.props.subOptionsSelectGradeFn("_RESID_OUTRO_LOCAL", 0.5);
        this.props.subOptionsSelectGradeFn("_TARDE", 0.5);
        this.props.subOptionsSelectGradeFn("_VIAGEM", 0.5);
      }

      if (this.props.cardTitle === "Contratação") {
        this.props.subOptionsSelectGradeFn("_AUTONOMO", 0.5);
        this.props.subOptionsSelectGradeFn("_CLT", 0.5);
        this.props.subOptionsSelectGradeFn("_DIARISTA", 0.5);
        this.props.subOptionsSelectGradeFn("_ESTAGIARIO", 0.5);
        this.props.subOptionsSelectGradeFn("_FREELANCER", 0.5);
        this.props.subOptionsSelectGradeFn("_PJ", 0.5);
        this.props.subOptionsSelectGradeFn("_TEMPORARIO", 0.5);
        this.props.subOptionsSelectGradeFn("_TERCEIRIZADO", 0.5);
        this.props.subOptionsSelectGradeFn("_TRAINEE", 0.5);
      }

      if (this.props.cardTitle === "Sexo") {
        this.props.subOptionsSelectGradeFn("_FEM", 0.5);
        this.props.subOptionsSelectGradeFn("_MAS", 0.5);
      }
      
    }

    if (this.props.active) {
      if (this.props.cardTitle === "Faixa Etária") {
        this.props.subOptionsSelectGradeFn("_14a17", 0.0);
        this.props.subOptionsSelectGradeFn("_18a24", 0.0);
        this.props.subOptionsSelectGradeFn("_25a39", 0.0);
        this.props.subOptionsSelectGradeFn("_40a59", 0.0);
        this.props.subOptionsSelectGradeFn("_60Mais", 0.0);
      }

      if (this.props.cardTitle === "Disponibilidade") {
        this.props.subOptionsSelectGradeFn("_HORARIO_COMERCIAL", 0.0);
        this.props.subOptionsSelectGradeFn("_MADRUGADA", 0.0);
        this.props.subOptionsSelectGradeFn("_MANHA", 0.0);
        this.props.subOptionsSelectGradeFn("_NOITE", 0.0);
        this.props.subOptionsSelectGradeFn("_RESID_OUTRO_LOCAL", 0.0);
        this.props.subOptionsSelectGradeFn("_TARDE", 0.0);
        this.props.subOptionsSelectGradeFn("_VIAGEM", 0.0);
      }

      if (this.props.cardTitle === "Contratação") {
        this.props.subOptionsSelectGradeFn("_AUTONOMO", 0.0);
        this.props.subOptionsSelectGradeFn("_CLT", 0.0);
        this.props.subOptionsSelectGradeFn("_DIARISTA", 0.0);
        this.props.subOptionsSelectGradeFn("_ESTAGIARIO", 0.0);
        this.props.subOptionsSelectGradeFn("_FREELANCER", 0.0);
        this.props.subOptionsSelectGradeFn("_PJ", 0.0);
        this.props.subOptionsSelectGradeFn("_TEMPORARIO", 0.0);
        this.props.subOptionsSelectGradeFn("_TERCEIRIZADO", 0.0);
        this.props.subOptionsSelectGradeFn("_TRAINEE", 0.0);
      }

      if (this.props.cardTitle === "Sexo") {
        this.props.subOptionsSelectGradeFn("_FEM", 0.0);
        this.props.subOptionsSelectGradeFn("_MAS", 0.0);
      }
    }

    //if (this.props.jobId && this.props.persistJobFn) {
    //  this.props.persistJobFn();
    //}
  };

  handleChangeOption = event => {
    const item = event.target.value;

    // valor para alterar a configuração global

    if (this.props.active && this.props.selectGradeFn) {
      this.props.selectGradeFn(item);
    }

    //if (this.props.active && this.props.jobId && this.props.persistJobFn) {
    //  this.props.persistJobFn();
    //}
    this.setState({ selectedOption: item });
  };

  handleActiveSubItems = (item, vcard) => {
    if (item === "0.50" && !vcard) {
      return true;
    } else {
      if (Number(item) === vcard || item === vcard) {
        return true;
      }
    }
    return false;
  };

  render() {
    const { classes, cardTitle, active, grade } = this.props;
    const { openDialog, selectedOption } = this.state;

    return (
      <Dialog
        TransitionComponent={Transition}
        maxWidth={"md"}
        open={openDialog}
        onClose={this.handleClose}
        aria-labelledby="responsive-dialog-title"
        style={{ minWidth: "350px" }}
      >
        <AppBar position="static">
          <Toolbar>
            <IconButton color="inherit" onClick={() => this.handleClose()}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.title}>
              {cardTitle}
            </Typography>
            <Button color="inherit" onClick={() => this.handleClose()}>
              Confirmar
            </Button>
          </Toolbar>
        </AppBar>

        <DialogTitle id="responsive-dialog-title" className={classes.header}>
          <p>Ativar critério</p>
          <Switch
            color="secondary"
            checked={active}
            onChange={() => this.handleActiveCard()}
          />
        </DialogTitle>

        {active ? (
          <DialogContent className={classes.dialogContent}>
            {!active && (
              <div
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.65)",
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  zIndex: 8889
                }}
              />
            )}

            <h3>{cardTitle}</h3>
            <small>
              Selecione o nível de importância do critério{" "}
              {cardTitle.toLowerCase()}
            </small>
            <Divider style={{ height: 2, marginBottom: 8, marginTop: 8 }} />
            <RadioGroup
              aria-label="position"
              name="position"
              value={selectedOption}
              onChange={this.handleChangeOption}
              row
              className={classes.radioGroup}
            >
              {this.props.importanceRange.map((v, k) => (
                <FormControlLabel
                  key={k}
                  value={v.value}
                  control={<Radio color="primary" />}
                  label={v.text}
                  labelPlacement="top"
                  className={classes.label}
                  readOnly={!active}
                  checked={Number(v.value) === Number(grade)}
                />
              ))}
            </RadioGroup>

            {this.props.subOptions && (
              <div style={{ marginTop: 24 }}>
                <h4>Opções</h4>
                <small>Aqui você seleciona a importância das subopções</small>
                <Divider style={{ height: 2, marginBottom: 8, marginTop: 8 }} />
              </div>
            )}

            {this.props.subOptions &&
              this.props.subOptions.map((v0, k0) => (
                <Fragment key={k0}>
                  <span style={{ fontSize: 20 }}>
                    {this.capitalizeFirstLetter(v0.text)}
                  </span>
                  <div className={classes.levelCard}>
                    {this.props.importanceRange.map((v, k) => (
                      <div key={k} className={classes.levelCardOptions}>
                        <label>{v.text}</label>
                        <Radio
                          color="primary"
                          name={v0.value}
                          value={v.value}
                          readOnly={!active}
                          checked={this.handleActiveSubItems(
                            v.value,
                            this.props.subOptionsSavedValues[v0.value]
                          )}
                          onChange={() => {
                            if (
                              this.props.active &&
                              this.props.subOptionsSelectGradeFn
                            )
                              this.props.subOptionsSelectGradeFn(
                                v0.value,
                                v.value
                              );

                            //if (
                            //  this.props.active &&
                            //  this.props.jobId &&
                            //  this.props.persistJobFn
                            //)
                            //  this.props.persistJobFn();
                          }}
                        />
                      </div>
                    ))}
                  </div>
                  <Divider
                    style={{ height: 10, marginBottom: 15, marginTop: 15 }}
                  />
                </Fragment>
              ))}
          </DialogContent>
        ) : (
          <SnackbarContent
            aria-describedby="client-snackbar"
            style={{
              backgroundColor: "#55738c",
              margin: "20px auto",
              display: "flex"
            }}
            message={
              <span
                id="client-snackbar"
                style={{ display: "flex", alignItems: "center" }}
              >
                <WarningIcon style={{ marginRight: "10px" }} />
                Ative este critério para editar as opções!
              </span>
            }
          />
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(DialogLevel);
