// @flow
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import classNames from "classnames";
import { Slide, Dialog, DialogTitle } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DialogContentText from "@material-ui/core/DialogContentText";
import compose from "recompose/compose";
import LinearProgress from '@mui/material/LinearProgress';
import { withStyles } from "@material-ui/core/styles";
import TopBar from "../../components/TopBar";
import Button from "@material-ui/core/Button";
import {
  withWidth
} from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import i18n from "../../util/i18n";

import {
  doUpdateJobCandidates,
  doResetJobCandidates,
  closeSnackbar,
  doResetJobState
} from "../../modules/job";

import { doLoadUser, doLoadStatesUpdate, doUpdateUser } from "../../modules/form-data";

import styles from "./styles";
import Persona from "../../components/Persona";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

class Update extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();

    this.state = {
      jobs: [],
      storedJobs: [],
      loading: true,
      companyName: '',
      socialNumber: '',
      city: '',
      state: '',
      neighborhood: '',
      fullAddress: '',
      name: '',
      phone: '',
      contactEmail: '',
      states: [],
      cities: [],
      openUpdateModal: false,
      showSuccessIcon: false,
      showFailIcon: false,
      errorMessage: ''
    };
  }

  componentDidMount() {
    doLoadStatesUpdate().then(result => {
      this.setState({ states: result });
    });

    //doLoadStatesUpdate().then(result => {

    //});
    //this.setState({ states: this.props.states });
    //onsole.log('this.props: ', this.props);
    

    doLoadUser(this.props.token).then(userData => {
      this.setState({
        companyName: userData.companyName,
        socialNumber: userData.socialNumber,
        city: userData.city,
        state: userData.state,
        neighborhood: userData.neighborhood,
        fullAddress: userData.fullAddress,
        name: userData.name,
        phone: userData.phone,
        contactEmail: userData.email
      })
    });
  }

  handleIconUpdate = () => {
    if (this.state.showFailIcon) {
      return <ErrorOutlineIcon style={{ color: "red", textAlign: "center" }} />;
    }

    if (this.state.showSuccessIcon) {
      return <CheckCircleOutlineIcon style={{ color: "green", textAlign: "center" }} />;
    }

    if (this.state.openUpdateModal) {
      return <LinearProgress />
    }
  }

  handleDismissButton = () => {
    if (this.state.showFailIcon || this.state.showSuccessIcon) {
      return <Button color="primary" variant="contained" onClick={() => { this.handleClose() }}>Entendido</Button> 
    }
  }

  handleTextUpdate = () => {
    if (this.state.showFailIcon) {
      return <>
        {"Um erro inesperado aconteceu enquanto atualizávamos o seu perfil."}
        <br />
        {"Verifique seus dados ou entre em contato com nosso suporte."}
        <br />
        {"E-mail: contato@jobspot.com.br"}
      </>
    }

    if (this.state.showSuccessIcon) {
      return "Perfil atualizado com sucesso!";
    }

    if (this.state.openUpdateModal) {
      return "Estamos atualizando seu perfil...";
    }
  }

  handleClose = () => {
    if (this.state.showFailIcon || this.state.showSuccessIcon) {
      this.setState({ openUpdateModal: false, showSuccessIcon: false, showFailIcon: false, errorMessage: '' });
    }
  }

  handleUserUpdate = () => {
    if (this.formRef.current.reportValidity()) {

      this.setState({ openUpdateModal: true });

      const updatedUser = {
        phone: this.state.phone,
        id_estado: this.state.state.id,
        id_cidade: this.state.city.id,
        email: this.state.contactEmail,
        company_name: this.state.companyName,
        neighborhood: this.state.neighborhood,
        full_address: this.state.fullAddress,
        name: this.state.name
      };

      doUpdateUser(this.props.token, updatedUser).then(result => {

        if (result.error_code) {
          this.setState({ showFailIcon: true, errorMessage: result.error_message.slice(0, 500) });
        } else {
          this.setState({
            companyName: result.companyName,
            socialNumber: result.socialNumber,
            city: result.city,
            state: result.state,
            neighborhood: result.neighborhood,
            fullAddress: result.fullAddress,
            name: result.name,
            phone: result.phone,
            contactEmail: result.email,
            showSuccessIcon: true
          });
        }
      });
    }
  }

  handleUserUpdateDebug = () => {
    if (this.formRef.current.reportValidity()) {

    }
  }

  render() {
    const { classes, width, history } = this.props;
    const isMobile = ["xs", "sm", "md"].includes(width);

    return (
      <div
        className={classNames(classes.root, {
          [classes.rootFulls]: isMobile
        })}
      >
      <TopBar
        pageTitle={"Editar dados"}
        buttonTitle="Salvar"
        actionButton={() => this.handleUserUpdate()}
        actionBack={() => history.replace("/jobs")}
        hasButton={true}
        hasButtonReview={false}
        buttonColor="primary"
        variant="contained"
      />

      <Persona text={`Utilize essa área para atualizar os dados da empresa vinculada ao seu perfil!`} />

        <div id="data_fields" style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <form noValidate id="form_fields" ref={this.formRef} style={{ display: "flex", flexDirection: "column", gap: "15px", position: "relative", right: isMobile ? "35px" : "0px" }}>
          <Dialog
            TransitionComponent={Transition}
            maxWidth={ isMobile ? "xs" : "md" }
            onClose={() => { this.handleClose() }}
            open={this.state.openUpdateModal}
            aria-labelledby="responsive-dialog-title"
            style={{ display: "flex", flexDirection: "column"}}
          >

          <DialogTitle id="responsive-dialog-title" style={{ backgroundColor: "white", textAlign: "center" }}>
            Mensagem da Jobspot!
          </DialogTitle>
            <DialogContent>

            <DialogContentText id="alert-dialog-slide-description" style={{ textAlign: "center" }}>
              { this.handleIconUpdate() } <div id="status_message"> { this.handleTextUpdate() } </div>
            </DialogContentText>
            </DialogContent>
              <div id="dismiss_button" style={{ width: "fit-content", alignSelf: "center", paddingTop: "10px", paddingBottom: "10px" }}>{ this.handleDismissButton() }</div>
          </Dialog>

          <Grid item xs={8}>
            <TextField
              className={classNames(classes.textfield, {
                [classes.textfieldMobile]: isMobile
              })}
              required
              variant="outlined"
              id="companyName"
              name="companyName"
              label={"Razão Social"}
              value={this.state.companyName}
              onChange={e => {
                this.setState({
                  companyName: e.target.value
                });
              }}
            />
          </Grid>
          <div id="social_number" style={{ display: "flex", gap: "170px", alignItems: "center" }}>
            <Grid item xs={8}>
            <HtmlTooltip
              title={
                  <React.Fragment>
                {"Se deseja modificar seu CNPJ ou email, entre em contato com a nossa equipe para que possamos ajudá-lo no processo."}
                <br />
                {"E-mail: suporte@jobspot.com"}
                </React.Fragment>
              }
            >  
              <TextField
                className={classNames(classes.textfield, {
                  [classes.textfieldMobile]: isMobile
                })}
                required
                variant="outlined"
                id="socialNumber"
                name="socialNumber"
                label={"CNPJ"}
                value={this.state.socialNumber}
                disabled={true}
              />
            </HtmlTooltip>
            </Grid>
          </div>
          <Grid item xs={8}>
          <HtmlTooltip
              title={
                  <React.Fragment>
                {"Se deseja modificar seu CNPJ ou email, entre em contato com a nossa equipe para que possamos ajudá-lo no processo."}
                <br />
                {"E-mail: suporte@jobspot.com"}
                </React.Fragment>
              }
            >  
            <TextField
                className={classNames(classes.textfield, {
                  [classes.textfieldMobile]: isMobile
                })}
              required
              variant="outlined"
              id="contactEmail"
              name="contactEmail"
              label={"E-mail para contato"}
              value={this.state.contactEmail}
              disabled={true}
            />
            </HtmlTooltip>
          </Grid>
          <Grid item xs={ isMobile ? 12 : 4 }>
                <FormControl variant="outlined" fullWidth required>
                  <InputLabel
                    htmlFor="state"
                    style={{
                      backgroundColor: "#F1F1F1",
                      paddingHorizontal: 5
                    }}
                  >
                    {i18n.t("state")}
                  </InputLabel>
                  <Select
                    value={this.state.state ? this.state.state.id : ""}
                    inputProps={{
                      name: "state",
                      id: "state"
                    }}
                    onChange={e => {
                      let st = this.state.states.find(m => {
                        return m.id === e.target.value;
                      });
  
                      this.setState({
                        state: st
                      });
                    }}
                    input={<OutlinedInput name="state" id="state" />}
                  >
                    <MenuItem value="">
                      <em>{i18n.t("state")}</em>
                    </MenuItem>
                    {this.state.states.map((e, key) => (
                      <MenuItem key={key} value={e.id}>
                        {e.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                </Grid>

                <Grid item xs={ isMobile ? 12 : 4 }>
                      <FormControl variant="outlined" fullWidth required>
                        <InputLabel
                          htmlFor="city"
                          style={{
                            backgroundColor: "#F1F1F1",
                            paddingHorizontal: 5
                          }}
                        >
                          {i18n.t("city")}
                        </InputLabel>
                        <Select
                          value={this.state.city ? this.state.city.id : ""}
                          inputProps={{
                            name: "city",
                            id: "city"
                          }}
                          onChange={e => {
                            let c = this.state.state.cities.find(m => {
                              return m.id === e.target.value;
                            });

                            this.setState({
                              city: c
                            });
                          }}
                          input={<OutlinedInput name="city" id="city" />}
                        >
                          <MenuItem value="">
                            <em>{i18n.t("city")}</em>
                          </MenuItem>
                          {this.state.state &&
                            this.state.state.cities.map((e, key) => (
                              <MenuItem key={key} value={e.id}>
                                {e.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                </Grid>
          <Grid item xs={12}>
            <TextField
                className={classNames(classes.textfield, {
                  [classes.textfieldMobile]: isMobile
                })}
              variant="outlined"
              id="neighborhood"
              name="neighborhood"
              label={"Bairro"}
              value={this.state.neighborhood}
              onChange={e => {
                this.setState({
                  neighborhood: e.target.value.trim()
                });
              }}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
                className={classNames(classes.textfield, {
                  [classes.textfieldMobile]: isMobile
                })}
              variant="outlined"
              id="fullAddress"
              name="fullAddress"
              label={"Endereço"}
              value={this.state.fullAddress}
              onChange={e => {
                this.setState({
                  fullAddress: e.target.value.trim()
                });
              }}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
                className={classNames(classes.textfield, {
                  [classes.textfieldMobile]: isMobile
                })}
              variant="outlined"
              required
              id="name"
              name="name"
              label={"Nome para contato"}
              value={this.state.name}
              onChange={e => {
                this.setState({
                  name: e.target.value.trim()
                });
              }}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
                className={classNames(classes.textfield, {
                  [classes.textfieldMobile]: isMobile
                })}
              required
              variant="outlined"
              id="phone"
              name="phone"
              label={"Telefone"}
              value={this.state.phone}
              onFocus={e => {
                let txt = e.target.value
                  .trim()
                  .replace(/[^0-9]/g, "")
                  .substr(0, 11);

                this.setState({
                  phone: txt.trim()
                });
              }}
              onChange={e => {
                let txt = e.target.value
                  .trim()
                  .replace(/[^0-9]/g, "")
                  .substr(0, 11);

                  this.setState({
                    phone: txt.trim()
                  });
              }}
              onBlur={e => {
                let txt = e.target.value
                  .trim()
                  .replace(/[^0-9]/g, "")
                  .substr(0, 11);

                let ddd = txt.substr(0, 2);
                let phone = txt.substr(2).replace(/(.{3})/g, "$1 ");

                let maskedTxt = ddd;
                if (ddd.length === 2 && phone) maskedTxt = "(" + ddd + ") ";
                if (phone) maskedTxt = maskedTxt + phone;

                this.setState({
                  phone: maskedTxt.trim()
                });
              }}
            />
          </Grid>
         </form>     
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.login.token,
  showSnackbar: state.job.showSnackbar,
  jobCandidates: state.job.jobCandidates,
  userInitialized: true,
  state: state
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closeSnackbar,
      doUpdateJobCandidates,
      doResetJobCandidates,
      doResetJobState
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(compose(withStyles(styles), withWidth())(Update))
);
