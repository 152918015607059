// @flow
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Toolbar,
  Typography,
  Slide
} from "@material-ui/core";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { withStyles } from "@material-ui/core/styles";
import {
  Accessible as AccessibleIcon,
  Assignment as AssignmentIcon,
  Cake as CakeIcon,
  Close as CloseIcon,
  // Label as LabelIcon,
  Place as PlaceIcon,
  Schedule as ScheduleIcon,
  School as SchoolIcon,
  Star as StarIcon,
  Wc as WcIcon,
  Work as WorkIcon,
} from "@material-ui/icons";
import classNames from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import Persona from "../../components/Persona";
import TopBar from "../../components/TopBar";
import ImportanceRange from "../../components/ImportanceRange";
import { Tooltip } from "@material-ui/core";
import {
  doLoadAgeRanges,
  doLoadAndKeepEducationLevels,
  doLoadContractingModalities,
  doLoadGenders,
  doLoadImportanceRange,
  doLoadShiftPreferences,
  doLoadSkills,
  doLoadSpecialNeeds,
  doLoadStates
} from "../../modules/form-data";
import {
  closeJobError,
  doAddTagSubOption,
  doMountDefaultAgeRangeOptions,
  doMountDefaultAvailabilityOptions,
  doMountDefaultGenderOptions,
  doMountDefaultHiringOptions,
  doMountDefaultPlacesOptions,
  doPersistJob,
  doRemoveTagSubOption,
  doSelecPlacesCity,
  doSelecPlacesState,
  doSelectAgeRangeGrade,
  doSelectAgeRangeSubOptionGrade,
  doSelectAvailabilityGrade,
  doSelectAvailabilitySubOptionGrade,
  doSelectCurrentCitySelectGrade,
  doSelectGenderGrade,
  doSelectGenderSubOptionGrade,
  doSelectHiringGrade,
  doSelectHiringSubOptionGrade,
  doSelectOtherCitySelectGrade,
  doSelectPlacesGrade,
  doSelectProfissaoGrade,
  doSelectQualificationGrade,
  doSelectSkillsGrade,
  doSelectSkillsubOptionGrade,
  doSelectSpecialNeedsGrade,
  doSelectSpecialNeedSubOptionGrade,
  doSelecttagGrade,
  doToggleAgeRange,
  doToggleAvailability,
  doToggleGender,
  doToggleHiring,
  doTogglePlaces,
  doToggleProfissao,
  doToggleQualification,
  doToggleSkills,
  doToggleSpecialNeeds,
  doToggleTags,
  showJobError,
  updateMyJobs
} from "../../modules/job";
import DialogEdit from "./components/DialogEdit";
import DialogLevel from "./components/DialogLevel";
import JobCard from "./components/JobCard";
import SelectLocation from "./components/SelectLocation";
import SelectSkills from "./components/SelectSkills";
import SelectSpecialNeed from "./components/SelectSpecialNeed";
// import SelectTags from "./components/SelectTags"; 
import styles, { SubmitButton } from "./styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Job extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      importanceRange: [],
      ageRanges: [],
      genders: [],
      shiftPreferences: [],
      contractingModalities: [],
      skills: [],
      specialNeeds: [],

      openQualificationEdit: false,
      openProfissaoEdit: false,
      openSpecialNeed: false,
      openLocation: false,
      openHiring: false,
      openAvailability: false,
      openAgeRange: false,
      openSkills: false,
      openGender: false,
      // openTags: false,

      validationError: false,
      validationErrorMsg: "",

      openAddNameDialog: false,
      jobNameText: "",
      showSnackBar: false,
      openJobResume: false,
      openReviewJob: false
    };
  }

  componentDidMount() {
    const { token, states, job } = this.props;
    this.props.doLoadAndKeepEducationLevels(token);

    if (states.length === 0) {
      this.props.doLoadStates();
    }

    this.loadData();

    let jobNameSuggestion = "";

    const jobProfissoesGrades = Object.entries(job.profissoesGrades);

    jobProfissoesGrades.forEach((profissao, index) => {
      let jobNameSplit = profissao[0].split("-");

      if (index === jobProfissoesGrades.length - 1) {
        jobNameSuggestion += `${jobNameSplit[1]}`;
      } else {
        jobNameSuggestion += `${jobNameSplit[1]} | `;
      }

    });

    if (jobNameSuggestion.length > 100) {
      jobNameSuggestion = jobNameSuggestion.substring(0, 100);
    }

    this.setState({ jobNameText: jobNameSuggestion });
  }

  loadData = () => {
    const { token } = this.props;

    Promise.all([
      doLoadImportanceRange(token),
      doLoadGenders(token),
      doLoadAgeRanges(token),
      doLoadShiftPreferences(token),
      doLoadContractingModalities(token),
      doLoadSkills(token),
      doLoadSpecialNeeds(token)
    ]).then(data => {
      this.setState({
        importanceRange: data[0],
        genders: data[1],
        ageRanges: data[2],
        shiftPreferences: data[3],
        contractingModalities: data[4],
        skills: data[5],
        specialNeeds: data[6],
        loading: false
      });
    });
  };

  handleMangerQualification = () => {
    const { history, qualificationConfirmed } = this.props;
    if (qualificationConfirmed) {
      this.setState({ openQualificationEdit: true });
    } else {
      history.replace("/job-qualificacao-choices");
    }
  };

  handleOpenReviewJobOptions = () => {
    this.setState({ openReviewJob: true });
    // this.handleCoursesConfirmed();
  }

  handleGradeLevel = (score) => {
    switch (Number(score)) {
      case 1:
        return "Absolutamente importante";
      case 0.8:
        return "Muito importante";
      case 0.5:
        return "Importância média";
      case 0.2:
        return "Pouco importante";
      case 0.05:
        return "Não importante";
      default:
        return "Critério não selecionado";
    }
  }

  handleSpecialNeeds = (specialNeed, status) => {

    const statusMessage = status === 1 ? "Habilitado" : "Desabilitado";

    switch (specialNeed) {
      case "_FISICA":
        return <><span><b>Física: </b> {statusMessage} </span> <br /></>;
      case "_VISUAL":
        return <><span><b>Visual: </b> {statusMessage} </span> <br /></>;
      case "_AUDITIVA":
        return <><span><b>Auditiva: </b> {statusMessage} </span> <br /></>;
      case "_INTELECTUAL":
        return <><span><b>Intelectual: </b> {statusMessage} </span> <br /></>;
      case "_MOTORA":
        return <><span><b>Motora: </b> {statusMessage} </span> <br /></>;
      default:
        return "Não selecionado";
    }
  }

  capitalizeEachFirstLetter = (string) => {
    let arr = string.split(" ");
    arr = arr.map(s => {
      return s.charAt(0).toUpperCase() + s.toLowerCase().slice(1);
    });
    return arr.join(" ");
  }

  handleSkillsSuboptions = (skill, status) => {

    const statusMessage = status === 1 ? "Habilitado" : "Desabilitado";

    switch (skill) {
      case "_COMPROMETIMENTO":
        return <><span><b>Comprometimento: </b> {statusMessage} </span> <br /></>;
      case "_HUMANIZACAO":
        return <><span><b>Humanização: </b> {statusMessage} </span> <br /></>;
      case "_LIDERANCA":
        return <><span><b>Liderança: </b> {statusMessage} </span> <br /></>;
      case "_PONTUALIDADE":
        return <><span><b>Pontualidade: </b> {statusMessage} </span> <br /></>;
      case "_COMUNICACAO":
        return <><span><b>Comunicação: </b> {statusMessage} </span> <br /></>;
      case "_TRABALHO_EM_EQUIPE":
        return <><span><b>Trabalho em equipe: </b> {statusMessage} </span> <br /></>;
      default:
        return "Não selecionado";
    }
  }

  handleHiringSuboptions = (hiringSuboption, level) => {
    switch (hiringSuboption) {
      case "_AUTONOMO":
        return <><div id="importance_range"><b>Autônomo: </b><br /> <ImportanceRange range={Number(level)} />{ this.handleGradeLevel(level) }<br /></div><br /></>
      case "_CLT":
        return <><div id="importance_range"><b>CLT: </b><br /> <ImportanceRange range={Number(level)} />{ this.handleGradeLevel(level) }<br /></div><br /></>
      case "_DIARISTA":
        return <><div id="importance_range"><b>Diarista: </b><br /> <ImportanceRange range={Number(level)} />{ this.handleGradeLevel(level) }<br /></div><br /></>
      case "_ESTAGIARIO":
        return <><div id="importance_range"><b>Estagiário: </b><br /> <ImportanceRange range={Number(level)} />{ this.handleGradeLevel(level) }<br /></div><br /></>
      case "_FREELANCER":
        return <><div id="importance_range"><b>Freelancer: </b><br /> <ImportanceRange range={Number(level)} />{ this.handleGradeLevel(level) }<br /></div><br /></>
      case "_PJ":
        return <><div id="importance_range"><b>PJ: </b><br /> <ImportanceRange range={Number(level)} />{ this.handleGradeLevel(level) }<br /></div><br /></>
      case "_TEMPORARIO":
        return <><div id="importance_range"><b>Temporário: </b><br /> <ImportanceRange range={Number(level)} />{ this.handleGradeLevel(level) }<br /></div><br /></>
      case "_TERCEIRIZADO":
        return <><div id="importance_range"><b>Terceirizado: </b><br /> <ImportanceRange range={Number(level)} />{ this.handleGradeLevel(level) }<br /></div><br /></>
      case "_TRAINEE":
        return <><div id="importance_range"><b>Trainee: </b><br /> <ImportanceRange range={Number(level)} />{ this.handleGradeLevel(level) }<br /></div><br /></>     
      default:
        return "Não selecionado";
    }
  }

  handleAgeRangeSuboptions = (ageRangeSuboption, level) => {
    switch (ageRangeSuboption) {
      case "_14a17":
        return <><div id="importance_range"><b>14 a 17 anos: </b><br /> <ImportanceRange range={Number(level)} />{ this.handleGradeLevel(level) }<br /></div><br /></>
      case "_18a24":
        return <><div id="importance_range"><b>18 a 24 anos: </b><br /> <ImportanceRange range={Number(level)} />{ this.handleGradeLevel(level) }<br /></div><br /></>
      case "_25a39":
        return <><div id="importance_range"><b>25 a 39 anos: </b><br /> <ImportanceRange range={Number(level)} />{ this.handleGradeLevel(level) }<br /></div><br /></>
      case "_40a59":
        return <><div id="importance_range"><b>40 a 59 anos: </b><br /> <ImportanceRange range={Number(level)} />{ this.handleGradeLevel(level) }<br /></div><br /></>
      case "_60Mais":
        return <><div id="importance_range"><b>60 anos ou mais: </b><br /> <ImportanceRange range={Number(level)} />{ this.handleGradeLevel(level) }<br /></div><br /></>
      default:
        return "Não selecionado";
    }
  }

  handleGenderSuboptions = (genderSuboption, level) => {
    switch (genderSuboption) {
      case "_FEM":
        return <><div id="importance_range"><b>Feminino: </b><br /> <ImportanceRange range={Number(level)} />{ this.handleGradeLevel(level) }<br /></div> <br /></>
      case "_MAS":
        return <><div id="importance_range"><b>Masculino: </b><br /> <ImportanceRange range={Number(level)} />{ this.handleGradeLevel(level) }<br /></div> <br /></>
      default:
        return "Não selecionado";
    }
  }

  handleAvailabilitySuboptions = (hiringSuboption, level) => {
    switch (hiringSuboption) {
      case "_HORARIO_COMERCIAL":
        return <><div id="importance_range"><b>Horário comercial: </b><br /> <ImportanceRange range={Number(level)} />{ this.handleGradeLevel(level) }<br /></div><br /></>
      case "_MADRUGADA":
        return <><div id="importance_range"><b>Madrugada: </b><br /> <ImportanceRange range={Number(level)} />{ this.handleGradeLevel(level) }<br /></div><br /></>
      case "_MANHA":
        return <><div id="importance_range"><b>Manhã: </b><br /> <ImportanceRange range={Number(level)} />{ this.handleGradeLevel(level) }<br /></div><br /></>
      case "_NOITE":
        return <><div id="importance_range"><b>Noite: </b><br /> <ImportanceRange range={Number(level)} />{ this.handleGradeLevel(level) }<br /></div><br /></>
      case "_RESID_OUTRO_LOCAL":
        return <><div id="importance_range"><b>Residir em outro local: </b><br /> <ImportanceRange range={Number(level)} />{ this.handleGradeLevel(level) }<br /></div><br /></>
      case "_TARDE":
        return <><div id="importance_range"><b>Tarde: </b><br /> <ImportanceRange range={Number(level)} />{ this.handleGradeLevel(level) }<br /></div> <br /></>
      case "_VIAGEM":
        return <><div id="importance_range"><b>Viagens: </b><br /> <ImportanceRange range={Number(level)} />{ this.handleGradeLevel(level) }<br /></div><br /></>
      default:
        return "Não selecionado";
    }
  }

  handleCloseReviewJobOptions = () => {
    this.setState({ openReviewJob: false });
  }

  handleMangerProfissao = () => {
    const { history, profissaoConfirmed } = this.props;
    if (profissaoConfirmed) {
      this.setState({ openProfissaoEdit: true });
    } else {
      history.replace("/job-profissao-choices");
    }
  };

  handleManagerSpecialNeed = () => {
    this.setState({ openSpecialNeed: !this.state.openSpecialNeed });
  };

  handleManagerLocation = () => {
    this.setState({ openLocation: !this.state.openLocation });
  };

  findState = (stateId) => {
    const state = this.props.states.filter((state) => state.id === stateId);
    if (!state[0]) {
      return "Não definido";
    }
    return state[0].name;
  }

  findCity = (stateId, cityId) => {
    const state = this.props.states.filter((state) => state.id === stateId);

    if (!state[0]) {
      return "Não definido";
    }

    const city = state[0].cities.filter((city) => city.id === cityId );

    if (!city[0]) {
      return "Não definido";
    }

    return city[0].name;
  }

  handleSubmit = () => {
    if (!this.props.qualificationConfirmed) {
      this.props.showJobError("Erro", "Qualificação é obrigatória");
      this.setState({
        validationError: true,
        validationErrorMsg: "Qualificação é obrigatória"
      });
    } else if (!this.props.profissaoConfirmed) {
      this.props.showJobError("Erro", "Profissão é obrigatória");
      this.setState({
        validationError: true,
        validationErrorMsg: "Profissão é obrigatória"
      });
    } else if (!this.props.placesConfirmed) {
      this.props.showJobError("Erro", "Localidade é obrigatória");
      this.setState({
        validationError: true,
        validationErrorMsg: "Localidade é obrigatória"
      });
    } else {
      if (this.props.jobId) {
        this.props.doPersistJob(this.props.jobName);
        this.setState({ showSnackBar: true });
        //this.props.persistJobFn();
        setTimeout(() => {
          this.props.history.push(`/job-summary/${this.props.jobId}`);
        }, 800);
      } else {
        //this.props.history.push("/job-name");
        this.setState({ openAddNameDialog: true });
      }
    }
  };

  handleDoCriarVaga = () => {
    const { doCreateJob } = this.props;
    const { jobNameText } = this.state;
    if (jobNameText && jobNameText.length > 0) doCreateJob(jobNameText);
  };

  handleCloseErrorDialog = () => {
    this.setState({
      validationError: false,
      validationErrorMsg: " "
    });
  };

  render() {
    const { classes, history, jobName, job } = this.props;

    const {
      openQualificationEdit,
      openProfissaoEdit,
      openSpecialNeed,
      openLocation,
      openHiring,
      openAvailability,
      openAgeRange,
      openSkills,
      openGender,
      // openTags,
      validationError,
      validationErrorMsg,
      openAddNameDialog,
      openJobResume,
      showSnackBar,
      openReviewJob
    } = this.state;
    const grid = {
      xs: 6,
      sm: 4,
      md: 3,
      lg: 2
    };
    const pageTitle = jobName ? `Editar - ${jobName}` : "Nova Vaga";

    return (
      <div className={classes.root}>
        <TopBar
          pageTitle={pageTitle}
          buttonTitle="Confirmar"
          buttonReviewTitle="Revisar"
          actionButtonReview={() => this.handleOpenReviewJobOptions()}
          hasButton={true}
          hasButtonReview={true}
          buttonColor="primary"
          variant="contained"
          actionButton={() => this.handleSubmit()}
          actionBack={() => history.replace("/jobs")}
        />
        <Persona
          text={`Defina aqui o perfil que deseja contratar. Lembre-se de que os itens Qualificação, Profissão e Localidade são obrigatórios.`}
        />

          <Dialog
            TransitionComponent={Transition}
            maxWidth={"md"}
            open={openReviewJob}
            onClose={() => { this.handleCloseReviewJobOptions() }}
            aria-labelledby="responsive-dialog-title"
          >
            <AppBar position="static">
              <Toolbar>
                <Typography variant="h6" color="inherit" className={classes.title}>
                  {"Resumo das informações da vaga"}
                </Typography>
              </Toolbar>
            </AppBar>
              <DialogContent dividers={"paper"}>
                <DialogContentText
                  id="scroll-dialog-description"
                  tabIndex={-1}
                >
                  <div id="header_review_topic" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "70%" }}><h3><SchoolIcon style={{ position: "relative", top: "5px" }} /> Qualificação </h3><BorderColorIcon style={{ cursor: "pointer" }} onClick={() => this.handleMangerQualification()} fontSize="small" /></div>
                  <br />

                  {job.coursesConfirmed.length > 0 ? (
                    job.coursesConfirmed.map((element) => {
                      return (<><div id="list_courses"><b>{element.courseLevel.text} {Object.keys(element.course).length !== 0 ? this.capitalizeEachFirstLetter(element.course.text) : ''} {`(${element.situation.text}) `} </b></div> <div id="importance_range"><Tooltip placement="top"><ImportanceRange range={Number(element.grade)} /></Tooltip>{ this.handleGradeLevel(element.grade) }</div> <br /></>)
                    })
                    ) : (
                      <>
                        <span> Critério não selecionado </span>
                        <br />
                      </>
                    )
                  }
                

                  <Divider className={classes.profileDivider} />
                  <br />
                  <div id="header_review_topic" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "70%" }}><h3><WorkIcon style={{ position: "relative", top: "5px" }}/> Experiências profissionais </h3><BorderColorIcon style={{ cursor: "pointer" }} onClick={() => this.handleMangerProfissao()} fontSize="small" /></div>
                  <br />

                  {job.profissoesConfirmed.length > 0 ? (
                    job.profissoesConfirmed.map((element) => {
                      return (<><span><b>{ element.profissao.text } {`(${element.experiencePeriod ? element.experiencePeriod.text : element.segment.text}) `} </b></span> <div id="importance_range"><ImportanceRange range={Number(element.grade)} /></div> { this.handleGradeLevel(element.grade) } <br /><br /></>)
                    })
                    ) : (
                      <>
                        <span> Critério não selecionado </span>
                        <br />
                      </>
                    )
                  }

                  <br />

                  <Divider className={classes.profileDivider} />
                  <br />
                  <div id="header_review_topic" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "70%" }}><h3><PlaceIcon style={{ position: "relative", top: "5px" }} /> Localidade </h3><BorderColorIcon style={{ cursor: "pointer" }} onClick={() => this.handleManagerLocation()} fontSize="small" /></div>
                  
                  <br />
                  <span><b>Estado:</b> { this.findState(job.placesState) }</span><br />
                  <span><b>Cidade:</b> { this.findCity(job.placesState, job.placesCity) }</span><br />

                  <br />

                  <span><b>Cidade da vaga:</b> <div id="importance_range_city_job"><ImportanceRange range={Number(job.currentCityGrade)} /></div> { this.handleGradeLevel(job.currentCityGrade) }</span><br />

                  <br />

                  <span><b>Outras cidades:</b> <div id="importance_range_other_cities"><ImportanceRange range={Number(job.otherCityGrade)} /></div>{ this.handleGradeLevel(job.otherCityGrade) }</span><br />
                  <br />

                  <Divider className={classes.profileDivider} />
                  <br />
                  <div id="header_review_topic" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "70%" }}><h3><AccessibleIcon style={{ position: "relative", top: "5px" }} /> Necessidades Especiais </h3><BorderColorIcon style={{ cursor: "pointer" }} onClick={() => this.handleManagerSpecialNeed()} fontSize="small" /></div>
                  <br />

                  {Object.keys(job.specialNeedsSubOptions).length > 0 && job.specialNeedsConfirmed ? (
                    Object.keys(job.specialNeedsSubOptions).map((element) => {
                      return (this.handleSpecialNeeds(element, job.specialNeedsSubOptions[element]));
                    })
                    ) : (
                      <>
                        <span> Critério não selecionado </span>
                        <br />
                      </>
                    )
                  }
                  <br />

                  <Divider className={classes.profileDivider} />
                  <br />
                  <div id="header_review_topic" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "70%" }}><h3><AssignmentIcon style={{ position: "relative", top: "5px" }} /> Contratação </h3><BorderColorIcon style={{ cursor: "pointer" }} onClick={() => this.setState({ openHiring: true })} fontSize="small" /></div>
                  <br />

                  {Object.keys(job.hiringSubOptions).length > 0 && job.hiringConfirmed ? (
                    Object.keys(job.hiringSubOptions).map((element) => {
                      return (this.handleHiringSuboptions(element, job.hiringSubOptions[element]));
                    })
                    ) : (
                      <>
                        <span> Critério não selecionado </span>
                        <br />
                      </>
                    )
                  }

                  <br />

                  <Divider className={classes.profileDivider} />
                  <br />
                  <div id="header_review_topic" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "70%" }}><h3><ScheduleIcon style={{ position: "relative", top: "5px" }} /> Disponibilidade </h3><BorderColorIcon style={{ cursor: "pointer" }} onClick={() => this.setState({ openAvailability: true })} fontSize="small" /></div>
                  
                  <br />

                  {Object.keys(job.availabilitySubOptions).length > 0 && job.availabilityConfirmed ? (
                    Object.keys(job.availabilitySubOptions).map((element) => {
                      return (this.handleAvailabilitySuboptions(element, job.availabilitySubOptions[element]));
                    })
                    ) : (
                      <>
                        <span> Critério não selecionado </span>
                        <br />
                      </>
                    )
                  }                  

                  <br />

                  <Divider className={classes.profileDivider} />
                  <br />
                  <div id="header_review_topic" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "70%" }}><h3><CakeIcon style={{ position: "relative", top: "5px" }} /> Faixa Etária </h3><BorderColorIcon style={{ cursor: "pointer" }} onClick={() => this.setState({ openAgeRange: true })} fontSize="small" /></div>
                  
                  <br />

                  {Object.keys(job.ageRangeSubOptions).length > 0 && job.ageRangeConfirmed ? (
                    Object.keys(job.ageRangeSubOptions).map((element) => {
                      return (this.handleAgeRangeSuboptions(element, job.ageRangeSubOptions[element]));
                    })
                    ) : (
                      <>
                        <span> Critério não selecionado </span>
                        <br />
                      </>
                    )
                  }  

                  <br />

                  <Divider className={classes.profileDivider} />
                  <br />
                  <div id="header_review_topic" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "70%" }}><h3><StarIcon style={{ position: "relative", top: "5px" }} /> Habilidades </h3><BorderColorIcon style={{ cursor: "pointer" }} onClick={() => this.setState({ openSkills: true })} fontSize="small" /></div>
                  
                  <br />

                  {Object.keys(job.skillsSubOptions).length > 0 && job.skillsConfirmed ? (
                    Object.keys(job.skillsSubOptions).map((element) => {
                      return (this.handleSkillsSuboptions(element, job.skillsSubOptions[element]));
                    })
                    ) : (
                      <>
                        <span> Critério não selecionado </span>
                        <br />
                      </>
                    )
                  }

                  <br />

                  <Divider className={classes.profileDivider} />
                  <br />
                  <div id="header_review_topic" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "70%" }}><h3><WcIcon style={{ position: "relative", top: "5px" }} /> Sexo </h3><BorderColorIcon style={{ cursor: "pointer" }} onClick={() => this.setState({ openGender: true })} fontSize="small" /></div>
                  
                  <br />

                  {Object.keys(job.genderSubOptions).length > 0 && job.genderSubOptions ? (
                    Object.keys(job.genderSubOptions).map((element) => {
                      return (this.handleGenderSuboptions(element, job.genderSubOptions[element]));
                    })
                    ) : (
                      <>
                        <span> Critério não selecionado </span>
                        <br />
                      </>
                    )
                  }

                  <br />
                </DialogContentText>
              </DialogContent>
          </Dialog>
        <Grid container spacing={3}>
          <Grid
            item
            xs={grid.xs}
            sm={grid.sm}
            md={grid.md}
            lg={grid.lg}
            className={classNames(classes.card, {
              [classes.cardActive]: this.props.qualificationConfirmed
            })}
          >
            <JobCard
              cardTitle="Qualificação *"
              cardIcon={<SchoolIcon />}
              active={this.props.qualificationConfirmed}
              toggleCheckboxFn={this.props.doToggleQualification}
              grade={this.props.qualificationGrade}
              selectGradeFn={this.props.doSelectQualificationGrade}
              importanceRange={this.state.importanceRange}
              jobId={this.props.jobId}
              persistJobFn={this.props.doPersistJob}
              history={this.props.history}
              actionCard={() => this.handleMangerQualification()}
              optional={false}
            />
          </Grid>

          <Grid
            item
            xs={grid.xs}
            sm={grid.sm}
            md={grid.md}
            lg={grid.lg}
            className={classNames(classes.card, {
              [classes.cardActive]: this.props.profissaoConfirmed
            })}
          >
            <JobCard
              cardTitle="Profissão *"
              cardIcon={<WorkIcon />}
              active={this.props.profissaoConfirmed}
              toggleCheckboxFn={this.props.doToggleProfissao}
              grade={this.props.profissaoGrade}
              selectGradeFn={this.props.doSelectProfissaoGrade}
              importanceRange={this.state.importanceRange}
              jobId={this.props.jobId}
              persistJobFn={this.props.doPersistJob}
              history={this.props.history}
              actionCard={() => this.handleMangerProfissao()}
              jobActive={true}
              optional={false}
            />
          </Grid>

          <Grid
            item
            xs={grid.xs}
            sm={grid.sm}
            md={grid.md}
            lg={grid.lg}
            className={classNames(classes.card, {
              [classes.cardActive]: this.props.placesConfirmed
            })}
          >
            <JobCard
              cardTitle="Localidade *"
              cardIcon={<PlaceIcon />}
              active={this.props.placesConfirmed}
              actionCard={() => this.handleManagerLocation()}
              grade={this.props.placesGrade}
              optional={false}
            />
          </Grid>

          <Grid
            item
            xs={grid.xs}
            sm={grid.sm}
            md={grid.md}
            lg={grid.lg}
            className={classNames(classes.card, {
              [classes.cardActive]: this.props.specialNeedsConfirmed
            })}
          >
            <JobCard
              cardTitle="Necessidades Especiais"
              cardIcon={<AccessibleIcon />}
              active={this.props.specialNeedsConfirmed}
              actionCard={() => this.handleManagerSpecialNeed()}
              grade={this.props.specialNeedsGrade}
              optional={true}
            />
          </Grid>

          <Grid
            item
            xs={grid.xs}
            sm={grid.sm}
            md={grid.md}
            lg={grid.lg}
            className={classNames(classes.card, {
              [classes.cardActive]: this.props.hiringConfirmed
            })}
          >
            <JobCard
              cardTitle="Contratação"
              cardIcon={<AssignmentIcon />}
              actionCard={() => this.setState({ openHiring: true })}
              active={this.props.hiringConfirmed}
              grade={this.props.hiringGrade}
              optional={true}
            />
          </Grid>

          <Grid
            item
            xs={grid.xs}
            sm={grid.sm}
            md={grid.md}
            lg={grid.lg}
            className={classNames(classes.card, {
              [classes.cardActive]: this.props.availabilityConfirmed
            })}
          >
            <JobCard
              cardTitle="Disponibilidade"
              cardIcon={<ScheduleIcon />}
              actionCard={() => this.setState({ openAvailability: true })}
              active={this.props.availabilityConfirmed}
              grade={this.props.availabilityGrade}
              optional={true}
            />
          </Grid>

          <Grid
            item
            xs={grid.xs}
            sm={grid.sm}
            md={grid.md}
            lg={grid.lg}
            className={classNames(classes.card, {
              [classes.cardActive]: this.props.ageRangeConfirmed
            })}
          >
            <JobCard
              cardTitle="Faixa etária"
              cardIcon={<CakeIcon />}
              active={this.props.ageRangeConfirmed}
              actionCard={() => this.setState({ openAgeRange: true })}
              grade={this.props.ageRangeGrade}
              optional={true}
            />
          </Grid>

          <Grid
            item
            xs={grid.xs}
            sm={grid.sm}
            md={grid.md}
            lg={grid.lg}
            className={classNames(classes.card, {
              [classes.cardActive]: this.props.skillsConfirmed
            })}
          >
            <JobCard
              cardTitle="Habilidades"
              cardIcon={<StarIcon />}
              active={this.props.skillsConfirmed}
              actionCard={() => this.setState({ openSkills: true })}
              grade={this.props.skillsGrade}
              optional={true}
            />
          </Grid>

          <Grid
            item
            xs={grid.xs}
            sm={grid.sm}
            md={grid.md}
            lg={grid.lg}
            className={classNames(classes.card, {
              [classes.cardActive]: this.props.genderConfirmed
            })}
          >
            <JobCard
              cardTitle="Sexo"
              cardIcon={<WcIcon />}
              active={this.props.genderConfirmed}
              actionCard={() => this.setState({ openGender: true })}
              grade={this.props.genderGrade}
              optional={true}
            />
          </Grid>

          {/* <Grid
            item
            xs={grid.xs}
            sm={grid.sm}
            md={grid.md}
            lg={grid.lg}
            className={classNames(classes.card, {
              [classes.cardActive]: this.props.tagsConfirmed
            })}
          >
            <JobCard
              cardTitle="Palavras-chave"
              cardIcon={<LabelIcon />}
              active={this.props.tagsConfirmed}
              actionCard={() => this.setState({ openTags: true })}
              grade={this.props.tagGrade}
            />
          </Grid> */}
        </Grid>

        {openQualificationEdit && (
          <DialogEdit
            cardTitle="Qualificação"
            active={this.props.qualificationConfirmed}
            toggleCheckboxFn={this.props.doToggleQualification}
            grade={this.props.qualificationGrade}
            selectGradeFn={this.props.doSelectQualificationGrade}
            importanceRange={this.state.importanceRange}
            jobId={this.props.jobId}
            persistJobFn={this.props.doPersistJob}
            history={this.props.history}
            jobActive={true}
            actionCard={() =>
              this.props.history.replace("/job-qualificacao-choices")
            }
            actionClose={() => this.setState({ openQualificationEdit: false })}
          />
        )}

        {openProfissaoEdit && (
          <DialogEdit
            cardTitle="Profissão"
            active={this.props.profissaoConfirmed}
            toggleCheckboxFn={this.props.doToggleProfissao}
            grade={this.props.profissaoGrade}
            selectGradeFn={this.props.doSelectProfissaoGrade}
            importanceRange={this.state.importanceRange}
            jobId={this.props.jobId}
            persistJobFn={this.props.doPersistJob}
            history={this.props.history}
            jobActive={true}
            actionCard={() =>
              this.props.history.replace("/job-profissao-choices")
            }
            actionClose={() => this.setState({ openProfissaoEdit: false })}
          />
        )}

        {openSpecialNeed && (
          <SelectSpecialNeed
            cardTitle="Necessidades Especiais"
            active={this.props.specialNeedsConfirmed}
            toggleCheckboxFn={this.props.doToggleSpecialNeeds}
            grade={this.props.specialNeedsGrade}
            selectGradeFn={this.props.doSelectSpecialNeedsGrade}
            importanceRange={this.state.importanceRange}
            jobId={this.props.jobId}
            persistJobFn={this.props.doPersistJob}
            history={this.props.history}
            subOptions={this.state.specialNeeds}
            subOptionsSavedValues={this.props.specialNeedsSubOptions}
            subOptionsSelectGradeFn={
              this.props.doSelectSpecialNeedSubOptionGrade
            }
            actionCard={() => this.setState({ openSpecialNeed: false })}
          />
        )}

        {openLocation && (
          <SelectLocation
            cardTitle="Localidade"
            active={this.props.placesConfirmed}
            toggleCheckboxFn={this.props.doTogglePlaces}
            grade={this.props.placesGrade}
            selectGradeFn={this.props.doSelectPlacesGrade}
            importanceRange={this.state.importanceRange}
            jobId={this.props.jobId}
            persistJobFn={this.props.doPersistJob}
            history={this.props.history}
            states={this.props.states}
            placesState={this.props.placesState}
            placesCity={this.props.placesCity}
            currentCityGrade={this.props.currentCityGrade}
            otherCityGrade={this.props.otherCityGrade}
            currentCitySelectGradeFn={this.props.doSelectCurrentCitySelectGrade}
            otherCitySelectGradeFn={this.props.doSelectOtherCitySelectGrade}
            selectStateFn={this.props.doSelecPlacesState}
            selectCityFn={this.props.doSelecPlacesCity}
            actionCard={() => this.setState({ openLocation: false })}
            defaultValuesFn={this.props.doMountDefaultPlacesOptions}
          />
        )}

        {openHiring && (
          <DialogLevel
            cardTitle="Contratação"
            active={this.props.hiringConfirmed}
            expandOnActivate={false}
            toggleCheckboxFn={this.props.doToggleHiring}
            grade={this.props.hiringGrade}
            selectGradeFn={this.props.doSelectHiringGrade}
            importanceRange={this.state.importanceRange}
            jobId={this.props.jobId}
            persistJobFn={this.props.doPersistJob}
            history={this.props.history}
            subOptions={this.state.contractingModalities}
            subOptionsSavedValues={this.props.hiringSubOptions}
            subOptionsSelectGradeFn={this.props.doSelectHiringSubOptionGrade}
            jobActive={true}
            defaultValuesFn={this.props.doMountDefaultHiringOptions}
            actionCard={() => this.setState({ openHiring: false })}
          />
        )}

        {openAvailability && (
          <DialogLevel
            cardTitle="Disponibilidade"
            active={this.props.availabilityConfirmed}
            toggleCheckboxFn={this.props.doToggleAvailability}
            grade={this.props.availabilityGrade}
            selectGradeFn={this.props.doSelectAvailabilityGrade}
            importanceRange={this.state.importanceRange}
            jobId={this.props.jobId}
            persistJobFn={this.props.doPersistJob}
            history={this.props.history}
            subOptions={this.state.shiftPreferences}
            subOptionsSavedValues={this.props.availabilitySubOptions}
            subOptionsSelectGradeFn={
              this.props.doSelectAvailabilitySubOptionGrade
            }
            jobActive={true}
            defaultValuesFn={this.props.doMountDefaultAvailabilityOptions}
            actionCard={() => this.setState({ openAvailability: false })}
          />
        )}

        {openAgeRange && (
          <DialogLevel
            cardTitle="Faixa Etária"
            active={this.props.ageRangeConfirmed}
            toggleCheckboxFn={this.props.doToggleAgeRange}
            grade={this.props.ageRangeGrade}
            selectGradeFn={this.props.doSelectAgeRangeGrade}
            importanceRange={this.state.importanceRange}
            jobId={this.props.jobId}
            persistJobFn={this.props.doPersistJob}
            history={this.props.history}
            subOptions={this.state.ageRanges}
            subOptionsSavedValues={this.props.ageRangeSubOptions}
            subOptionsSelectGradeFn={this.props.doSelectAgeRangeSubOptionGrade}
            jobActive={true}
            actionCard={() => this.setState({ openAgeRange: false })}
            defaultValuesFn={this.props.doMountDefaultAgeRangeOptions}
          />
        )}

        {openSkills && (
          <SelectSkills
            cardTitle="Habilidades"
            active={this.props.skillsConfirmed}
            toggleCheckboxFn={this.props.doToggleSkills}
            grade={this.props.skillsGrade}
            selectGradeFn={this.props.doSelectSkillsGrade}
            importanceRange={this.state.importanceRange}
            jobId={this.props.jobId}
            persistJobFn={this.props.doPersistJob}
            history={this.props.history}
            subOptions={this.state.skills}
            subOptionsSavedValues={this.props.skillsSubOptions}
            subOptionsSelectGradeFn={this.props.doSelectSkillsubOptionGrade}
            jobActive={true}
            actionCard={() => this.setState({ openSkills: false })}
          />
        )}

        {openGender && (
          <DialogLevel
            cardTitle="Sexo"
            active={this.props.genderConfirmed}
            toggleCheckboxFn={this.props.doToggleGender}
            grade={this.props.genderGrade}
            selectGradeFn={this.props.doSelectGenderGrade}
            importanceRange={this.state.importanceRange}
            jobId={this.props.jobId}
            persistJobFn={this.props.doPersistJob}
            history={this.props.history}
            subOptions={this.state.genders}
            subOptionsSavedValues={this.props.genderSubOptions}
            subOptionsSelectGradeFn={this.props.doSelectGenderSubOptionGrade}
            jobActive={true}
            actionCard={() => this.setState({ openGender: false })}
            defaultValuesFn={this.props.doMountDefaultGenderOptions}
          />
        )}


        {/* Trecho comentado em 07/2022. A funcionalidade ainda não está ativa, portanto não devemos mostrar para o usuário.
          {openTags && (
          <SelectTags
            cardTitle="Palavras-chave"
            active={this.props.tagsConfirmed}
            toggleCheckboxFn={this.props.doToggleTags}
            grade={this.props.tagGrade}
            selectGradeFn={this.props.doSelecttagGrade}
            importanceRange={this.state.importanceRange}
            jobId={this.props.jobId}
            persistJobFn={this.props.doPersistJob}
            history={this.props.history}
            subOptions={this.props.tagsSubOptions}
            addSubOptionFn={this.props.doAddTagSubOption}
            removeSubOptionFn={this.props.doRemoveTagSubOption}
            actionCard={() => this.setState({ openTags: false })}
          />
        )} */}

        <Dialog
          open={validationError}
          onClose={this.handleCloseErrorDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Ops!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {validationErrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseErrorDialog}
              color="primary"
              autoFocus
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openAddNameDialog}
          onClose={() => this.setState({ openAddNameDialog: false })}
          aria-labelledby="form-dialog-title"
        >
          <AppBar position="static">
            <Toolbar style={{ padding: "0px" }}>
              <IconButton color="inherit" onClick={() => this.setState({ openAddNameDialog: false })}>
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.title}>
                  Definir nome da vaga
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <DialogContentText>Qual o nome desta vaga?<br /><b>Lembre-se! O nome escolhido será mostrado para o candidato.</b></DialogContentText>

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              autoFocus
              label="Nome da vaga"
              type="text"
              id="name"
              autoComplete="off"
              onChange={event =>
                this.setState({ jobNameText: event.target.value })
              }        
              onKeyDown={e => {
                if (e.key === "Enter") this.handleDoCriarVaga();
              }}
              value={this.state.jobNameText}
              inputProps={{ maxLength: 100 }}
            />
          </DialogContent>
          <DialogActions>
            <SubmitButton
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => this.handleDoCriarVaga()}
              >
                Confirmar e Criar Vaga
            </SubmitButton>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openJobResume}
          onClose={() => this.setState({ openJobResume: false })}
          aria-labelledby="form-dialog-title"
        >
          <AppBar position="static">
            <Toolbar style={{ padding: "0px" }}>
              <IconButton color="inherit" onClick={() => this.setState({ openJobResume: false })}>
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.title}>
                  Visão geral da vaga
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
              <h3><b>Qualificação: Muito importante</b></h3>
              <br />
              <h4>Ensino Fundamental</h4>
              <p>Concluído: Muito importante</p>
              <p>Em andamento: Pouco importante</p>

              <br />

              <h3><b>Profissão:</b></h3>
          </DialogContent>
        </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          open={showSnackBar}
          onClose={() => {
            this.setState({ showSnackBar: false });
          }}
          autoHideDuration={3000}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">Salvo com sucesso!</span>}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  qualificationConfirmed: state.job.qualificationConfirmed,
  qualificationGrade: state.job.qualificationGrade,
  profissaoConfirmed: state.job.profissaoConfirmed,
  profissaoGrade: state.job.profissaoGrade,
  specialNeedsConfirmed: state.job.specialNeedsConfirmed,
  specialNeedsGrade: state.job.specialNeedsGrade,
  specialNeedsSubOptions: state.job.specialNeedsSubOptions,
  placesConfirmed: state.job.placesConfirmed,
  placesGrade: state.job.placesGrade,
  placesState: state.job.placesState,
  placesCity: state.job.placesCity,
  currentCityGrade: state.job.currentCityGrade,
  otherCityGrade: state.job.otherCityGrade,
  hiringConfirmed: state.job.hiringConfirmed,
  hiringGrade: state.job.hiringGrade,
  hiringSubOptions: state.job.hiringSubOptions,
  availabilityConfirmed: state.job.availabilityConfirmed,
  availabilityGrade: state.job.availabilityGrade,
  availabilitySubOptions: state.job.availabilitySubOptions,
  ageRangeConfirmed: state.job.ageRangeConfirmed,
  ageRangeGrade: state.job.ageRangeGrade,
  ageRangeSubOptions: state.job.ageRangeSubOptions,
  skillsConfirmed: state.job.skillsConfirmed,
  skillsGrade: state.job.skillsGrade,
  skillsSubOptions: state.job.skillsSubOptions,
  genderConfirmed: state.job.genderConfirmed,
  genderGrade: state.job.genderGrade,
  genderSubOptions: state.job.genderSubOptions,
  tagsConfirmed: state.job.tagsConfirmed,
  tagGrade: state.job.tagGrade,
  tagsSubOptions: state.job.tagsSubOptions,
  jobId: state.job.jobId,
  jobName: state.job.jobName,
  states: state.formData.states,
  token: state.login.token,
  errorOpen: state.job.errorOpen,
  errorTitle: state.job.errorTitle,
  errorMessage: state.job.errorMessage,
  myJobs: state.job.myJobs,
  job: state.job
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doToggleQualification,
      doSelectQualificationGrade,
      doToggleProfissao,
      doSelectProfissaoGrade,
      doPersistJob,
      doLoadAndKeepEducationLevels,
      doToggleSpecialNeeds,
      doSelectSpecialNeedsGrade,
      doSelectSpecialNeedSubOptionGrade,
      doTogglePlaces,
      doSelectPlacesGrade,
      doSelecPlacesState,
      doSelecPlacesCity,
      doSelectCurrentCitySelectGrade,
      doSelectOtherCitySelectGrade,
      doToggleHiring,
      doSelectHiringGrade,
      doSelectHiringSubOptionGrade,
      doToggleAvailability,
      doSelectAvailabilityGrade,
      doSelectAvailabilitySubOptionGrade,
      doToggleAgeRange,
      doSelectAgeRangeGrade,
      doSelectAgeRangeSubOptionGrade,
      doToggleSkills,
      doSelectSkillsGrade,
      doSelectSkillsubOptionGrade,
      doToggleGender,
      doSelectGenderGrade,
      doSelectGenderSubOptionGrade,
      doToggleTags,
      doSelecttagGrade,
      doAddTagSubOption,
      doRemoveTagSubOption,
      doLoadStates,
      showJobError,
      closeJobError,
      updateMyJobs,
      doCreateJob: doPersistJob,
      doMountDefaultHiringOptions,
      doMountDefaultAvailabilityOptions,
      doMountDefaultAgeRangeOptions,
      doMountDefaultGenderOptions,
      doMountDefaultPlacesOptions
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Job))
);
