import React from "react";
import { connect } from "react-redux";
import { Switch, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import App from "../containers/app";
import JobStartChat from "../containers/chat/job-start-chat";
import CreateAccount from "../containers/create-account";
import CreateAccountType from "../containers/create-account/account-type";
import CreateAccountStep2 from "../containers/create-account/step-2";
import CreateAccountStep2Company from "../containers/create-account/step-2-company";
import CreateAccountJobspotPolicy from "../containers/create-account/jobspot-policy";
//Old pages/containers
import Initial from "../containers/initial";
// import Job from "../containers/job";
import JobName from "../containers/job/job-name";
// import JobProfissaoChoices from "../containers/job/job-profissao-choices";
import JobProfissaoAddChoice from "../containers/job/job-profissao-add-choice";
import JobProfissaoAddChoiceSelectExperience from "../containers/job/job-profissao-add-choice-select-experience";
import JobExperienceAddChoiceSelectSegment from "../containers/job/job-profissao-add-choice-select-segment";
// import JobQualificacaoChoices from "../containers/job/job-qualificacao-choices";
// import JobQualificacaoLevelChoices from "../containers/job/job-qualificacao-level-choices";
import JobQualificacaoAddChoice from "../containers/job/job-qualificacao-add-choice";
import JobQualificacaoAddChoiceSelectCourse from "../containers/job/job-qualificacao-add-choice-select-course";
import JobQualificacaoAddChoiceSelectCourseAcademicDegree from "../containers/job/job-qualificacao-add-choice-select-course-academic-degree";
import JobQualificacaoAddChoiceSelectCourseSituation from "../containers/job/job-qualificacao-add-choice-select-course-situation";
// import JobSpecificProfissaoChoices from "../containers/job/job-specific-profissao-choices";
import JobSpecialNeedsChoices from "../containers/job/job-special-needs-choices";
// import Jobs from "../containers/jobs";
// import JobSummary from "../containers/jobs/job-summary";
import JobCandidate from "../containers/jobs/job-candidate";
import Login from "../containers/login";
import ProfileAddProfissao from "../containers/profile/profile-add-profissao";
import ProfileAddProfissaoSelectExperience from "../containers/profile/profile-add-profissao-select-experience";
import ProfileAddProfissaoSelectSegment from "../containers/profile/profile-add-profissao-select-segment";
//import Chat from "../containers/chat";
//import ChatList from "../containers/chat/chat-list";
import ProfileAddQualificacaoSelectCourse from "../containers/profile/profile-add-qualificacao-select-course";
import ProfileAddQualificacaoSelectCourseAcademicDegree from "../containers/profile/profile-add-qualificacao-select-course-academic-degree";
import ProfileAddQualificacaoSelectCourseSituation from "../containers/profile/profile-add-qualificacao-select-course-situation";
import Reset from "../containers/reset";
import Settings from "../containers/settings";
//New pages - Pixell
import ForgotPassword from "../pages/forgot-password";
import DataAccessAnswer from "../pages/data-access-answer";
import Job from "../pages/job";
import JobSummary from "../pages/job-summary";
import JobProfissaoChoices from "../pages/job/job-profissao-choices";
import JobQualificacaoChoices from "../pages/job/job-qualificacao-choices";
import JobQualificacaoLevelChoices from "../pages/job/job-qualificacao-level-choices";
import JobSpecificProfissaoChoices from "../pages/job/job-specific-profissao-choices";
import Jobs from "../pages/jobs";
import Plans from "../pages/plans";
import Redirect from "../containers/redirect";
import NotFound from "../pages/not-found";
import PendingInvites from "../pages/pending-invites";
import Profile from "../pages/profile";
import Preferences from "../pages/preferences";
import ProfileAddQualificacao from "../pages/profile/profile-add-qualificacao";
import ResetPassword from "../pages/reset-password";
import Update from "../pages/update";
import VerifyAccount from "../pages/verify-account";
import Route from "./Route";

function Routes() {
  return (
    <Switch>
      <Route
        exact
        path="/"
        component={Login}
        isPrivate={false}
        initialRouter={true}
      />

      <Route
        exact
        path="/forgot-password"
        component={ForgotPassword}
        isPrivate={false}
      />

      <Route
        exact
        path="/redirect"
        component={Redirect}
        isPrivate={false}
      />

      <Route
        exact
        path="/reset-password"
        component={ResetPassword}
        isPrivate={false}
      />

      <Route exact path="/logout" component={Reset} isPrivate={false} />
      <Route exact path="/verify" component={VerifyAccount} isPrivate={false} />

      <Route
        exact
        path="/create-account"
        component={CreateAccount}
        isPrivate={false}
      />
      <Route
        exact
        path="/create-account-type"
        component={CreateAccountType}
        isPrivate={false}
      />
      <Route
        exact
        path="/create-account-step-2"
        component={CreateAccountStep2}
        isPrivate={false}
      />
      <Route
        exact
        path="/create-account-step-2-company"
        component={CreateAccountStep2Company}
        isPrivate={false}
      />
      <Route
        exact
        path="/create-account-jobspot-policy"
        component={CreateAccountJobspotPolicy}
        isPrivate={false}
      />

      {/** <Route exact path="/chats" component={ChatList} isPrivate={true} /> */}
      <Route exact path="/initial" component={Initial} isPrivate={true} />
      <Route exact path="/settings" component={Settings} isPrivate={true} />
      <Route
        exact
        path="/profile"
        component={Profile}
        isPrivate={true}
        displayFooter={true}
        authType="PERSON"
      />
      <Route
        exact
        path="/preferences"
        component={Preferences}
        isPrivate={true}
        authType="PERSON"
      />
      <Route
        exact
        path="/profile-add-qualificacao"
        component={ProfileAddQualificacao}
        isPrivate={true}
      />
      <Route
        exact
        path="/profile-add-qualificacao-select-course"
        component={ProfileAddQualificacaoSelectCourse}
        isPrivate={true}
      />
      <Route
        exact
        path="/profile-add-qualificacao-select-course-situation"
        component={ProfileAddQualificacaoSelectCourseSituation}
        isPrivate={true}
      />
      <Route
        exact
        path="/profile-add-qualificacao-select-qualificacao-academic-degree"
        component={ProfileAddQualificacaoSelectCourseAcademicDegree}
        isPrivate={true}
      />
      <Route
        exact
        path="/profile-add-profissao"
        component={ProfileAddProfissao}
        isPrivate={true}
      />
      <Route
        exact
        path="/profile-add-profissao-select-experience"
        component={ProfileAddProfissaoSelectExperience}
        isPrivate={true}
      />
      <Route
        exact
        path="/profile-add-profissao-select-segment"
        component={ProfileAddProfissaoSelectSegment}
        isPrivate={true}
      />
      <Route exact path="/jobs" component={Jobs} isPrivate={true} />
      <Route exact path="/update" component={Update} isPrivate={true} />
      <Route exact path="/plans" component={Plans} isPrivate={true} />
      <Route
        exact
        path="/job-summary/:id"
        component={JobSummary}
        isPrivate={true}
        // displayFooter={false}
      />
      <Route
        exact
        path="/job-candidate/:jobId/:candidateId"
        component={JobCandidate}
        isPrivate={true}
      />
      <Route
        exact
        path="/job"
        component={Job}
        isPrivate={true}
        // displayFooter={false}
        authType="COMPANY"
      />
      <Route exact path="/job-name" component={JobName} isPrivate={true} />
      <Route
        exact
        path="/job-qualificacao-choices"
        component={JobQualificacaoChoices}
        isPrivate={true}
        displayFooter={false}
      />
      <Route
        exact
        path="/job-qualificacao-add-choice"
        component={JobQualificacaoAddChoice}
        isPrivate={true}
      />
      <Route
        exact
        path="/job-qualificacao-add-choice-select-course"
        component={JobQualificacaoAddChoiceSelectCourse}
        isPrivate={true}
      />
      <Route
        exact
        path="/job-qualificacao-add-choice-select-course-situation"
        component={JobQualificacaoAddChoiceSelectCourseSituation}
        isPrivate={true}
      />
      <Route
        exact
        path="/job-qualificacao-add-choice-select-course-academic-degree"
        component={JobQualificacaoAddChoiceSelectCourseAcademicDegree}
        isPrivate={true}
      />
      <Route
        exact
        path="/job-qualificacao-level-choices/:level"
        component={JobQualificacaoLevelChoices}
        isPrivate={true}
        displayFooter={false}
      />
      <Route
        exact
        path="/job-profissao-choices"
        component={JobProfissaoChoices}
        isPrivate={true}
        displayFooter={false}
      />
      <Route
        exact
        path="/job-profissao-add-choice"
        component={JobProfissaoAddChoice}
        isPrivate={true}
      />
      <Route
        exact
        path="/job-profissao-add-choice-select-experience"
        component={JobProfissaoAddChoiceSelectExperience}
        isPrivate={true}
      />
      <Route
        exact
        path="/job-profissao-add-choice-select-segment"
        component={JobExperienceAddChoiceSelectSegment}
        isPrivate={true}
      />
      <Route
        exact
        path="/job-specific-profissao-choices/:option"
        component={JobSpecificProfissaoChoices}
        isPrivate={true}
        displayFooter={false}
      />
      <Route
        exact
        path="/job-special-needs-choices"
        component={JobSpecialNeedsChoices}
        isPrivate={true}
      />
      <Route
        exact
        path="/job/accept-chat-invitation/:jobChatId"
        component={JobStartChat}
        chat-trabalhador
        isPrivate={true}
      />
      <Route
        exact
        path="/profile"
        component={Profile}
        isPrivate={true}
        displayFooter={true}
        authType="PERSON"
      />

      <Route
        exact
        path="/pending-invites"
        component={PendingInvites}
        isPrivate={true}
        displayFooter={true}
      />

      <Route
        exact
        path="/access-answer"
        component={DataAccessAnswer}
        isPrivate={false}
        displayFooter={true}
      />

      <Route exact path="/app" component={App} isPrivate={true} />
      <Route exact path="/logout" component={Reset} isPrivate={false} />
      <Route component={NotFound} />
    </Switch>
  );
}

const mapStateToProps = state => ({
  token: state.login.token,
  cloudMessagingToken: state.login.cloudMessagingToken,
  userInitialized: true,
  subscribedToOneSignal: state.user.subscribedToOneSignal
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));
