// @flow
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import classNames from "classnames";
import { Slide, Dialog, DialogTitle } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DialogContentText from "@material-ui/core/DialogContentText";
import compose from "recompose/compose";
import LinearProgress from '@mui/material/LinearProgress';
import { withStyles } from "@material-ui/core/styles";
import TopBar from "../../components/TopBar";
import Button from "@material-ui/core/Button";
import {
  withWidth
} from "@material-ui/core";

import Persona from "../../components/Persona";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import { doUpdateProfile, forceReloadProfile } from "../../modules/profile";
import { doGetProfile } from "../../modules/user";

import {
  sendUpdatePreferences
} from "../../modules/job";

import i18n from "../../util/i18n";

import {
  doUpdateJobCandidates,
  doResetJobCandidates,
  closeSnackbar,
  doResetJobState
} from "../../modules/job";

import { doLoadUser, doLoadStatesUpdate, doUpdatePerson } from "../../modules/form-data";

import styles from "./styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Preferences extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();

    let doLoading = true;
    this.getProfile();

    this.state = {
      jobs: [],
      storedJobs: [],
      loading: true,
      companyName: '',
      socialNumber: '',
      city: '',
      state: '',
      neighborhood: '',
      fullAddress: '',
      name: '',
      phone: '',
      contactEmail: '',
      states: [],
      cities: [],
      openUpdateModal: false,
      showSuccessIcon: false,
      showFailIcon: false,
      personLoading: doLoading,
      alertInfo: false,
      profileVisibility: this.props.profile.profileVisibility ? this.props.profile.profileVisibility : "automatic",
      isSnackbarOpen: false
    };
  }

  componentDidMount() {
    doLoadStatesUpdate().then(result => {
      this.setState({ states: result });
    });
    
    doLoadUser(this.props.token).then(userData => {
      this.setState({
        name: userData.name,
        socialNumber: userData.socialNumber,
        city: userData.city,
        state: userData.state,
        neighborhood: userData.neighborhood,
        fullAddress: userData.fullAddress,
        phone: userData.phone,
        contactEmail: userData.email,
        profileVisibility: userData.profileVisibility === "automatic" ? "1" : "2" 
      })
    });
  }

  handleIconUpdate = () => {
    if (this.state.showFailIcon) {
      return <ErrorOutlineIcon style={{ color: "red", textAlign: "center" }} />;
    }

    if (this.state.showSuccessIcon) {
      return <CheckCircleOutlineIcon style={{ color: "green", textAlign: "center" }} />;
    }

    if (this.state.openUpdateModal) {
      return <LinearProgress />
    }
  }

  handleTextUpdate = () => {
    if (this.state.showFailIcon) {
      return "Um erro inesperado aconteceu enquanto atualizávamos o seu perfil. Entre em contato com nosso suporte.";
    }

    if (this.state.showSuccessIcon) {
      return "Perfil atualizado com sucesso!";
    }

    if (this.state.openUpdateModal) {
      return "Estamos atualizando seu perfil...";
    }
  }

  handleClose = () => {
    if (this.state.showFailIcon || this.state.showSuccessIcon) {
      this.setState({ openUpdateModal: false });
    }
  }

  getProfile = () => {
    doGetProfile(this.props.token)
      .then(p => {

        this.props.doUpdateProfile(p);

        if (!p.initialized) {
          this.props.history.replace("/profile");
        }

        this.setState({ loading: false });

      })
      .catch(error => {
        console.error(error);
      });
  };

  onClose = () => {
    this.setState({
      alertInfo: false
    });
  };

  handleOpenInfoAlert = () => {
    this.setState({
      alertInfo: true
    });
  };

  handleDismissButton = () => {
    if (this.state.showFailIcon || this.state.showSuccessIcon) {
      return <Button color="primary" variant="contained" onClick={() => { this.handleClose() }}>Entendido</Button> 
    }
  }

  sendPreferences = () => {
    this.setState({ isSnackbarOpen: true });

    sendUpdatePreferences(this.props.token, this.props.profile.phone, this.state.profileVisibility);
  }

  onChange = (event) => {
    this.setState({ profileVisibility: event.target.value });
  }


  handleUserUpdate = () => {
    if (this.formRef.current.reportValidity()) {

      this.setState({ openUpdateModal: true });

      const updatedUser = {
        phone: this.state.phone,
        id_estado: this.state.state.id,
        id_cidade: this.state.city.id,
        email: this.state.contactEmail,
        socialNumber: this.state.socialNumber,
        neighborhood: this.state.neighborhood,
        full_address: this.state.fullAddress,
        name: this.state.name,
        profileVisibility: this.state.profileVisibility === "1" ? "automatic" : "requiresConfirmation"
      };

      doUpdatePerson(this.props.token, updatedUser).then(result => {

        if (result.error_code) {
          this.setState({ showFailIcon: true })
        } else {
          this.setState({
            socialNumber: result.socialNumber,
            city: result.city,
            state: result.state,
            neighborhood: result.neighborhood,
            fullAddress: result.fullAddress,
            contactEmail: result.email,
            name: result.name,
            phone: result.phone,
            showSuccessIcon: true,
            profile_visibility: this.state.profileVisibility === "automatic" ? "1" : "2"
          })
        }

      })
    }
  }

  handleUserUpdateDebug = () => {
    if (this.formRef.current.reportValidity()) {
    }
  }

  render() {
    const { classes, width, history } = this.props;
    const isMobile = ["xs", "sm", "md"].includes(width);

    return (
      <div
        className={classNames(classes.root, {
          [classes.rootFulls]: isMobile
        })}
      >
      <TopBar
        pageTitle={"Editar dados"}
        buttonTitle="Salvar"
        actionButton={() => this.handleUserUpdate()}
        actionBack={() => history.replace("/profile")}
        hasButton={true}
        hasButtonReview={false}
        buttonColor="primary"
        variant="contained"
      />

      <Persona text={`Utilize essa área para atualizar seus dados pessoais!`} />

        <div id="data_fields" style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <form noValidate id="form_fields" ref={this.formRef} style={{ display: "flex", flexDirection: "column", gap: "15px", position: "relative", right: isMobile ? "35px" : "0px" }}>
          <Dialog
            TransitionComponent={Transition}
            maxWidth={ isMobile ? "xs" : "md" }
            onClose={() => { this.handleClose() }}
            open={this.state.openUpdateModal}
            aria-labelledby="responsive-dialog-title"
            style={{ display: "flex", flexDirection: "column"}}
          >

          <DialogTitle id="responsive-dialog-title" style={{ backgroundColor: "white", textAlign: "center" }}>
            Mensagem da Jobspot!
          </DialogTitle>
            <DialogContent>

            <DialogContentText id="alert-dialog-slide-description" style={{ textAlign: "center" }}>
              { this.handleIconUpdate() } <div id="status_message"> { this.handleTextUpdate() } </div>
            </DialogContentText>
            </DialogContent>

            <div id="dismiss_button" style={{ width: "fit-content", alignSelf: "center", paddingTop: "10px", paddingBottom: "10px" }}>{ this.handleDismissButton() }</div>

          </Dialog>

          <Grid item xs={8}>
            <TextField
              className={classNames(classes.textfield, {
                [classes.textfieldMobile]: isMobile
              })}
              required
              variant="outlined"
              id="name"
              name="name"
              label={"Nome"}
              value={this.state.name}
              onChange={e => {
                this.setState({
                  name: e.target.value
                });
              }}
            />
          </Grid>
          <div id="social_number" style={{ display: "flex", gap: "170px", alignItems: "center" }}>
            <Grid item xs={8}>
              <TextField
                className={classNames(classes.textfield, {
                  [classes.textfieldMobile]: isMobile
                })}
                required
                variant="outlined"
                id="socialNumber"
                name="socialNumber"
                label={"CPF"}
                value={this.state.socialNumber}
                onChange={e => {
                  this.setState({
                    socialNumber: e.target.value
                  });
                }}
              />
            </Grid>
          </div>
          <Grid item xs={8}>
            <TextField
              className={classNames(classes.textfield, {
                [classes.textfieldMobile]: isMobile
              })}
              variant="outlined"
              id="contactEmail"
              name="contactEmail"
              label={"E-mail para contato"}
              value={this.state.contactEmail}
              onChange={e => {
                this.setState({
                  contactEmail: e.target.value
                });
              }}
            />
          </Grid>
          <Grid item xs={ isMobile ? 12 : 4 }>
                <FormControl variant="outlined" fullWidth required>
                  <InputLabel
                    htmlFor="state"
                    style={{
                      backgroundColor: "#F1F1F1",
                      paddingHorizontal: 5
                    }}
                  >
                    {i18n.t("state")}
                  </InputLabel>
                  <Select
                    value={this.state.state ? this.state.state.id : ""}
                    inputProps={{
                      name: "state",
                      id: "state"
                    }}
                    onChange={e => {
                      let st = this.state.states.find(m => {
                        return m.id === e.target.value;
                      });
  
                      this.setState({
                        state: st
                      });
                    }}
                    input={<OutlinedInput name="state" id="state" />}
                  >
                    <MenuItem value="">
                      <em>{i18n.t("state")}</em>
                    </MenuItem>
                    {this.state.states.map((e, key) => (
                      <MenuItem key={key} value={e.id}>
                        {e.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                </Grid>

                <Grid item xs={ isMobile ? 12 : 4 }>
                      <FormControl variant="outlined" fullWidth required>
                        <InputLabel
                          htmlFor="city"
                          style={{
                            backgroundColor: "#F1F1F1",
                            paddingHorizontal: 5
                          }}
                        >
                          {i18n.t("city")}
                        </InputLabel>
                        <Select
                          value={this.state.city ? this.state.city.id : ""}
                          inputProps={{
                            name: "city",
                            id: "city"
                          }}
                          onChange={e => {
                            let c = this.state.state.cities.find(m => {
                              return m.id === e.target.value;
                            });

                            this.setState({
                              city: c
                            });
                          }}
                          input={<OutlinedInput name="city" id="city" />}
                        >
                          <MenuItem value="">
                            <em>{i18n.t("city")}</em>
                          </MenuItem>
                          {this.state.state &&
                            this.state.state.cities.map((e, key) => (
                              <MenuItem key={key} value={e.id}>
                                {e.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                </Grid>
          <Grid item xs={12}>
            <TextField
              className={classNames(classes.textfield, {
                [classes.textfieldMobile]: isMobile
              })}
              variant="outlined"
              id="neighborhood"
              name="neighborhood"
              label={"Bairro"}
              value={this.state.neighborhood}
              onChange={e => {
                this.setState({
                  neighborhood: e.target.value.trim()
                });
              }}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              className={classNames(classes.textfield, {
                [classes.textfieldMobile]: isMobile
              })}
              variant="outlined"
              id="fullAddress"
              name="fullAddress"
              label={"Endereço"}
              value={this.state.fullAddress}
              onChange={e => {
                this.setState({
                  fullAddress: e.target.value.trim()
                });
              }}
            />
          </Grid>

          <Grid item xs={ isMobile ? 12 : 4 }>
                <FormControl variant="outlined" fullWidth required>
                  <InputLabel
                    htmlFor="state"
                    style={{
                      backgroundColor: "#F1F1F1",
                      paddingHorizontal: 5
                    }}
                  >
                    {"Visibilidade do perfil"}
                  </InputLabel>
                  <Select
                    value={this.state.profileVisibility}
                    inputProps={{
                      name: "profileVisibility",
                      id: "profileVisibility"
                    }}
                    onChange={e => {
                      this.setState({
                        profileVisibility: e.target.value
                      });
                    }}
                    input={<OutlinedInput name="state" id="state" />}
                  >
                    {[{ id: "1", text: "Deixar meu perfil visível sempre" }, { id: "2", text: "Enviar solicitação para o meu Whatsapp sobre novas vagas" }].map((e, key) => (
                      <MenuItem key={key} value={e.id}>
                        {e.text}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                </Grid>

         </form>     
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.login.token,
  showSnackbar: state.job.showSnackbar,
  jobCandidates: state.job.jobCandidates,
  userInitialized: true,
  state: state,
  editing: state.profile.editing,
  qualificationOpen: state.profile.qualificationOpen,
  ocupationOpen: state.profile.ocupationOpen,
  specialNeedsOpen: state.profile.specialNeedsOpen,
  placesInterestOpen: state.profile.placesInterestOpen,
  contractingModalitiesOpen: state.profile.contractingModalitiesOpen,
  shiftPreferencesOpen: state.profile.shiftPreferencesOpen,
  skillsOpen: state.profile.skillsOpen,
  confirmOpen: state.profile.confirmOpen,
  confirmTitle: state.profile.confirmTitle,
  confirmText: state.profile.confirmText,
  confirmKey: state.profile.confirmKey,
  confirmObjId: state.profile.confirmObjId,
  profile: state.profile.profile,
  profileQualification: state.profile.profileQualification,
  profileOcupation: state.profile.profileOcupation,
  profileSpecialNeeds: state.profile.profileSpecialNeeds,
  profilePlacesInterest: state.profile.profilePlacesInterest,
  profileContractingModalities: state.profile.profileContractingModalities,
  profileShiftPreferences: state.profile.profileShiftPreferences,
  profileSkills: state.profile.profileSkills,
  loading: state.profile.loading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closeSnackbar,
      doUpdateJobCandidates,
      doResetJobCandidates,
      doResetJobState,
      doUpdateProfile,
      forceReloadProfile
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(compose(withStyles(styles), withWidth())(Preferences))
);
