import {
  Avatar,
  Checkbox,
  CircularProgress,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Fab,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Snackbar,
  Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  Clear as ClearIcon,
  Edit as EditIcon,
  ExpandMore as ExpandMoreIcon,
  Save as SaveIcon
} from "@material-ui/icons";
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { forceReloadProfile } from "../../../../../../modules/profile";
import {
  doGetJsonProfile,
  doSaveProfile,
  doUpdateJsonProfile
} from "../../../../../../modules/user";
//Component styles
import styles from "./styles";

class ProfileItem extends Component {
  state = {
    edit: false,
    selecteds: [],
    showMsg: false
  };

  componentWillReceiveProps() {
    this.loadPropsSelecteds();
  }

  loadPropsSelecteds = () => {
    const { data } = this.props;
    const selecteds = [];

    data.forEach(item => {
      selecteds.push(item.id);
    });

    this.setState({ selecteds, loading: false });
  };

  handleDisableCheckbox = (targetName, selectedCheckbox, checkboxId) => {

    switch(targetName) {
      case 'skills':
        return !selectedCheckbox.includes(checkboxId) && selectedCheckbox.length === 3;
      default:
        return false;
    }
  }

  handleCheckboxClick = (profileItem, checked, checkboxItem) => {
    const { profile, doUpdateProfile } = this.props;
    const { selecteds } = this.state;
    let addToList = true;

    switch (profileItem) {
      case "specialNeeds":
        if (checked) {
          profile.specialNeeds.push(checkboxItem);
        } else {
          profile.specialNeeds = profile.specialNeeds.filter(
            s => s.id !== checkboxItem.id
          );
          addToList = false;
        }

        break;
      case "placesInterest":
        if (checked) {
          profile.placesInterest.push(checkboxItem);
        } else {
          profile.placesInterest = profile.placesInterest.filter(
            s => s.id !== checkboxItem.id
          );
          addToList = false;
        }

        break;
      case "contractingModalities":
        if (checked) {
          profile.contractingModalities.push(checkboxItem);
        } else {
          profile.contractingModalities = profile.contractingModalities.filter(
            s => s.id !== checkboxItem.id
          );
          addToList = false;
        }

        break;
      case "shiftPreferences":
        if (checked) {
          profile.shiftPreferences.push(checkboxItem);
        } else {
          profile.shiftPreferences = profile.shiftPreferences.filter(
            s => s.id !== checkboxItem.id
          );
          addToList = false;
        }

        break;
      case "skills":
        if (checked) {
          profile.skills.push(checkboxItem);
        } else {
          profile.skills = profile.skills.filter(s => s.id !== checkboxItem.id);
          addToList = false;
        }

        break;

      default:
        break;
    }

    if (selecteds.includes(checkboxItem.id)) {
      const index = selecteds.indexOf(checkboxItem.id);
      selecteds.splice(index, 1);
    } else if (addToList === true) {
      selecteds.push(checkboxItem.id);
    }

    this.setState({ selecteds });
    doUpdateProfile(profile);
    this.forceUpdate();
  };

  handleSaveEdit = () => {
    const { profile, token, refreshData } = this.props;
    const userJsonData = doGetJsonProfile(profile);

    doSaveProfile(userJsonData, token).then(result => {
      doUpdateJsonProfile(result);
      forceReloadProfile(token);

      this.setState({
        edit: false,
        showMsg: true
      });
    });
    refreshData();
  };

  handleSetEdit = () => {
    if (this.state.selecteds[0] === "audioRef") {
      window.location.href='https://wa.me/555140422006?text=Quero regravar meu áudio de apresentação!'
    } else if (this.state.selecteds[0] === "noAudio") {
      window.location.href='https://wa.me/555140422006?text=Quero gravar um áudio de apresentação!'
    } else {
      this.setState({ edit: !this.state.edit });
    }
  };

  render() {
    const {
      Icon,
      title,
      targetName,
      classes,
      data,
      fullList,
      loading
    } = this.props;
    const { edit, selecteds, showMsg } = this.state;

    return (
      <Fragment>
        <Accordion defaultExpanded={false}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1c-content"
            id="panel1c-header"
            className={classes.summary}
          >
            <Avatar className={classes.avatar} style={{ backgroundColor: this.props.backgroundColor }} >{Icon}</Avatar>
            <Typography className={classes.secondaryHeading}>
              {title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Fragment>
              {loading ? (
                <CircularProgress
                  color="secondary"
                  style={{ margin: "15px auto" }}
                />
              ) : (
                <Fragment>
                  <List className={classes.subList}>
                    <Fragment>
                      {!edit &&
                        data.map((item, key) => {
                          return (
                            <ListItem key={key}>
                              <ListItemText
                                primary={
                                  typeof item.title === "string" ?
                                    <p className={classes.subListTitle}>
                                      {item.title}
                                    </p>
                                  : 
                                    <div className={classes.subListTitle}>
                                      {item.title}
                                    </div>
                                }
                              />
                            </ListItem>
                          );
                        })}
                      {edit &&
                        fullList.map((item, key) => {
                          return (
                            <ListItem key={key}>
                              <ListItemText
                                primary={
                                  item.text !== "AudioReference" ?
                                  <p className={classes.subListTitle}>
                                    {item.text}
                                  </p> :
                                  <div className={classes.subListTitle}>
                                    {item.text}
                                  </div>
                                }
                              />
                              <ListItemSecondaryAction>
                                <Checkbox
                                  name={targetName}
                                  edge="end"
                                  disabled={this.handleDisableCheckbox(targetName, selecteds, item.id)}
                                  onClick={e => {
                                    this.handleCheckboxClick(
                                      e.target.name,
                                      e.target.checked,
                                      item
                                    );
                                  }}
                                  checked={
                                    //data.filter(fitem => fitem.id === item.id).length > 0 ||
                                    selecteds.includes(item.id)
                                  }
                                />
                              </ListItemSecondaryAction>
                            </ListItem>
                          );
                        })}
                    </Fragment>
                  </List>
                  {!edit && (
                    <Fab
                      variant="extended"
                      size="medium"
                      color="primary"
                      aria-label="Edit"
                      className={classes.fab}
                      onClick={this.handleSetEdit}
                      style={{ visibility: this.props.targetName === "audioFileReference" ? "" : "" }}
                      // style={{ visibility: "collapse" }}
                    >
                      { this.state.selecteds[0] === "noAudio" ? <RecordVoiceOverIcon className={classes.editIcon} /> : this.state.selecteds[0] === "audioRef" ? <RecordVoiceOverIcon className={classes.editIcon} /> : <EditIcon className={classes.editIcon} /> }
                      { this.state.selecteds[0] === "noAudio" ? `Gravar áudio` : this.state.selecteds[0] === "audioRef" ? `Regravar áudio` : `Editar` }
                    </Fab>
                  )}
                  {edit && (
                    <div
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        display: "flex",
                        paddingTop: "15px"
                      }}
                    >
                      <Fab
                        size="medium"
                        color="default"
                        aria-label="Remove"
                        className={classes.fab}
                        onClick={this.handleSetEdit}
                        style={{
                          backgroundColor: "#e0e0e0",
                          marginRight: "15px",
                          marginLeft: "0px"
                        }}
                      >
                        <ClearIcon />
                      </Fab>
                      <Fab
                        variant="extended"
                        size="medium"
                        color="primary"
                        aria-label="Edit"
                        className={classes.fab}
                        onClick={this.handleSaveEdit}
                        style={{ margin: "3px 0" }}
                      >
                        <SaveIcon className={classes.editIcon} />
                        Salvar
                      </Fab>
                    </div>
                  )}
                </Fragment>
              )}
            </Fragment>
          </AccordionDetails>
        </Accordion>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          open={showMsg}
          onClose={() => {
            this.setState({ showMsg: false });
          }}
          autoHideDuration={3000}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">Editado com sucesso!</span>}
        />
      </Fragment>
    );
  }
}

ProfileItem.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  refreshData: PropTypes.func,
  loading: PropTypes.bool
};

const mapStateToProps = state => ({
  token: state.login.token,
  userInitialized: true,
  profile: state.profile.profile
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doUpdateJsonProfile,
      forceReloadProfile
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProfileItem))
);
