import { Grid, Divider, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  Accessible as AccessibleIcon,
  Assignment as AssignmentIcon,
  Place as PlaceIcon,
  Schedule as ScheduleIcon,
  School as SchoolIcon,
  Star as StarIcon,
  Work as WorkIcon,
  GraphicEq as AudioIcon
} from "@material-ui/icons";

import AudioPlayer from "./components/AudioPlayer";
import Persona from "../../../../components/Persona";

import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  doLoadAgeRanges,
  doLoadContractingModalities,
  doLoadGenders,
  doLoadImportanceRange,
  doLoadPlacesInterests,
  doLoadShiftPreferences,
  doLoadSkills,
  doLoadSpecialNeeds
} from "../../../../modules/form-data";
import { doUpdateProfile } from "../../../../modules/profile";
import { doGetProfile } from "../../../../modules/user";
import ProfileItem from "./components/ProfileItem";
import ProfileQualificacao from "./components/ProfileQualificacao";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

//Component styles
import styles from "./styles";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class AccountDetails extends Component {
  state = {
    qualificacao: [],
    profissao: [],
    necessidade_especial: [],
    localidade: [],
    contratacao: [],
    disponibilidade: [],
    habilidades: [],

    importanceRange: [],
    genders: [],
    ageRanges: [],
    shiftPreferences: [],
    contractingModalities: [],
    skills: [],
    specialNeeds: [],
    placesInterest: [],
    audioFileReference: "",

    showFinishProfileDialog: false,
    showCongratulationMessage: false,

    loadingQualificacao: false,
    loadingProfissao: false,
    loadingEspecial: false,
    loadingLocalidade: false,
    loadingContratacao: false,
    loadingDisponibilidade: false,
    loadingHabilidades: false,
    loadingAudioReference: false
  };

  componentDidMount() {
    this.loadAllOptions();
  }

  loadAllOptions = () => {
    Promise.all([
      doLoadImportanceRange(this.props.token),
      doLoadGenders(this.props.token),
      doLoadAgeRanges(this.props.token),
      doLoadShiftPreferences(this.props.token),
      doLoadContractingModalities(this.props.token),
      doLoadSkills(this.props.token),
      doLoadSpecialNeeds(this.props.token),
      doLoadPlacesInterests(this.props.token)
    ]).then(data => {

      this.setState({
        importanceRange: data[0],
        genders: data[1],
        ageRanges: data[2],
        shiftPreferences: data[3],
        contractingModalities: data[4],
        skills: data[5],
        specialNeeds: data[6],
        placesInterest: data[7],
        audioFileReference: this.props.profile.audioFileReference
      });

      this.getQualificacoes();
      this.getProfissoes();
      this.getLimitacoes();
      this.getLocalidade();
      this.getModalidadesContratacao();
      this.getDisponibilidade();
      this.getHabilidades();
      this.getAudioReference();
    });
  };

  getAudioReference = () => {
    this.setState({
      audioFileReference:
        this.props.profile.audioFileReference === "nao" ? undefined : this.props.profile.audioFileReference
      });
  }

  getQualificacoes = () => {
    this.setState({ loadingQualificacao: true });

    const { profile } = this.props;
    const data = [];

    profile.educations.forEach(item => {
      if (item.course) {
        data.push({
          id: item.id,
          uuid: item.uuid,
          title: item.course.text,
          subitens: [
            { text: item.educationLevel.text },
            { text: item.situation.text }
          ],
          selectedItens: []
        });
      } else {
        data.push({
          id: item.id,
          uuid: item.uuid,
          title: item.educationLevel.text,
          subitens: [{ text: item.situation.text }],
          selectedItens: []
        });
      }
    });

    this.setState({ qualificacao: data });
    setTimeout(() => {
      this.setState({ loadingQualificacao: false });
    }, 300);
  };

  handleFinishProfile = () => {
    this.setState({ showFinishProfileDialog: true });
  }

  handleCloseCongratulations = () => {
    this.setState({ showCongratulationMessage: false });
  }

  getProfissoes = () => {
    this.setState({ loadingProfissao: true });
    const { profile } = this.props;
    const data = [];

    profile.ocupations.forEach(item => {
      data.push({
        id: item.id,
        uuid: item.uuid,
        title: item.ocupation.text,
        subitens: [{ text: item.experiencePeriod.text }, ...item.segments],
        selectedItens: []
      });
    });

    this.setState({ profissao: data });
    setTimeout(() => {
      this.setState({ loadingProfissao: false });
    }, 300);
  };

  getLimitacoes = () => {
    this.setState({ loadingEspecial: true });

    const { profile } = this.props;
    const data = [];

    profile.specialNeeds.forEach(item => {
      data.push({
        id: item.id,
        title: item.text,
        subitens: []
      });
    });

    this.setState({ necessidade_especial: data });
    setTimeout(() => {
      this.setState({ loadingEspecial: false });
    }, 300);
  };

  getLocalidade = () => {
    this.setState({ loadingLocalidade: true });

    const { profile } = this.props;
    const data = [];

    profile.placesInterest.forEach(item => {
      data.push({
        id: item.id,
        title: item.text,
        subitens: []
      });
    });

    this.setState({ localidade: data });
    setTimeout(() => {
      this.setState({ loadingLocalidade: false });
    }, 300);
  };

  getModalidadesContratacao = () => {
    this.setState({ loadingContratacao: true });
    const { profile } = this.props;
    const data = [];

    profile.contractingModalities.forEach(item => {
      data.push({
        id: item.id,
        title: item.text,
        subitens: []
      });
    });

    this.setState({ contratacao: data });
    setTimeout(() => {
      this.setState({ loadingContratacao: false });
    }, 300);
  };

  dismissFinishProfileDialog = () => {
    this.setState({ showFinishProfileDialog: false });
  }

  getDisponibilidade = () => {
    this.setState({ loadingDisponibilidade: true });
    const { profile } = this.props;
    const data = [];

    profile.shiftPreferences.forEach(item => {
      data.push({
        id: item.id,
        title: item.text,
        subitens: []
      });
    });

    this.setState({ disponibilidade: data });
    setTimeout(() => {
      this.setState({ loadingDisponibilidade: false });
    }, 300);
  };

  getHabilidades = () => {
    this.setState({ loadingHabilidades: true });
    const { profile } = this.props;
    const data = [];

    profile.skills.forEach(item => {
      data.push({
        id: item.id,
        title: item.text,
        subitens: []
      });
    });

    this.setState({ habilidades: data });
    setTimeout(() => {
      this.setState({ loadingHabilidades: false });
    }, 300);
  };

  handleUserToLogout = () => {
    this.setState({ showFinishProfileDialog: false });
    this.setState({ showCongratulationMessage: true });
  }

  handleLogout = (profileType) => {
    this.props.dispatch({
      type: "FACTORY_RESET"
    });

    if (profileType === "COMPANY") {
      window.location.replace('https://jobspot.com.br/quero-contratar/');
    }

    if (profileType === "PERSON") {
      window.location.replace('https://jobspot.com.br/busco-trabalho-2/');
    }
  }

  updateProfile = targetContent => {
    if (targetContent === "QUALIFICACAO") {
      this.setState({ loadingQualificacao: true });
    } else {
      this.setState({ loadingProfissao: true });
    }

    doGetProfile(this.props.token)
      .then(p => {
        this.props.doUpdateProfile(p);

        this.loadAllOptions();
        if (targetContent === "QUALIFICACAO") {
          this.setState({ loadingQualificacao: false });
        } else {
          this.setState({ loadingProfissao: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  render() {
    const { classes, doUpdateProfile, location, profile } = this.props;
    const {
      qualificacao,
      profissao,
      necessidade_especial,
      localidade,
      contratacao,
      disponibilidade,
      habilidades,

      shiftPreferences,
      contractingModalities,
      skills,
      specialNeeds,
      placesInterest,

      loadingQualificacao,
      loadingProfissao,
      loadingEspecial,
      loadingLocalidade,
      loadingContratacao,
      loadingDisponibilidade,
      loadingHabilidades,
      loadingAudioReference,

      showFinishProfileDialog,
      showCongratulationMessage,

      audioFileReference
    } = this.state;

    const grids = {
      xl: 3,
      lg: 4,
      md: 6,
      xs: 12
    };
    
    const cityName = profile.city.name;
    const stateInitials = profile.state.initials;

    return (
      <Fragment>
        <div id="mandatory_fields" style={{ width: "100%" }}>
        <div>
          <Dialog
            open={showFinishProfileDialog}
            TransitionComponent={Transition}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogContent>
            <Persona
                text={`Seu perfil pode se tornar ainda melhor ao preencher as informações complementares! Gostaria de fazer isso agora?`}
            />
            </DialogContent>
            <DialogActions>
              <div id="buttons_actions" style={{ width: "100%", display: "flex", justifyContent: "space-evenly" }}>
                <Button onClick={() => this.handleUserToLogout()} color="primary" variant="contained">
                  Não
                </Button>
                <Button onClick={() => this.dismissFinishProfileDialog()} color="primary" variant="contained">
                  Sim
                </Button>
              </div>
            </DialogActions>
          </Dialog>
        </div>

        <div>
          <Dialog
            open={showCongratulationMessage}
            TransitionComponent={Transition}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogContent>
            <Persona
                text={`Parabéns! Você está cadastrado no nosso sistema! Para editar suas informações, basta utilizar o Whatsapp, através da opção "Meu perfil".`}
            />
            </DialogContent>
            <DialogActions>
              <div id="buttons_actions" style={{ width: "100%", display: "flex", justifyContent: "space-evenly" }}>
                <Button onClick={() => this.handleCloseCongratulations()} color="primary" variant="contained">
                  Meu perfil
                </Button>
                <Button onClick={() => this.handleLogout(profile.userType)} color="primary" variant="contained">
                  Sair do app
                </Button>
              </div>
            </DialogActions>
          </Dialog>
        </div>
        {profile.educations.length > 0 && profile.ocupations.length > 0 && (
          <div id="finish_button"><Button color="primary" variant="contained" onClick={() => this.handleFinishProfile() }>Salvar</Button></div>
        )}
          <Typography variant="caption"><b>Informações Obrigatórias</b></Typography>
            <br />
          <Divider />
          <Grid
          item
          xs={grids.xs}
          className={classes.grid}
        >
          <ProfileQualificacao
            title="Qualificação"
            backgroundColor={profile.educations.length > 0 ? "limegreen" : "" }
            isIncomplete={this.props.profile.educations.length === 0}
            field="QUALIFICACAO"
            data={qualificacao}
            Icon={<SchoolIcon />}
            refreshData={() => this.getQualificacoes()}
            loading={loadingQualificacao}
            fieldEdited={
              location.state && location.state.edited === "_qualificacao"
            }
            refreshProfile={() => {
              this.updateProfile("QUALIFICACAO");
            }}
          />
        </Grid>

        <Grid
          item
          xs={grids.xs}
          className={classes.grid}
        >
          <ProfileQualificacao
            title="Profissão"
            field="PROFISSAO"
            backgroundColor={profile.ocupations.length > 0 ? "limegreen" : "" }
            isIncomplete={profile.ocupations.length === 0}
            data={profissao}
            Icon={<WorkIcon />}
            refreshData={() => this.getProfissoes()}
            loading={loadingProfissao}
            fieldEdited={
              location.state && location.state.edited === "_profissao"
            }
            refreshProfile={() => {
              this.updateProfile("PROFISSAO");
            }}
          />
        </Grid>

        <Grid
            item
            xs={grids.xs}
            className={classes.grid}
          >
            {localidade && (
              <ProfileItem
                title={`Localidade (${cityName} - ${stateInitials})`}
                targetName={"placesInterest"}
                data={localidade}
                backgroundColor={profile.placesInterest.length > 0 ? "limegreen" : "" }
                Icon={<PlaceIcon />}
                fullList={placesInterest}
                doUpdateProfile={doUpdateProfile}
                refreshData={() => this.getLocalidade()}
                loading={loadingLocalidade}
              />
            )}
          </Grid>
        </div>

        <div id="optional_fields" style={{ width: "100%" }}>
        <Typography variant="caption"><b>Informações Complementares</b></Typography>
            <br />
          <Divider />
          <Grid
            item
            xs={grids.xs}
            className={classes.grid}
          >
            {necessidade_especial && (
              <ProfileItem
                title="Necessidades Especiais"
                targetName={"specialNeeds"}
                data={necessidade_especial}
                backgroundColor={profile.specialNeeds.length > 0 ? "limegreen" : "" }
                Icon={<AccessibleIcon />}
                fullList={specialNeeds}
                doUpdateProfile={doUpdateProfile}
                refreshData={() => this.getLimitacoes()}
                loading={loadingEspecial}
              />
            )}
          </Grid>

          <Grid
            item
            xs={grids.xs}
            className={classes.grid}
          >
            {contratacao && (
              <ProfileItem
                title="Forma de Contratação"
                targetName={"contractingModalities"}
                data={contratacao}
                Icon={<AssignmentIcon />}
                backgroundColor={profile.contractingModalities.length > 0 ? "limegreen" : "" }
                fullList={contractingModalities}
                doUpdateProfile={doUpdateProfile}
                refreshData={() => this.getModalidadesContratacao()}
                loading={loadingContratacao}
              />
            )}
          </Grid>

          <Grid
            item
            xs={grids.xs}
            className={classes.grid}
          >
            {disponibilidade && (
              <ProfileItem
                title="Disponibilidade"
                targetName={"shiftPreferences"}
                data={disponibilidade}
                Icon={<ScheduleIcon />}
                backgroundColor={profile.shiftPreferences.length > 0 ? "limegreen" : "" }
                fullList={shiftPreferences}
                doUpdateProfile={doUpdateProfile}
                refreshData={() => this.getDisponibilidade()}
                loading={loadingDisponibilidade}
              />
            )}
          </Grid>

          <Grid
            item
            xs={grids.xs}
            className={classes.grid}
          >
            {habilidades && (
              <ProfileItem
                title="Habilidades"
                targetName={"skills"}
                backgroundColor={profile.skills.length > 0 ? "limegreen" : "" }
                data={habilidades}
                Icon={<StarIcon />}
                fullList={skills}
                doUpdateProfile={doUpdateProfile}
                refreshData={() => this.getHabilidades()}
                loading={loadingHabilidades}
              />
            )}
          </Grid>
          
          <Grid
            item
            xs={grids.xs}
            className={classes.grid}
          >
              <ProfileItem
                Icon={<AudioIcon />}
                title="Áudio de apresentação"
                targetName={"audioFileReference"}
                data={[{ id: audioFileReference ? "audioRef" : "noAudio", title: audioFileReference ? <AudioPlayer title={"audioRef1"} data={[]} reference={ audioFileReference } /> : "Você ainda não possui um áudio de apresentação.", text: audioFileReference ? "AudioReference" : "NoAudio" }]}
                fullList={"audioFileReference"}
                backgroundColor={audioFileReference ? "limegreen" : "" }
                doUpdateProfile={ false }
                refreshData={() => ({ })}
                loading={loadingAudioReference}
              />
          </Grid>
        </div>
      </Fragment>
    );
  }
}

AccountDetails.propTypes = {
  className: PropTypes.string,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  token: state.login.token,
  userInitialized: true,
  editing: state.profile.editing,
  qualificationOpen: state.profile.qualificationOpen,
  ocupationOpen: state.profile.ocupationOpen,
  specialNeedsOpen: state.profile.specialNeedsOpen,
  placesInterestOpen: state.profile.placesInterestOpen,
  contractingModalitiesOpen: state.profile.contractingModalitiesOpen,
  shiftPreferencesOpen: state.profile.shiftPreferencesOpen,
  skillsOpen: state.profile.skillsOpen,
  confirmOpen: state.profile.confirmOpen,
  confirmTitle: state.profile.confirmTitle,
  confirmText: state.profile.confirmText,
  confirmKey: state.profile.confirmKey,
  confirmObjId: state.profile.confirmObjId,
  profile: state.profile.profile,
  profileQualification: state.profile.profileQualification,
  profileOcupation: state.profile.profileOcupation,
  profileSpecialNeeds: state.profile.profileSpecialNeeds,
  profilePlacesInterest: state.profile.profilePlacesInterest,
  profileContractingModalities: state.profile.profileContractingModalities,
  profileShiftPreferences: state.profile.profileShiftPreferences,
  profileSkills: state.profile.profileSkills,
  loading: state.profile.loading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doUpdateProfile,
      dispatch
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(AccountDetails))
);
