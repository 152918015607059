// @flow
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import Footer from "../../components/footer";

class Settings extends React.Component {
  componentDidMount() {}
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <Typography
              variant="title"
              color="inherit"
              className={classes.flex}
            >
              Ajustes
            </Typography>
          </Toolbar>
        </AppBar>
        <p className={classes.spaceDivider} />
        <div className={classes.whiteBackground}>
          <Divider />
          <List component="nav" className={classes.noPadding}>
            <ListItem
              button
              onClick={() => {
                this.props.history.replace("/logout");
              }}
            >
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
              <KeyboardArrowRightIcon className={classes.rightButton} />
            </ListItem>
          </List>
          <Divider />
        </div>
        <Footer
          value={3}
          history={this.props.history}
          userInitialized={true}
        />
      </div>
    );
  }
}

const styles = theme => ({
  margin: {
    marginRight: theme.spacing.unit * 2
  },
  padding: {
    padding: `0 ${theme.spacing.unit * 2}px`
  },
  root: {
    flexGrow: 1,
    height: "100vh",
    width: "100vw",
    backgroundColor: "#EEEEEE"
  },
  flex: {
    flex: 1
  },
  whiteBackground: {
    backgroundColor: "#FFFFFF"
  },
  spaceDivider: {
    height: "25px"
  },
  noPadding: {
    padding: 0
  },
  rightButton: {
    position: "absolute",
    right: 0,
    marginRight: 10
  }
});

const mapStateToProps = state => ({
  token: state.login.token,
  userInitialized: true
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Settings))
);
