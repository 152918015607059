// @flow
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import Loading from "../loading";

import Footer from "../../components/footer";

import { acceptChatInvitation } from "../../modules/chat";

class JobStartChat extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      success: false
    };
  }
  componentDidMount() {
    acceptChatInvitation(this.props.token, this.props.match.params.jobChatId)
      .then(data => {
        this.setState({
          loading: false,
          success: true
        });
        this.props.history.replace(
          `/chat/trabalhador/${data.id},${data.job.id},${data.user.id}`
        );
      })
      .catch(() => {
        this.setState({
          loading: false,
          success: false
        });
      });
  }
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <Typography
              variant="title"
              color="inherit"
              className={classes.flex}
            >
              Chat
            </Typography>
          </Toolbar>
        </AppBar>
        {this.state.loading && <Loading />}
        {!this.state.loading &&
          !this.state.success && (
            <div>
              <Paper className={classes.paperError} elevation={2}>
                <Typography align="center" component="p">
                  Algum erro ocorreu.
                </Typography>
              </Paper>
            </div>
          )}
        <Footer
          value={2}
          history={this.props.history}
          userInitialized={true}
        />
      </div>
    );
  }
}

const styles = theme => ({
  margin: {
    marginRight: theme.spacing.unit * 2
  },
  padding: {
    padding: `0 ${theme.spacing.unit * 2}px`
  },
  root: {
    flexGrow: 1,
    height: "100vh",
    width: "100vw"
  },
  flex: {
    flex: 1
  },
  badge: {},
  jobName: {
    float: "left",
    width: "40%"
  },
  goJob: {
    position: "fixed",
    right: 0,
    marginRight: 10
  },
  fabButton: {
    margin: theme.spacing.unit + 15,
    marginBottom: 75,
    position: "fixed",
    bottom: 0,
    right: 0,
    zIndex: 10
  },
  paperError: {
    ...theme.mixins.gutters(),
    margin: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  }
});

const mapStateToProps = state => ({
  token: state.login.token,
  userInitialized: true
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(JobStartChat))
);
