// @flow
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Persona from "../../components/Persona";
// import CandidatoRating from './components/CandidatoRating';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import QRCode from "react-qr-code";

import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from '@material-ui/icons/Favorite';
import DescriptionIcon from '@material-ui/icons/Description';

import BlockIcon from '@material-ui/icons/Block';
import DeleteIcon from '@material-ui/icons/Delete';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import TextField from '@material-ui/core/TextField';

import { Rating } from "@material-ui/lab";

import classNames from "classnames";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import {
  AppBar,
  withWidth,
  Grid,
  Avatar,
  Breadcrumbs,
  Chip,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  CircularProgress,
  Fab,
  Typography,
  Snackbar,
  Dialog,
  DialogTitle,
  IconButton,
  Slide,
  Toolbar
} from "@material-ui/core";

import GroupIcon from '@material-ui/icons/Group';

import Button from "@material-ui/core/Button";

import {
  Edit as EditIcon,
  ToggleOff as ToggleOffIcon,
  ToggleOn as ToggleOnIcon,
  Share as ShareIcon,
  FileCopy as FileCopyIcon,
  GetApp as GetAppIcon,
  Lock as LockIcon,
  Close as CloseIcon
} from "@material-ui/icons";

import PersonPinIcon from '@mui/icons-material/PersonPin';

import TopBar from "../../components/TopBar";
import ImportanceRange from "../../components/ImportanceRange";
import CandidatosList from "./components/CandidatosList";

import {
  doRestoreJobDbState,
  doLoadJob,
  doLoadJobCandidatesProfiles,
  doDeleteJob,
  doToggleQualification,
  doSelectQualificationGrade,
  doToggleProfissao,
  doSelectProfissaoGrade,
  doPersistJob,
  doToggleJobActive,
  doToggleSpecialNeeds,
  doSelectSpecialNeedsGrade,
  doSelectSpecialNeedSubOptionGrade,
  doTogglePlaces,
  doUpdateAvailableCredits,
  doSelectPlacesGrade,
  doSelecPlacesState,
  doSelecPlacesCity,
  doSelectCurrentCitySelectGrade,
  doSelectOtherCitySelectGrade,
  doToggleHiring,
  doSelectHiringGrade,
  doSelectHiringSubOptionGrade,
  doToggleAvailability,
  doSelectAvailabilityGrade,
  doSelectAvailabilitySubOptionGrade,
  doToggleAgeRange,
  doSelectAgeRangeGrade,
  doSelectAgeRangeSubOptionGrade,
  doToggleSkills,
  doSelectSkillsGrade,
  doSelectSkillsubOptionGrade,
  doToggleGender,
  doSelectGenderGrade,
  doSelectGenderSubOptionGrade,
  doToggleTags,
  doSelecttagGrade,
  doAddTagSubOption,
  doRemoveTagSubOption,
  favoriteCandidate,
  sendDataAccessRequest,
  unlockCandidateStatusProfile,
  generatePDF,
  doSaveRating
} from "../../modules/job";
import {
  doLoadAndKeepEducationLevels,
  doLoadStates
} from "../../modules/form-data";

import styles from "./styles";

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: '#d6d6d6',
    height: theme.spacing(3),
    color: '#fff'
  },
}))(Chip);

const Jasypt = require('jasypt');
const jasypt = new Jasypt();

jasypt.setPassword('!g3n-on3-t1m3!');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class JobSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 'one',
      tabInfo: 'two',
      candidatesToShow: 10,
      loadingCandidates: true,
      loadingJob: true,
      loadingData: true,
      resultCandidates: [],
      importanceRange: [],
      ageRanges: [],
      genders: [],
      openDialog: true,
      openDialogNotEnoughCredits: false,
      shiftPreferences: [],
      contractingModalities: [],
      skills: [],
      specialNeeds: [],
      maxPoints: 0,
      favoriteCandidates: [],
      candidates: [],
      favoriteStatus: false,
      thetaValues: [],
      candidatos: [],
      alreadyFilled: false,
      keyValues: [],
      firstPositionJob: {},
      isSnackbarOpen: false,
      showSnackbar: false,
      change: false,
      snackbarMessage: "",
      alertDeleteJob: false,
      candidateShown: {
        job_user: {
          hiringStatus: "00",
          favorite: false,
          rating: null,
          commentary: ''
        },
        user: {
          publicId: undefined
        },
        criterios: []
      },
      candidateInfo: "basic",
      extraInfo: [
        {
          name: "Contratação",
          value: "Oculto"
        },
        {
          name: "Disponibilidade",
          value: "Oculto"
        },
        {
          name: "Data de Nascimento",
          value: "Oculto"
        },
        {
          name: "Localidade",
          value: "Oculto"
        },
        {
          name: "Necessidades Especiais",
          value: "Oculto"
        },
        {
          name: "Profissão",
          value: "Oculto"
        }
      ],
      selectedRatingFace: undefined,
      grade: undefined
    };
  }

  componentDidMount() {
    this.loadJob();
    this.doUpdateCandidates();
    this.props.doLoadAndKeepEducationLevels(this.props.token);

    if (this.props.states.length === 0) {
      this.props.doLoadStates();
    }
  }

  componentWillMount() {
    this.loadJob();
  }

  calculateStars = totalPoints => {
    const totalStars = 5;
    const totalStarsPercentage = 100;
    return (totalPoints * totalStars) / totalStarsPercentage;
  };

  handleGeneratePDF = async () => {
    // make API call to generate PDF
    const result = await generatePDF(this.props.token, this.state.candidateShown.user.phone);

    window.open(
      result.file,
      '_blank'
    );
  }

  handleStartWhatsAppChat = () => {
    const candidate_phone = this.state.candidateShown.user.phone;
    const candidate_name = this.state.candidateShown.user.name.split(" ")[0];
    const company_name = this.props.profile.companyName;
    const job_name = this.props.jobName;

    window.location.href = `https://wa.me/${candidate_phone}?text=Olá, ${candidate_name}. Somos da empresa ${company_name} e vimos seu perfil na plataforma JobSpot para a vaga de ${job_name}.
    Você tem interesse em conversar mais sobre essa oportunidade?`;
  }

  handleChangeTextfield = (event) => {
    const candidateShownUpdated = this.state.candidateShown;
    candidateShownUpdated.job_user.commentary = event.target.value;

    this.setState({
      candidateShown: candidateShownUpdated
    });
  };

  handleSaveCommentaryClick = async () => {

    const ratingObject = {
      rating: this.state.candidateShown.job_user.rating,
      commentary: this.state.candidateShown.job_user.commentary
    };

    const resultOfRating = await doSaveRating(this.props.token, this.props.jobId, this.state.candidateShown.user.id, ratingObject);

    const candidateShownUpdated = this.state.candidateShown;
    candidateShownUpdated.job_user.commentary = resultOfRating.commentary;

    this.setState({
      hiringStatus: resultOfRating.hiringStatus,
      candidateShown: candidateShownUpdated,
      change: !this.state.change,
      isSnackbarOpen: true
    });
  }

  handleIconClick = async (value) => {
    const ratingObject = {
      rating: value,
      commentary: this.state.candidateShown.commentary
    };

    const candidateShownUpdated = this.state.candidateShown;
    candidateShownUpdated.job_user.rating = value;

    this.setState({
      candidateShown: candidateShownUpdated,
      change: !this.state.change,
      candidateInfo: "rating"
    });

    const resultOfRating = await doSaveRating(this.props.token, this.props.jobId, this.state.candidateShown.user.id, ratingObject);

    this.setState({
      hiringStatus: resultOfRating.hiringStatus
    });
  }

  onImageDownload = () => {
    const svg = document.getElementById("qrcode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  handleGetAccessToCandidate = async () => {
    const responseOfDataAccess = await sendDataAccessRequest(this.props.token, this.props.jobId, this.state.candidateShown.user.id, this.state.candidateShown.job_user.hiringStatus);

    if (responseOfDataAccess.error_message) {
      this.setState({ openDialog: false });
      this.setState({ openDialogNotEnoughCredits: true });

    } else {

      this.props.current_plan.available_credits = this.props.current_plan.available_credits - 1;

      const candidateShownUpdated = this.state.candidateShown;
      candidateShownUpdated.job_user.hiringStatus = responseOfDataAccess.hiringStatus;
      this.setState({
        hiringStatus: responseOfDataAccess.hiringStatus,
        candidateShown: candidateShownUpdated,
        change: !this.state.change
      });

      this.props.doUpdateAvailableCredits();
      await this.unlockCandidate();
    }

  }

  handleFilterCheckbox = (favoriteFilterStatus) => {
    this.setState({ favoriteStatus: !favoriteFilterStatus });
  }

  generateRandomNumber = (limit) => {
    return { r: Math.floor(Math.random() * limit), g: Math.floor(Math.random() * limit), b: Math.floor(Math.random() * limit) }
  }

  handleChangeTab = (value) => {
    this.setState({ tabInfo: value });
  }

  loadFavoriteCandidates = () => {

    if (this.state.resultCandidates) {
      const { candidatos } = this.state.resultCandidates;
      const favorites = [];

      candidatos.forEach( candidato => {
        candidato.user.jobs.forEach( jobInfo => {
            if (jobInfo.favorite === true && jobInfo.job.id === Number(this.props.match.params.id)) {
              favorites.push(candidato);
              candidato.favorite = jobInfo.favorite;
            }
        });
      });

      this.setState({ favoriteCandidates: favorites });
      this.setState({ candidatos });
    }
  }

  handleIconAndTextFavorite = () => {
    if (this.state.candidateShown.job_user.favorite) {
      return <FavoriteIcon style={{ position: "relative", right: "5px" }} />
    } else {
      return <FavoriteBorderIcon style={{ position: "relative", right: "5px" }} />
    }
  }

  handleDeleteJob = () => {
    this.setState({ alertDeleteJob: true });
  }

  handleActivateJob = () => {
    this.props.doToggleJobActive();
    this.props.doPersistJob();
  }

  handleConfirmationDeleteJob = () => {
    doDeleteJob(this.props.token, this.props.jobId)
      .then(result => {
        this.setState({ alertDeleteJob: false });
        this.props.history.replace("/jobs");
      })
      .catch(e => {
        console.error(e);
        return;
      });
  }

  handleTextFavorite = () => {
    if (this.state.candidateShown.job_user.favorite) {
      return "Desfavoritar";
    } else {
      return "Favoritar";
    }
  }

  handleFavoritar = () => {
    favoriteCandidate(
      this.props.token,
      this.props.jobId,
      this.state.candidateShown.user.id
    ).then(() => {
      if (!this.state.candidateShown.job_user.favorite) {

        const candidateShownUpdated = this.state.candidateShown;
        candidateShownUpdated.job_user.favorite = true;

        this.setState({
          showSnackbar: true,
          snackbarMessage: "Favoritado com sucesso!",
          candidateShown: candidateShownUpdated,
          change: !this.state.change
        });
      } else {

        const candidateShownUpdated = this.state.candidateShown;
        candidateShownUpdated.job_user.favorite = false;

        this.setState({
          showSnackbar: true,
          snackbarMessage: "Removido dos favoritos com sucesso!",
          candidateShown: candidateShownUpdated,
          change: !this.state.change
        });
      }

      this.forceUpdate();

      setTimeout(() => {
        this.setState({
          showSnackbar: false
        });
      }, 3000);
    });
  };

  copyCode = () => {
    navigator.clipboard
      .writeText(`https://app.jobspot.com.br/redirect?url=` + jasypt.encrypt(encodeURI(`https://wa.me/555140422006?text=Olá! Quero participar do processo seletivo para a vaga: ${this.props.jobName}! [${this.state.firstPositionJob.id}]`)))
      .then(() => {
        this.setState({ isSnackbarOpen: true });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  loadJob = () => {
    const { token, doRestoreJobDbState } = this.props;

    doLoadJob(token, this.props.match.params.id)
      .then(job => {
        this.setState({ firstPositionJob: job.professions[0].profession });

        doRestoreJobDbState(job);
        this.setState({
          loadingJob: false
        });
      })
      .catch(e => {
        this.props.history.replace("/jobs");
        console.error(e);
        return;
      });
  };

  returnMessageWhenNoCandidateIsFound(candidates) {
    if (!candidates) {
      return 'Não há nenhum candidato com os critérios desejados.';
    }

    if (candidates.length === 0 || candidates === undefined) {
      return 'Não há nenhum candidato com os critérios desejados.';
    }

    return candidates;
  }

  convertBirthday(birthday) {
    const splitValue = birthday.split("/");
    const day = Number(splitValue[0]) + 1;
    const month = Number(splitValue[1]);
    const year = Number(splitValue[2]);

    return new Date(`${year}/${month}/${day}`).toLocaleString().split(',')[0];
  }

  handleChange = (value) => {
    this.setState({ tab: value });
  }

  handleChangeCandidateInfoTab = (value) => {
    this.setState({ candidateInfo: value });
  }

  updateCurrentCandidateFromChild = (currentCandidate) => {

    let updatedCurrentCandidate = currentCandidate;
    const updatedCriterios = [];

    const listItens = [
      {
        name: "FORMA_CONTRATACAO",
        label: "Forma de Contratação",
        active: this.props.hiringConfirmed,
        grade: this.props.hiringConfirmed ? this.props.hiringGrade : 0.0
      },
      {
        name: "DISPONIBILIDADE",
        label: "Disponibilidade",
        active: this.props.availabilityConfirmed,
        grade: this.props.availabilityConfirmed ? this.props.availabilityGrade : 0.0
      },
      {
        name: "FAIXA_ETARIA",
        label: "Faixa Etária",
        active: this.props.ageRangeConfirmed,
        grade: this.props.ageRangeConfirmed ? this.props.ageRangeGrade : 0.0
      },
      {
        name: "HABILIDADES",
        label: "Habilidades",
        active: this.props.skillsConfirmed,
        grade: this.props.skillsConfirmed ? this.props.skillsGrade : 0.0
      },
      {
        name: "LOCALIDADE",
        label: "Localidade",
        active: this.props.placesConfirmed,
        grade: this.props.placesConfirmed ? this.props.placesGrade : 0.0
      },
      {
        name: "NECESSIDADES_ESPECIAIS",
        label: "Necessidades Especiais",
        active: this.props.specialNeedsConfirmed,
        grade: this.props.specialNeedsConfirmed ? this.props.specialNeedsGrade : 0.0
      },
      {
        name: "PROFISSAO",
        label: "Profissão",
        active: this.props.profissaoConfirmed,
        grade: this.props.profissaoConfirmed ? this.props.profissaoGrade : 0.0
      },
      {
        name: "QUALIFICACAO",
        label: "Qualificação",
        active: this.props.qualificationConfirmed,
        grade: this.props.qualificationConfirmed ? this.props.qualificationGrade : 0.0
      },
      {
        name: "SEXO",
        label: "Sexo",
        active: this.props.genderConfirmed,
        grade: this.props.genderConfirmed ? this.props.genderGrade : 0.0
      }
    ];

    listItens.forEach((element, position) => {
      if (element.active) {
        currentCandidate.criterios.forEach((e) => {
          if (element.name === e.idDescricao) {
            if (e.pontos === "-Infinity") {
              e.pontos = 0.0;
            }
            e.name = element.label;
            updatedCriterios.push(e);
          }
        })
      }
    })

    updatedCurrentCandidate.criterios = updatedCriterios;
    
    if (updatedCurrentCandidate.job_user === undefined || updatedCurrentCandidate.job_user === null) {
      updatedCurrentCandidate.job_user = {
        hiringStatus: "00"
      };
    }

    //updatedCurrentCandidate.user.contractingModalities
    //updatedCurrentCandidate.user.shiftPreferences
    //updatedCurrentCandidate.user.dateOfBirth
    //updatedCurrentCandidate.user.city.name + updatedCurrentCandidate.user.state.initials
    //updatedCurrentCandidate.user.specialNeeds
    //updatedCurrentCandidate.user.ocupations[i].ocupation.text

    if (updatedCurrentCandidate.job_user.hiringStatus[1] === "5" || updatedCurrentCandidate.job_user.hiringStatus === "40") {

      let hiddenInfoWillAppear = [];
      let contractingModalities = "";
      let shiftPreferences = "";
      let dateOfBirth = this.convertBirthday(updatedCurrentCandidate.user.dateOfBirth);
      let local = `${updatedCurrentCandidate.user.city.name}/${updatedCurrentCandidate.user.state.initials}`;
      let specialNeeds = "";
      let ocupations = "";

      updatedCurrentCandidate.user.contractingModalities.forEach((element) => {
        contractingModalities += `${element.text} `;
      });

      updatedCurrentCandidate.user.shiftPreferences.forEach((element) => {
        shiftPreferences += `${element.text} `; 
      })

      if (updatedCurrentCandidate.user.specialNeeds.length === 0) {
          specialNeeds = "Não possui necessidades especiais";
      } else {
          updatedCurrentCandidate.user.specialNeeds.forEach((element) => {
            specialNeeds = `${element.text} `; 
          });
      }

      if (updatedCurrentCandidate.user.ocupations.length === 0) {
          ocupations = "Não possui experiência profissional prévia";
      } else {
          updatedCurrentCandidate.user.ocupations.forEach((element) => {
            ocupations = `${element.ocupation.text} (${element.experiencePeriod.text})`;
          });
      }


      hiddenInfoWillAppear =  [
        {
          name: "Contratação",
          value: contractingModalities
        },
        {
          name: "Disponibilidade",
          value: shiftPreferences
        },
        {
          name: "Data de Nascimento",
          value: dateOfBirth
        },
        {
          name: "Localidade",
          value: local
        },
        {
          name: "Necessidades Especiais",
          value: specialNeeds
        },
        {
          name: "Profissão",
          value: ocupations
        }
      ]

      this.setState({ extraInfo: hiddenInfoWillAppear });

    } else {

      const hiddenValues = [
        {
          name: "Contratação",
          value: "Oculto"
        },
        {
          name: "Disponibilidade",
          value: "Oculto"
        },
        {
          name: "Data de Nascimento",
          value: "Oculto"
        },
        {
          name: "Localidade",
          value: "Oculto"
        },
        {
          name: "Necessidades Especiais",
          value: "Oculto"
        },
        {
          name: "Profissão",
          value: "Oculto"
        }
      ];

      this.setState({ extraInfo: hiddenValues });
    }
    
    this.setState({ candidateShown: updatedCurrentCandidate });
  }

  unlockCandidate = async () => {

    this.props.current_plan.available_credits = this.props.current_plan.available_credits - 1;

    //this.props.profile.current_plan.access_plan.available_credits = this.props.profile.current_plan.access_plan.available_credits - 1;

    let statusCode = this.state.candidateShown.job_user.hiringStatus;
    let userId = this.state.candidateShown.user.id;

    if (statusCode === undefined || statusCode === null || statusCode === "00") {
      statusCode = "05";
    } else {
      statusCode = statusCode.slice(0, -1) + '5';
    }

    const responseOfDataAccess = await unlockCandidateStatusProfile(this.props.token, this.props.jobId, userId, statusCode);

    if (responseOfDataAccess.error_message) {
      this.setState({ openDialog: false });
      this.setState({ openDialogNotEnoughCredits: true });
    } else {

      this.props.doUpdateAvailableCredits();

      let shownCandidateUpdated = this.state.candidateShown;
      shownCandidateUpdated.job_user.hiringStatus = responseOfDataAccess.hiringStatus;

      this.setState({ 
        candidateShown: shownCandidateUpdated,
        change: !this.state.change
      });

        let hiddenInfoWillAppear = [];
        let contractingModalities = "";
        let shiftPreferences = "";
        let dateOfBirth = this.convertBirthday(this.state.candidateShown.user.dateOfBirth);
        let local = `${this.state.candidateShown.user.city.name}/${this.state.candidateShown.user.state.initials}`;
        let specialNeeds = "";
        let ocupations = "";
  
        this.state.candidateShown.user.contractingModalities.forEach((element) => {
          contractingModalities += `${element.text} `;
        });
  
        this.state.candidateShown.user.shiftPreferences.forEach((element) => {
          shiftPreferences += `${element.text} `; 
        })
  
        if (this.state.candidateShown.user.specialNeeds.length === 0) {
            specialNeeds = "Não possui necessidades especiais";
        } else {
          this.state.candidateShown.user.specialNeeds.forEach((element) => {
              specialNeeds = `${element.text} `; 
            });
        }
  
        if (this.state.candidateShown.user.ocupations.length === 0) {
            ocupations = "Não possui experiência profissional prévia";
        } else {
          this.state.candidateShown.user.ocupations.forEach((element) => {
              ocupations = `${element.ocupation.text} (${element.experiencePeriod.text})`;
            });
        }
  
  
        hiddenInfoWillAppear =  [
          {
            name: "Contratação",
            value: contractingModalities
          },
          {
            name: "Disponibilidade",
            value: shiftPreferences
          },
          {
            name: "Data de Nascimento",
            value: dateOfBirth
          },
          {
            name: "Localidade",
            value: local
          },
          {
            name: "Necessidades Especiais",
            value: specialNeeds
          },
          {
            name: "Profissão",
            value: ocupations
          }
        ]
  
        this.setState({ extraInfo: hiddenInfoWillAppear });
      }
  }

  doUpdateCandidates(size = this.state.candidatesToShow) {
    this.setState({
      loadingCandidates: true
    });

    doLoadJobCandidatesProfiles(
      this.props.token,
      this.props.match.params.id
    ).then(result => {
      this.setState({
        loadingCandidates: false,
        resultCandidates: result
      });

      this.loadFavoriteCandidates();

      if (result && result.candidatos && result.candidatos.length > 0) {
        this.setState({
          maxPoints: result.candidatos[0].totalPontos
        });
      }
    });
  }

  handleChange = (event, value) => {
    this.setState({ tab: event });
    this.forceUpdate();
  }

  handleCandidateFeedbackBackgroundColor = (code) => {
    if (code[0] === '3') {
      return 'darkred';
    } else if (code[0] === '4') {
      return 'green';
    } else {
      return 'grey';
    }
  }

  handleCandidateFeedbackMessage = (code) => {
    if (code[0] === '3') {
      return 'Solicitação recusada';
    }

    if (code[0] === '4') {
      return 'Solicitação aceita';
    }

    return 'Resposta da solicitação';
  }

  handleClose = () => {
    this.setState({ openDialog: false });
    this.setState({ openDialogNotEnoughCredits: false });
    this.setState({ alertDeleteJob: false });
  };

  handleBreadcrumbLabels = (hiringStatus) => {
    if (hiringStatus[0] === '1') {
      return "Solicitação enviada";
    }

    if (hiringStatus[0] === '2') {
      return "Solicitação visualizada";
    }

    return "Aguardando ação";
  }

  buildCandidateName = (currentStatus) => {
    if (currentStatus[0] === '4') {
      return this.state.candidateShown.user.name;
    } else {
      return this.state.candidateShown.user.publicId;
    }
  }

  render() {
    const { classes, width, history, jobName, jobActive } = this.props;
    const { isSnackbarOpen, tabInfo, candidateShown, candidateInfo, openDialogNotEnoughCredits, alertDeleteJob } = this.state;
    let hiringStatus = this.state.candidateShown.job_user.hiringStatus;

    const showUnlockProfessionalData = hiringStatus.includes("5") || hiringStatus === "40" ? true : false;

    const convidado = false;
    const audioFileReference = null;

    const isMobile = ["xs", "sm", "md"].includes(width);
    const listItens = [
      {
        name: "Contratação",
        active: this.props.hiringConfirmed,
        grade: this.props.hiringConfirmed ? this.props.hiringGrade : 0.0
      },
      {
        name: "Disponibilidade",
        active: this.props.availabilityConfirmed,
        grade: this.props.availabilityConfirmed ? this.props.availabilityGrade : 0.0
      },
      {
        name: "Faixa etária",
        active: this.props.ageRangeConfirmed,
        grade: this.props.ageRangeConfirmed ? this.props.ageRangeGrade : 0.0
      },
      {
        name: "Habilidades",
        active: this.props.skillsConfirmed,
        grade: this.props.skillsConfirmed ? this.props.skillsGrade : 0.0
      },
      {
        name: "Localidade",
        active: this.props.placesConfirmed,
        grade: this.props.placesConfirmed ? this.props.placesGrade : 0.0
      },
      {
        name: "Necessidades Especiais",
        active: this.props.specialNeedsConfirmed,
        grade: this.props.specialNeedsConfirmed ? this.props.specialNeedsGrade : 0.0
      },
      {
        name: "Profissão",
        active: this.props.profissaoConfirmed,
        grade: this.props.profissaoConfirmed ? this.props.profissaoGrade : 0.0
      },
      {
        name: "Qualificação",
        active: this.props.qualificationConfirmed,
        grade: this.props.qualificationConfirmed ? this.props.qualificationGrade : 0.0
      },
      {
        name: "Sexo",
        active: this.props.genderConfirmed,
        grade: this.props.genderConfirmed ? this.props.genderGrade : 0.0
      }
    ];

    const listItensCandidate = [];

    listItens.forEach((element) => {
      if (element.active) {
        listItensCandidate.push(element);
      }
    })

    const avatar = `https://api.dicebear.com/8.x/initials/svg?seed=${candidateShown.user.publicId}&backgroundColor=0062E4`;

    return (
      <div className={classes.root}>
        <TopBar
          pageTitle={jobName}
          buttonTitle="Editar"
          hasButton={false}
          actionButton={() => history.replace("/jobs")}
          actionBack={() => history.replace("/jobs")}
        />

        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={6}
            className={classNames(classes.card, {
              [classes.cardActive]: isMobile
            })}
          >
            <Card>
            <Tabs value={tabInfo} centered onChange={this.onChange} sx={{ '& .MuiTabs-flexContainer': { flexWrap: 'nowrap', } }}>
                <Tab
                  value={"one"}
                  label={<span style={{ fontSize: isMobile ? '9px' : '12px' }}>Informações da Vaga</span>}
                  onClick={() => this.handleChangeTab('one')}
                  icon={<AlignHorizontalLeftIcon />}
                />
                <Tab
                  value={"two"}
                  label={<span style={{ fontSize: isMobile ? '9px' : '12px' }}>Lista de Candidatos</span>}
                  onClick={() => this.handleChangeTab('two')}
                  icon={<GroupIcon />}
                />                   
                <Tab
                  value={"three"}
                  label={<span style={{ fontSize: isMobile ? '9px' : '12px' }}>Compartilhar Vaga</span>}
                  onClick={() => this.handleChangeTab('three')}
                  icon={<ShareIcon />}
                />                
              </Tabs>
              <CardContent
                className={classNames(classes.cardContent)}
              >
              { tabInfo === "one" ? (
                <>
                  <List className={classNames(classes.list, {
                    [classes.cardContentInactive]: !jobActive
                  })} style={{ padding: "8px" }} >
                    {listItens.map((item, key) => (
                      <ListItem key={key} style={{ padding: "0px 5px" }}>
                        <ListItemIcon>
                          {item.active ? (
                            <ToggleOnIcon className={classes.iconActive} />
                          ) : (
                            <ToggleOffIcon className={classes.iconInactive} />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          className={classes.listItemText}
                          primary={item.name}
                          secondary={<ImportanceRange range={Number(item.grade)} />}
                        />
                      </ListItem>
                    ))}
                  </List>
                  
                    <div id="action_buttons" style={{ display: "flex" }}>
                    <Fab
                      variant="extended"
                      elevation={0}
                      size="medium"
                      color="primary"
                      aria-label="Edit"
                      className={classes.fab}
                      onClick={() => history.replace("/job")}
                    >
                    <EditIcon style={{ marginRight: 8 }} />
                      Editar
                    </Fab>

                    <Fab
                      variant="extended"
                      elevation={0}
                      size="medium"
                      color="primary"
                      aria-label="Disable"
                      className={this.props.jobActive ? classes.fabDeactivate : classes.fab}
                      onClick={this.handleActivateJob}
                    >
                    <BlockIcon style={{ marginRight: 8 }} />
                      { this.props.jobActive ? "Desativar" : "Ativar" }
                    </Fab>

                    <Fab
                      variant="extended"
                      elevation={0}
                      size="medium"
                      color="primary"
                      aria-label="Delete"
                      className={classes.fabDeactivate}
                      onClick={this.handleDeleteJob}
                    >
                    <DeleteIcon style={{ marginRight: 8 }} />
                      Excluir
                    </Fab>
                    <Dialog
                      TransitionComponent={Transition}
                      maxWidth={"md"}
                      open={alertDeleteJob}
                      //open={true}
                      onClose={this.handleClose}
                      aria-labelledby="responsive-dialog-title"
                    >
                      <DialogTitle id="responsive-dialog-title" style={{ backgroundColor: "white", textAlign: "center" }}>
                        Tem certeza que deseja excluir essa vaga? <br />
                        Você perderá as interações e outros dados referentes a essa vaga.
                        <br />
                      </DialogTitle>
                    <div id="confirmation_delete_buttons" style={{ display: "flex", padding: "10px" }}>
                      <Fab
                        variant="extended"
                        elevation={0}
                        size="small"
                        color="primary"
                        aria-label="Delete"
                        className={classes.fab}
                        onClick={this.handleClose}
                      >
                        Cancelar
                      </Fab>

                      <Fab
                        variant="extended"
                        elevation={0}
                        size="small"
                        color="primary"
                        aria-label="Delete"
                        className={classes.fabDeactivate}
                        onClick={this.handleConfirmationDeleteJob}
                      >
                        Excluir
                      </Fab>
                    </div>

                    </Dialog>
                    </div>
                  </>                 
                  ) : tabInfo === "three" ? (
                    <Typography align="center" component="span" className={classNames(classes.list, {
                      [classes.cardContentInactive]: !jobActive
                    })}>
                      <Persona
                        className={classes.persona}
                        hideJobin={ false }
                        text={`Utilize o QR Code abaixo para compartilhar a vaga! Usando esse QR Code, mais candidatos saberão sobre o seu processo seletivo!`}
                      />
                      <div style={{ textAlign: "center" }}>
                        <div className="HpQrcode">
                          <QRCode id="qrcode" align="center" value={encodeURI(`https://wa.me/555140422006?text=Olá! Quero participar do processo seletivo para a vaga: ${this.props.jobName}! [${this.state.firstPositionJob.id}]`)} />
                        </div>                        
                           
                          <br />
                        <input align="center" size="33" disabled value={`https://app.jobspot.com.br/redirect?url=` + jasypt.encrypt(encodeURI(`https://wa.me/555140422006?text=Olá! Quero participar do processo seletivo para a vaga: ${this.props.jobName}! [${this.state.firstPositionJob.id}]`))}/>
                          <br />
                          <br />
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ width: "200px !important"}}
                          startIcon={<FileCopyIcon />}
                          onClick={this.copyCode}
                        >
                          Copiar link
                        </Button>
                        <Snackbar
                          open={isSnackbarOpen}
                          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                          message="Texto copiado!"
                          onClose={() => this.setState({ isSnackbarOpen: false })}
                          onClick={() => this.setState({ isSnackbarOpen: false })}
                          style={{ cursor: "pointer", width: "200px" }}
                          autoHideDuration={2000}
                        />
                        <br />
                        <br />
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ width: "200px !important"}}
                          startIcon={<GetAppIcon />}
                          onClick={() => this.onImageDownload()}
                        >
                          Baixar QR Code
                        </Button>                   
                      </div>
                    </Typography>
                  ) : (tabInfo === "two" && !this.state.loadingCandidates && this.state.resultCandidates) ? (
                    <CandidatosList
                      className={classNames(classes.list, {
                        [classes.cardContentInactive]: !jobActive
                      })}
                      history={this.props.history}
                      jobId={this.props.jobId}
                      change={this.state.change}
                      candidatos={
                        this.state.tab === 'two' ? 
                          this.returnMessageWhenNoCandidateIsFound(this.state.favoriteCandidates) :
                          this.returnMessageWhenNoCandidateIsFound(this.state.resultCandidates.candidatos)
                      }
                      candidatosFavoritos={
                        this.returnMessageWhenNoCandidateIsFound(this.state.favoriteCandidates)
                      }
                      maxPoints={this.state.maxPoints}
                      isMobile={isMobile}
                      showFavorites={this.state.tab === 'two'}
                      showStartedChats={this.state.tab === 'three'}
                      updateSelectedCandidate={this.updateCurrentCandidateFromChild}
                    />) :
                    <div id="circular_candidate" align="center" component="p" style={{ paddingTop: "40px", paddingBottom: "40px" }}>
                      <CircularProgress style={{ align: "center", display: "inline-block", width: "40px", height: "40px" }} />
                      <p>Buscando candidatos para a vaga...</p>
                    </div>
                }
              </CardContent>     
            </Card>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            lg={6}
            className={classNames(classes.card, {
              [classes.cardActive]: isMobile
            })}
          >
            <Card>
              { this.state.candidateShown.user.publicId === undefined ? (<> </>) : (
              <>
                <Tabs value={candidateInfo} centered onChange={this.onChange} sx={{ '& .MuiTabs-flexContainer': { flexWrap: 'nowrap', } }}>
                  <Tab
                    value={"basic"}
                    label={<span style={{ fontSize: isMobile ? '9px' : '12px' }}>Perfil do Candidato</span>}
                    onClick={() => this.handleChangeCandidateInfoTab("basic")}
                    icon={<PersonPinIcon />}
                  />
                  <Tab
                    value={"extra"}
                    label={<span style={{ fontSize: isMobile ? '9px' : '12px' }}>Informações Profissionais</span>}
                    onClick={() => this.handleChangeCandidateInfoTab("extra")}
                    icon={<LockIcon />}
                  />
                  <Tab
                    value={"rating"}
                    label={<span style={{ fontSize: isMobile ? '9px' : '12px' }}>Avaliação do Perfil</span>}
                    onClick={() => this.handleChangeCandidateInfoTab("rating")}
                    icon={<ThumbsUpDownIcon />}
                  />
                </Tabs>
                {
                  this.state.candidateInfo === "basic" ? (
                  <>
                  <CardContent>
                    <div style={{ 
                        paddingBottom: "10px",
                        display: "flex",
                        justifyContent: "space-evenly"
                      }}
                    >
                      <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <StyledBreadcrumb style={{ backgroundColor: hiringStatus === null || hiringStatus[0] === '0' || hiringStatus === 0 ? 'green' : 'grey' }} component="a" href="#" label="Início" />
                        <StyledBreadcrumb style={{ backgroundColor: hiringStatus[0] === '1' || hiringStatus[0] === '2' ? 'green' : 'grey' }} component="a" href="#" label={this.handleBreadcrumbLabels(hiringStatus) } />
                        <StyledBreadcrumb style={{ backgroundColor: this.handleCandidateFeedbackBackgroundColor(hiringStatus) }} component="a" href="#" label={this.handleCandidateFeedbackMessage(hiringStatus)} />
                      </Breadcrumbs>
                    </div>
                    <Avatar
                      src={avatar}
                      style={{ width: 70, height: 70, margin: "10px auto" }}
                    />
                  <div id="name_rating" style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <span>{ this.buildCandidateName(hiringStatus) }</span>
                    <Rating
                      name="simple-controlled"
                      size="small"
                      readOnly={true}
                      value={this.calculateStars(candidateShown.totalPontos)}
                      precision={0.1}
                    />
                  </div>
                  <div id="bubbly">
              <div id="buttons" style={{ display: "flex", justifyContent: "space-evenly", padding: "10px" }}>  
                <Button
                    style={{
                      backgroundColor: "#1976D2",
                      color: "white",
                      width: "125px", 
                      height: "25px",
                      fontSize: "10px",
                      borderRadius: "20px",
                      disabled: true
                    }}
                  onClick={() => this.handleFavoritar()}
                  >
                    {this.handleIconAndTextFavorite()} { this.handleTextFavorite() }
                    
                </Button>
                {hiringStatus === null && (
                  <Button
                  style={{
                    backgroundColor: "#1976D2",
                    color: "white",
                    width: "165px", 
                    height: "25px",
                    fontSize: "small",
                    borderRadius: "20px"
                  }}
                  onClick={() => this.handleGetAccessToCandidate()}
                >
                    <span style={{ fontSize: "9px" }}>Liberar dados pessoais</span>
                </Button>
                )}

                {hiringStatus === 0 && (
                  <Button
                  style={{
                    backgroundColor: "#1976D2",
                    color: "white",
                    width: "165px", 
                    height: "25px",
                    fontSize: "small",
                    borderRadius: "20px"
                  }}
                  onClick={() => this.handleGetAccessToCandidate()}
                >
                  <span style={{ fontSize: "9px" }}>Liberar dados pessoais</span>
                </Button>
                )}

                {hiringStatus[0] === '0' && (
                  <Button
                  style={{
                    backgroundColor: "#1976D2",
                    color: "white",
                    width: "165px", 
                    height: "25px",
                    fontSize: "small",
                    borderRadius: "20px"
                  }}
                  onClick={() => this.handleGetAccessToCandidate()}
                >
                  <span style={{ fontSize: "9px" }}>Liberar dados pessoais</span>
                </Button>
                )}

                {hiringStatus[0] === '4' && !convidado && (
                  <Button
                  style={{
                    backgroundColor: "#1976D2",
                    color: "white",
                    width: "160px", 
                    height: "25px",
                    fontSize: "small",
                    borderRadius: "20px"
                  }}
                  onClick={ this.handleStartWhatsAppChat }
                >
                  <WhatsAppIcon style={{ position: "relative", right: "5px" }} />
                  <span style={{ fontSize: "7px" }}>Conversar via WhatsApp</span>
                </Button>
                )}
                {hiringStatus[0] === '4' && convidado && (
                  <Button
                  style={{
                    backgroundColor: "#1976D2",
                    color: "white",
                    width: "160px", 
                    height: "25px",
                    fontSize: "small",
                    borderRadius: "20px"
                  }}
                  onClick={ this.handleStartWhatsAppChat }
                >
                  <WhatsAppIcon style={{ position: "relative", right: "5px" }} />
                  <span style={{ fontSize: "7px" }}>Conversar via WhatsApp</span>
                </Button>
                )}
                {hiringStatus[0] === "4" && (
                  <Button
                  style={{
                    backgroundColor: "#1976D2",
                    color: "white",
                    width: "125px", 
                    height: "25px",
                    fontSize: "small",
                    borderRadius: "20px"
                  }}
                  onClick={() => this.handleGeneratePDF()}
                >
                  <DescriptionIcon style={{ position: "relative", right: "5px" }} />
                  <span style={{ fontSize: "7px" }}>Imprimir perfil</span>
                </Button>                  
                )}
              </div>
            <div id="rating_candidate" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <small>Gostou desse candidato?</small>
              <div id="rating_candidate_icons">
                <SentimentVeryDissatisfiedIcon onClick={() => this.handleIconClick(1)} className={ this.state.candidateShown.job_user.rating === 1 ? classes.veryUnsatisfiedChecked : classes.veryUnsatisfied } />
                <SentimentDissatisfiedIcon onClick={() => this.handleIconClick(2)} className={ this.state.candidateShown.job_user.rating === 2 ? classes.unsatisfiedChecked : classes.unsatisfied } />
                <SentimentSatisfiedIcon onClick={() => this.handleIconClick(3)} className={ this.state.candidateShown.job_user.rating === 3 ? classes.neutralChecked : classes.neutral } />
                <SentimentSatisfiedAltIcon onClick={() => this.handleIconClick(4)} className={ this.state.candidateShown.job_user.rating === 4 ? classes.satisfiedChecked : classes.satisfied } />
                <SentimentVerySatisfiedIcon onClick={() => this.handleIconClick(5)} className={ this.state.candidateShown.job_user.rating === 5 ? classes.verySatisfiedChecked : classes.verySatisfied } />
              </div>
            </div>
            {(hiringStatus[0] === '4' && audioFileReference !== null) && (
              <div style={{ paddingTop: "10px", position: "relative", left: "125px" }}>
                <audio src={audioFileReference} preload="metadata" controls controlsList="nodownload" />
              </div>
            )}

            {(hiringStatus[0] === '4' && audioFileReference === null) && (
              <div style={{ paddingTop: "10px", position: "relative", left: "90px", fontSize: "small" }}>
                <span>O candidato não possui um áudio de apresentação gravado.</span>
              </div>
            )}
            </div>
                  <List className={classes.list} style={{ padding: "8px" }} >
                    {candidateShown.criterios.map((item, key) => (
                      <ListItem key={key} style={{ padding: "0px 5px" }}>
                        <ListItemText
                          className={classes.listItemText}
                          primary={item.name}
                          secondary={<Rating
                            name="simple-controlled"
                            size="small"
                            readOnly={true}
                            value={this.calculateStars(item.pontos)}
                            precision={0.1}
                        />}
                        />
                  </ListItem>
                    ))}
                  </List>
                  </CardContent></>) : this.state.candidateInfo === "extra" ? (
                  <>
                  <Dialog
                    TransitionComponent={Transition}
                    maxWidth={"md"}
                    open={openDialogNotEnoughCredits}
                    //open={true}
                    onClose={this.handleClose}
                    aria-labelledby="responsive-dialog-title"
                  >
                  <AppBar position="static">
                      <Toolbar>
                        <IconButton color="inherit" onClick={() => this.handleClose()}>
                          <CloseIcon />
                        </IconButton>
                        <Typography
                          variant="h6"
                          color="inherit"
                          className={classes.title}
                        >
                          Mensagem do Jobin!
                        </Typography>
                      </Toolbar>
                    </AppBar>
          
                    <DialogTitle id="responsive-dialog-title" style={{ backgroundColor: "white", textAlign: "center" }}>
                      Você não possui créditos suficientes para concluir essa ação. <br />Efetue sua recarga utilizando o link abaixo:
                      <br />
                      <a href="https://billing.stripe.com/p/login/bIYeYd4bKdR29eU6oo">Recarga de créditos JobSpot</a>
                      <br />
                      Está com dificuldade? Fale conosco através do nosso Whatsapp!
                      <b>Telefone e WhatsApp:</b><a href="https://wa.me/555140422006?text=Oi, estou tentando ver um perfil para minha vaga mas estou sem créditos. Gostaria de comprar mais créditos, por favor!">+55 51 4042.2006</a>
                    </DialogTitle>
                  </Dialog>
                  <div id="button_unlock_professional_data">
                    <Button 
                      onClick={ this.unlockCandidate }
                      style={{
                        position: "relative",
                        top: "100px",
                        left: "200px",
                        zIndex: "1",
                        backgroundColor: "#0062E4",
                        color: "white",
                        visibility: showUnlockProfessionalData ? "hidden" : "visible"
                      }}
                    >
                      Liberar dados profissionais
                    </Button>
                  </div>
                  <p style={{
                    zIndex: "1",
                    position: "relative",
                    top: "110px",
                    left: "175px",
                    width: "325px",
                    textAlign: "center",
                    fontSize: "small",
                    visibility: showUnlockProfessionalData ? "hidden" : "visible"
                  }}
                  >Por apenas 1 crédito do seu plano, você pode liberar mais informações sobre esse candidato</p>
                <CardContent 
                  style={{ 
                    filter: showUnlockProfessionalData ? "blur(0px)" : "blur(3px)"
                  }}>
                  <List className={classes.list} style={{ padding: "8px" }} >
                      {this.state.extraInfo.map((item, key) => (
                        <ListItem key={key} style={{ padding: "0px 5px" }}>
                          <ListItemText
                            className={classes.listItemText}
                            primary={item.name}
                            secondary={item.value}
                          />
                    </ListItem>
                      ))}
                  </List>                    
                </CardContent>
                  </>
                  ) : (
                    <div id="rating_tab">
                    <CardContent>
                      <div id="rating_area">
                      <div id="rating_candidate" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <small>Gostou desse candidato?</small>
                        <div id="rating_candidate_icons">
                          <SentimentVeryDissatisfiedIcon onClick={() => this.handleIconClick(1)} className={ this.state.candidateShown.job_user.rating === 1 ? classes.veryUnsatisfiedChecked : classes.veryUnsatisfied } />
                          <SentimentDissatisfiedIcon onClick={() => this.handleIconClick(2)} className={ this.state.candidateShown.job_user.rating === 2 ? classes.unsatisfiedChecked : classes.unsatisfied } />
                          <SentimentSatisfiedIcon onClick={() => this.handleIconClick(3)} className={ this.state.candidateShown.job_user.rating === 3 ? classes.neutralChecked : classes.neutral } />
                          <SentimentSatisfiedAltIcon onClick={() => this.handleIconClick(4)} className={ this.state.candidateShown.job_user.rating === 4 ? classes.satisfiedChecked : classes.satisfied } />
                          <SentimentVerySatisfiedIcon onClick={() => this.handleIconClick(5)} className={ this.state.candidateShown.job_user.rating === 5 ? classes.verySatisfiedChecked : classes.verySatisfied } />
                        </div>

                        <TextField
                          id="standard-multiline-flexible"
                          label="Anotações sobre o candidato"
                          variant="outlined"
                          onChange={this.handleChangeTextfield}
                          multiline
                          style={{ width: "500px", paddingBottom: "20px" }}
                          maxRows={6}
                          value={this.state.candidateShown.job_user.commentary}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<FileCopyIcon />}
                          onClick={() => this.handleSaveCommentaryClick()}
                        >
                          Salvar Anotação
                        </Button>
                      </div>
                      </div>
                    </CardContent>
                  </div>
                  )
                }

              </>
              )}
            </Card>
          </Grid>
        </Grid>
        <Snackbar
          open={isSnackbarOpen}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          message="Sua avaliação e anotações sobre o candidato foram salvas!"
          onClose={() => this.setState({ isSnackbarOpen: false })}
          onClick={() => this.setState({ isSnackbarOpen: false })}
          style={{ cursor: "pointer" }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.profile.profile,
  current_plan: state.profile.profile.current_plan,
  qualificationConfirmed: state.job.qualificationConfirmed,
  qualificationGrade: state.job.qualificationGrade,
  profissaoConfirmed: state.job.profissaoConfirmed,
  profissaoGrade: state.job.profissaoGrade,
  jobActive: state.job.jobActive,
  jobName: state.job.jobName,
  jobId: state.job.jobId,
  token: state.login.token,
  specialNeedsConfirmed: state.job.specialNeedsConfirmed,
  specialNeedsGrade: state.job.specialNeedsGrade,
  specialNeedsSubOptions: state.job.specialNeedsSubOptions,
  placesConfirmed: state.job.placesConfirmed,
  placesGrade: state.job.placesGrade,
  placesState: state.job.placesState,
  placesCity: state.job.placesCity,
  currentCityGrade: state.job.currentCityGrade,
  otherCityGrade: state.job.otherCityGrade,
  hiringConfirmed: state.job.hiringConfirmed,
  hiringGrade: state.job.hiringGrade,
  hiringSubOptions: state.job.hiringSubOptions,
  availabilityConfirmed: state.job.availabilityConfirmed,
  availabilityGrade: state.job.availabilityGrade,
  availabilitySubOptions: state.job.availabilitySubOptions,
  ageRangeConfirmed: state.job.ageRangeConfirmed,
  ageRangeGrade: state.job.ageRangeGrade,
  ageRangeSubOptions: state.job.ageRangeSubOptions,
  skillsConfirmed: state.job.skillsConfirmed,
  skillsGrade: state.job.skillsGrade,
  skillsSubOptions: state.job.skillsSubOptions,
  genderConfirmed: state.job.genderConfirmed,
  genderGrade: state.job.genderGrade,
  genderSubOptions: state.job.genderSubOptions,
  tagsConfirmed: state.job.tagsConfirmed,
  tagGrade: state.job.tagGrade,
  tagsSubOptions: state.job.tagsSubOptions,
  states: state.formData.states
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doRestoreJobDbState,
      doLoadAndKeepEducationLevels,
      doToggleQualification,
      doSelectQualificationGrade,
      doToggleProfissao,
      doSelectProfissaoGrade,
      doPersistJob,
      doToggleJobActive,
      doToggleSpecialNeeds,
      doSelectSpecialNeedsGrade,
      doSelectSpecialNeedSubOptionGrade,
      doTogglePlaces,
      doSelectPlacesGrade,
      doSelecPlacesState,
      doSelecPlacesCity,
      doSelectCurrentCitySelectGrade,
      doSelectOtherCitySelectGrade,
      doToggleHiring,
      doSelectHiringGrade,
      doSelectHiringSubOptionGrade,
      doToggleAvailability,
      doSelectAvailabilityGrade,
      doSelectAvailabilitySubOptionGrade,
      doToggleAgeRange,
      doSelectAgeRangeGrade,
      doSelectAgeRangeSubOptionGrade,
      doToggleSkills,
      doSelectSkillsGrade,
      doSelectSkillsubOptionGrade,
      doToggleGender,
      doSelectGenderGrade,
      doSelectGenderSubOptionGrade,
      doToggleTags,
      doSelecttagGrade,
      doAddTagSubOption,
      doRemoveTagSubOption,
      doLoadStates,
      doUpdateAvailableCredits
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(compose(withStyles(styles), withWidth())(JobSummary))
);
