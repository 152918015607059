// @flow
import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Typography,
  withWidth
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import classNames from "classnames";
import React, { Component } from "react";
import compose from "recompose/compose";
import styles from "./styles";

class TopBar extends Component {
  handleActionButton = () => {
    const { actionButton } = this.props;
    actionButton();
  };

  handleReviewButton = () => {
    const { actionButtonReview } = this.props;
    actionButtonReview();
  }

  handleBackAction = () => {
    const { actionBack } = this.props;
    actionBack();
  };

  render() {
    const {
      classes,
      width,
      hasButton,
      hasButtonReview,
      pageTitle,
      buttonTitle,
      buttonReviewTitle,
      buttonColor,
      variant
    } = this.props;
    const isMobile = ["xs", "sm", "md"].includes(width);

    return (
      <AppBar
        position="fixed"
        color="default"
        elevation={1}
        className={classNames(classes.header, {
          [classes.headerMobile]: isMobile
        })}
      >
        <Toolbar>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            aria-label="Menu"
            onClick={() => this.handleBackAction()}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.title}>
            {pageTitle}
          </Typography>
          {hasButtonReview && (
            <div id="review_button" style={{ position: "relative", right: "20px" }}>
            <Button
              variant={variant ? variant : ""}
              color={buttonColor ? buttonColor : "inherit"}
              onClick={() => this.handleReviewButton()}
            >
              {buttonReviewTitle}
            </Button>
            </div>
          )}
          {hasButton && (
            <Button
              variant={variant ? variant : ""}
              color={buttonColor ? buttonColor : "inherit"}
              onClick={() => this.handleActionButton()}
            >
              {buttonTitle}
            </Button>
          )}
        </Toolbar>
      </AppBar>
    );
  }
}

export default compose(withStyles(styles), withWidth())(TopBar);
