// @flow
import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Grid, Paper, IconButton, Typography } from "@material-ui/core";

import {
  School as SchoolIcon,
  ArrowBack as ArrowBackIcon
} from "@material-ui/icons";

//Component styles
import styles from "./styles";

import Loading from "../../../containers/loading";

import { doLoadEducationLevels } from "../../../modules/form-data";
import { doConfirmCourseLevel } from "../../../modules/profile";

import SelectCourse from "../components/SelectCourse";
import SelectCourseSituation from "../components/SelectCourseSituation";

class ProfileAddQualificacao extends Component {
  constructor(props) {
    super(props);

    this.educationLevels = [];
    this.gridEducationLevels = [];

    this.state = {
      openDialogSelectCourse: false,
      openDialogSelectCourseSituation: false
    };
  }

  componentDidMount() {
    doLoadEducationLevels(this.props.token).then(data => {
      this.educationLevels = data;
      let aux = [];

      data.forEach((el, k) => {
        aux.push(el);

        if ((k + 1) % 3 === 0) {
          this.gridEducationLevels.push(aux);
          aux = [];
        }
      });

      this.forceUpdate();
    });
  }

  handleOpenDialogSelectCourse = element => {
    const { doConfirmCourseLevel } = this.props;

    doConfirmCourseLevel(element);

    if (
      element.value === "ENSINO_FUNDAMENTAL" ||
      element.value === "ENSINO_MEDIO"
    ) {
      this.setState({ openDialogSelectCourseSituation: true });
    } else {
      this.setState({ openDialogSelectCourse: true });
    }
  };

  handleOpenDialogSelectCourseSituation = () => {
    this.setState({
      openDialogSelectCourse: false,
      openDialogSelectCourseSituation: true
    });
  };

  handleBack = () => {
    const { history } = this.props;
    history.replace("/profile");
  };

  render() {
    const { classes } = this.props;
    const {
      openDialogSelectCourse,
      openDialogSelectCourseSituation
    } = this.state;

    if (this.gridEducationLevels.length === 0) {
      return <Loading />;
    } else {
      return (
        <div className={classes.root}>
          <IconButton
            onClick={this.handleBack}
            style={{ marginBottom: "20px" }}
          >
            <ArrowBackIcon />
            <Typography>Voltar</Typography>
          </IconButton>

          <Grid container spacing={3}>
            {this.gridEducationLevels.map((row, k1) => {
              return (
                <Fragment key={k1}>
                  {row.map((col, k2) => {
                    return (
                      <Grid key={k2} item xl={2} md={3} xs={6}>
                        <Paper
                          className={classes.optionCard}
                          style={{ cursor: "pointer" }}
                          onClick={() => this.handleOpenDialogSelectCourse(col)}
                        >
                          <SchoolIcon fontSize="large" />
                          <p>{col.text}</p>
                        </Paper>
                      </Grid>
                    );
                  })}
                </Fragment>
              );
            })}
          </Grid>

          {openDialogSelectCourse && (
            <SelectCourse
              OpenDialogSelectCourseSituation={() =>
                this.handleOpenDialogSelectCourseSituation()
              }
            />
          )}
          {openDialogSelectCourseSituation && <SelectCourseSituation />}
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  token: state.login.token
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doConfirmCourseLevel
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ProfileAddQualificacao))
);
