export default theme => ({
  root: {
    padding: theme.spacing(4)
  },
  progress: {
    margin: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%"
  }
});
