// @flow
import { LinearProgress, Grid, Typography, withWidth } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import Persona from "../../components/Persona";
import { doUpdateProfile, forceReloadProfile } from "../../modules/profile";
import { doGetProfile } from "../../modules/user";
import AccountDetails from "./components/AccountDetails";

//Component styles
import styles from "./styles";

class Profile extends Component {
  constructor(props) {
    super(props);
    let doLoading = true;

    this.getProfile();

    this.state = {
      loading: doLoading,
      profile: this.props.profile
    };
  }

  delay = (ms) => { new Promise(res => setTimeout(res, ms)) };

  getProfile = () => {

    this.delay(8000);

    doGetProfile(this.props.token)
      .then(p => {

        this.props.doUpdateProfile(p);
        const chatFinished = localStorage.getItem('conversa_finalizada');

        if (!p.initialized && !chatFinished) {
          this.props.history.replace("/profile");
        }

        // this.forceReloadProfile(this.props.token);

        this.setState({ profile: this.props.profile });

        this.setState({ loading: false });
        //this.forceReloadProfile(this.props.token);
      })
      .catch(error => {
        console.error(error);
      });

  };

  handleRefreshClick = () => {
    this.setState({
      loading: true
    });

    doGetProfile(this.props.token)
    .then(p => {
      this.props.doUpdateProfile(p);
      this.setState({ loading: false });
    })
    .catch(error => {
      console.error(error);
    });
  };

  componentDidMount = () => {
    forceReloadProfile(this.props.token);
  }

  chooseString = (school, jobs, name) => {

    const stringProfileIncomplete = <>{name}, falta pouco! Para que seu perfil fique visível às empresas, <b>complete pelo menos as informações de profissão e escolaridade</b>. As demais informações são complementares e ajudam o seu perfil a se destacar!</>
    const stringProfileJobIncomplete = <>{name}, falta pouco! Vejo que você já preencheu sua escolaridade! <b>Complete agora suas experiências profissionais e tenha seu perfil apto para ser encontrado.</b> As demais informações são complementares e ajudam o seu perfil a se destacar!</>
    const stringProfileSchoolIncomplete = <>{name}, falta pouco! Vejo que você já preencheu suas experiências profissionais! <b>Complete agora sua escolaridade e tenha seu perfil apto para ser encontrado.</b> As demais informações são complementares e ajudam o seu perfil a se destacar!</>
    const stringProfileComplete = <>{name}, tudo pronto! Vejo que você já preencheu todos os dados obrigatórios! <b>As demais informações complementares ajudam o seu perfil a ter mais destaque!</b></>

    if (school && jobs) {
      return stringProfileIncomplete;
    }

    if (school && !jobs) {
      return stringProfileSchoolIncomplete;
    }

    if (!school && jobs) {
      return stringProfileJobIncomplete;
    }

    return stringProfileComplete;
  }

  render() {
    const { classes, profile } = this.props;
    const { loading } = this.state;

    const isMobile = ["xs", "sm", "md"].includes(this.props.width);

    const school = profile.educations.length === 0;
    const jobs = profile.ocupations.length === 0;

    const chosenString = this.chooseString(school, jobs, profile.name);

    return (
      <div className={classes.root}>
        <Persona text={chosenString} />
        <Grid container spacing={4}>
          {loading ? (
            <div id="circular_progress" className={classes.progress}>
              <LinearProgress className={classes.progress} style={{ width: !isMobile ? "300px" : "" }} color="primary" />
              <Typography>Carregando...</Typography>
            </div>
          ) : (
            <AccountDetails profile={this.state.profile} />
          )}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.login.token,
  userInitialized: true,
  editing: state.profile.editing,
  qualificationOpen: state.profile.qualificationOpen,
  ocupationOpen: state.profile.ocupationOpen,
  specialNeedsOpen: state.profile.specialNeedsOpen,
  placesInterestOpen: state.profile.placesInterestOpen,
  contractingModalitiesOpen: state.profile.contractingModalitiesOpen,
  shiftPreferencesOpen: state.profile.shiftPreferencesOpen,
  skillsOpen: state.profile.skillsOpen,
  confirmOpen: state.profile.confirmOpen,
  confirmTitle: state.profile.confirmTitle,
  confirmText: state.profile.confirmText,
  confirmKey: state.profile.confirmKey,
  confirmObjId: state.profile.confirmObjId,
  profile: state.profile.profile,
  profileQualification: state.profile.profileQualification,
  profileOcupation: state.profile.profileOcupation,
  profileSpecialNeeds: state.profile.profileSpecialNeeds,
  profilePlacesInterest: state.profile.profilePlacesInterest,
  profileContractingModalities: state.profile.profileContractingModalities,
  profileShiftPreferences: state.profile.profileShiftPreferences,
  profileSkills: state.profile.profileSkills,
  loading: state.profile.loading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doUpdateProfile,
      forceReloadProfile
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(compose(withStyles(styles), withWidth())(Profile))
);
