// @flow
import {
  doLoadAcademicDegrees,
  doLoadContractingModalities,
  doLoadCoursesByType,
  doLoadEducationLevels,
  doLoadEducationSituations,
  doLoadExperiencePeriods,
  doLoadOcupations,
  doLoadPlacesInterests,
  doLoadSegments,
  doLoadShiftPreferences,
  doLoadSkills,
  doLoadSpecialNeeds,
  doLoadUser
} from "../modules/form-data";
import user from "../modules/user";
import { getBotMessage } from "./bot-messages";
import { getAgeFromUserBirthDate } from "./chronos";

export default function(state) {
  const userAge = getAgeFromUserBirthDate(state.profile.profile.dateOfBirth);

  const educationLevels = [];

  return [
    {
      id: "msg-start",
      bot: true,
      msg: replies => {
        return new Promise((resolve, reject) => {
          doLoadUser(state.login.token).then(data => {
            resolve(
              getBotMessage(
                "msg-start",
                { name: data.companyName ? data.companyName : data.name },
                userAge
              )
            );
          });
        });
      },
      next: "msg-start-2",
      lastMsg: false,
      waitTime: 800
    },
    {
      id: "msg-start-2",
      bot: true,
      msg: getBotMessage("msg-start-2", null, userAge),
      next: "msg-start-3",
      waitTime: 1200
    },
    {
      id: "msg-start-3",
      bot: true,
      msg: getBotMessage("msg-start-3", null, userAge),
      next: "msg-start-4",
      waitTime: 200
    },
    {
      id: "msg-start-4",
      bot: true,
      msg: getBotMessage("msg-start-4", null, userAge),
      next: "msg-agreement",
      waitTime: 800
    },
    {
      id: "msg-agreement",
      bot: true,
      msg: getBotMessage("msg-agreement", null, userAge),
      options: [
        {
          text: "Começar"
        }
      ],
      _next: "msg-continue-1",
      waitTime: 800
    },
    {
      id: "msg-continue-1",
      bot: true,
      msg: getBotMessage("msg-continue-1", null, userAge),
      next: "msg-location",
      waitTime: 400
    },
    {
      id: "msg-location",
      bot: true,
      msg: replies => {
        return new Promise((resolve, reject) => {
          doLoadUser(state.login.token).then(data => {
            resolve(
              getBotMessage(
                "msg-location",
                {
                  name: data.name,
                  city: data.city.name,
                  state: data.state.name
                },
                userAge
              )
            );
          });
        });
      },
      options: () => {
        return new Promise((resolve, reject) => {
          doLoadPlacesInterests(state.login.token).then(data => {
            resolve(data);
          });
        });
      },
      _next: "msg-continue-2",
      valueKey: ["LOCALIDADE", "caracteristicas"],
      evalValue: async opt => {
        let value = doLoadPlacesInterests(state.login.token).then(data => {
          return data.filter(el => el.sort <= opt.sort).map(el => el["value"]);
        });
        return value;
      },
      waitTime: 0
    },
    {
      id: "msg-continue-2",
      bot: true,
      msg: getBotMessage("msg-continue-2", null, userAge),
      next: "msg-continue-3",
      waitTime: 1000,
      styles: { text: { fontWeight: "bold" } }
    },
    {
      id: "msg-continue-3",
      bot: true,
      msg: getBotMessage("msg-continue-3", null, userAge),
      next: "msg-start-education",
      valueKey: undefined,
      evalValue: undefined,
      waitTime: 0
    },
    {
      id: "msg-start-education",
      bot: true,
      msg: getBotMessage("msg-start-education", null, user),
      options: () => {
        return new Promise((resolve, reject) => {
          doLoadEducationLevels(state.login.token).then(data => {
            resolve(data);
          });
        });
      },
      _next: opt => {

        if(educationLevels.includes(opt.value)) {
          return "msg-already-choose";
        }

        if (opt.value === "ENSINO_FUNDAMENTAL" && !educationLevels.includes(opt.value)) {
          educationLevels.push(opt.value);
          return "msg-elementary-school";
        } else if (opt.value === "ENSINO_MEDIO" && !educationLevels.includes(opt.value)) {
          educationLevels.push(opt.value);
          return "msg-high-school";
        } else if (opt.value === "OUTROS_CURSOS_E_TREINAMENTOS" && !educationLevels.includes(opt.value)) {
          return "msg-course-other";
        } else if (opt.value === "GRADUACAO" && !educationLevels.includes(opt.value)) {
          return "msg-graduation";
        } else if (opt.value === "MESTRADO" && !educationLevels.includes(opt.value)) {
          return "msg-master";
        } else if (opt.value === "MESTRADO_PROFISSIONAL" && !educationLevels.includes(opt.value)) {
          return "msg-master-professional";
        } else if (opt.value === "DOUTORADO" && !educationLevels.includes(opt.value)) {
          return "msg-doctorate";
        } else if (opt.value === "OUTRAS_ESPECIALIZACOES" && !educationLevels.includes(opt.value)) {
          return "msg-other-specialization";
        } else if (opt.value === "TECNICO_PROFISSIONALIZANTE" && !educationLevels.includes(opt.value)) {
          return "msg-technical-professional";
        } else {
          return "msg-continue-4";
        }
      },
      valueKey: (opt, replies) => {
        if (
          opt.value === "ENSINO_FUNDAMENTAL" ||
          opt.value === "ENSINO_MEDIO"
        ) {
          return opt.value;
        } else {
          return undefined;
        }
      },
      evalValue: async opt => {
        return opt;
      },
      waitTime: 0
    },
    {
      id: "msg-elementary-school",
      bot: true,
      msg: getBotMessage("msg-elementary-school", null, userAge),
      options: () => {
        return new Promise((resolve, reject) => {
          doLoadEducationSituations(state.login.token).then(data => {
            resolve(data);
          });
        });
      },
      _next: "msg-other-course",
      valueKey: ["QUALIFICACAO", "_INSTRUCAO"],
      evalValue: async (opt, replies) => {
        return {
          __TIPO: "ENSINO_FUNDAMENTAL",
          __CURSO_ID: -1,
          __NIVEIS: [
            {
              __NIVEL: "",
              __SITUACAO: opt.value
            }
          ]
        };
      },
      waitTime: 0
    },
    {
      id: "msg-high-school",
      bot: true,
      msg: getBotMessage("msg-high-school", null, userAge),
      options: () => {
        return new Promise((resolve, reject) => {
          doLoadEducationSituations(state.login.token).then(data => {
            resolve(data);
          });
        });
      },
      _next: "msg-other-course",
      valueKey: ["QUALIFICACAO", "_INSTRUCAO"],
      evalValue: async (opt, replies) => {
        return {
          __TIPO: "ENSINO_MEDIO",
          __CURSO_ID: -1,
          __NIVEIS: [
            {
              __NIVEL: "",
              __SITUACAO: opt.value
            }
          ]
        };
      },
      waitTime: 0
    },
    {
      id: "msg-other-course",
      bot: true,
      msg: getBotMessage("msg-other-course", null, userAge),
      options: [
        {
          text: "Sim"
        },
        {
          text: "Não"
        }
      ],
      _next: opt => {
        if (opt.text === "Sim") {
          return "msg-start-education";
        } else {
          return "msg-continue-4";
        }
      },
      valueKey: undefined,
      evalValue: undefined,
      waitTime: 0
    },
    {
      id: "msg-course-other",
      bot: true,
      msg: getBotMessage("msg-course-other", null, userAge),
      autocompleteOptions: firstChars => {
        return new Promise((resolve, reject) => {
          if (!firstChars || firstChars.length < 3) {
            resolve([]);
          } else {
            doLoadCoursesByType(
              state.login.token,
              "OUTROS_CURSOS_E_TREINAMENTOS",
              firstChars
            ).then(data => {
              if (data.length === 0) {
                data.push({
                  text: firstChars
                });
              }
              resolve(data);
            });
          }
        });
      },
      _next: "msg-course-other-situation",
      valueKey: "OUTROS_CURSOS_E_TREINAMENTOS",
      evalValue: async opt => {
        return opt;
      },
      waitTime: 0
    },
    {
      id: "msg-course-other-situation",
      bot: true,
      msg: getBotMessage("msg-course-other-situation", null, userAge),
      options: () => {
        return new Promise((resolve, reject) => {
          doLoadEducationSituations(state.login.token).then(data => {
            resolve(data);
          });
        });
      },
      _next: "msg-other-course-more",
      valueKey: ["QUALIFICACAO", "_INSTRUCAO"],
      evalValue: async (opt, replies) => {
        let course = replies
          .slice(0)
          .reverse()
          .find(el => el.valueKey === "OUTROS_CURSOS_E_TREINAMENTOS").value;

        return {
          __TIPO: "OUTROS_CURSOS_E_TREINAMENTOS",
          __CURSO_ID: course.id,
          __CURSO_NOME: course.text,
          __NIVEIS: [
            {
              __NIVEL: "",
              __SITUACAO: opt.value
            }
          ]
        };
      },
      waitTime: 0
    },
    {
      id: "msg-other-course-more",
      bot: true,
      msg: getBotMessage("msg-other-course-more", null, userAge),
      options: [
        {
          text: "Sim"
        },
        {
          text: "Não"
        }
      ],
      _next: opt => {
        if (opt.text === "Sim") {
          return "msg-course-other";
        } else {
          return "msg-other-course";
        }
      },
      valueKey: undefined,
      evalValue: undefined,
      waitTime: 0
    },
    {
      id: "msg-technical-professional",
      bot: true,
      msg: getBotMessage("msg-technical-professional", null, userAge),
      autocompleteOptions: firstChars => {
        return new Promise((resolve, reject) => {
          if (!firstChars || firstChars.length < 3) {
            resolve([]);
          } else {
            doLoadCoursesByType(
              state.login.token,
              "TECNICO_PROFISSIONALIZANTE",
              firstChars
            ).then(data => {
              if (data.length === 0) {
                data.push({
                  text: firstChars
                });
              }
              resolve(data);
            });
          }
        });
      },
      _next: "msg-technical-professional-situation",
      valueKey: "TECNICO_PROFISSIONALIZANTE",
      evalValue: async opt => {
        return opt;
      },
      waitTime: 0
    },
    {
      id: "msg-technical-professional-situation",
      bot: true,
      msg: getBotMessage("msg-technical-professional-situation", null, userAge),
      options: () => {
        return new Promise((resolve, reject) => {
          doLoadEducationSituations(state.login.token).then(data => {
            resolve(data);
          });
        });
      },
      _next: "msg-technical-professional-other-course",
      valueKey: ["QUALIFICACAO", "_INSTRUCAO"],
      evalValue: async (opt, replies) => {
        let course = replies
          .slice(0)
          .reverse()
          .find(el => el.valueKey === "TECNICO_PROFISSIONALIZANTE").value;

        return {
          __TIPO: "TECNICO_PROFISSIONALIZANTE",
          __CURSO_ID: course.id,
          __CURSO_NOME: course.text,
          __NIVEIS: [
            {
              __NIVEL: "",
              __SITUACAO: opt.value
            }
          ]
        };
      },
      waitTime: 0
    },
    {
      id: "msg-technical-professional-other-course",
      bot: true,
      msg: getBotMessage(
        "msg-technical-professional-other-course",
        null,
        userAge
      ),
      options: [
        {
          text: "Sim"
        },
        {
          text: "Não"
        }
      ],
      _next: opt => {
        if (opt.text === "Sim") {
          return "msg-technical-professional";
        } else {
          return "msg-other-course";
        }
      },
      valueKey: undefined,
      evalValue: undefined,
      waitTime: 0
    },
    {
      id: "msg-graduation",
      bot: true,
      msg: getBotMessage("msg-graduation", null, userAge),
      autocompleteOptions: firstChars => {
        return new Promise((resolve, reject) => {
          if (!firstChars || firstChars.length < 3) {
            resolve([]);
          } else {
            doLoadCoursesByType(
              state.login.token,
              "GRADUACAO",
              firstChars
            ).then(data => {
              if (data.length === 0) {
                data.push({
                  text: firstChars
                });
              }
              resolve(data);
            });
          }
        });
      },
      _next: async opt => {
        if (!opt || !opt.academicDegrees) {
          let degrees = await doLoadAcademicDegrees(state.login.token).then(
            data => {
              return {
                id: "msg-graduation-degrees",
                additionalData: {
                  academicDegrees: data
                }
              };
            }
          );

          return degrees;
        } else if (opt.academicDegrees.length === 1) {
          return "msg-graduation-situation";
        } else if (opt.academicDegrees.length > 1) {
          return {
            id: "msg-graduation-degrees",
            additionalData: {
              academicDegrees: opt.academicDegrees
            }
          };
        }
      },
      valueKey: "GRADUACAO",
      evalValue: async opt => {
        return opt;
      },
      waitTime: 0
    },
    {
      id: "msg-graduation-degrees",
      bot: true,
      msg: getBotMessage("msg-graduation-degrees", null, userAge),
      valueKey: "GRADUACAO-DEGREE",
      options: "academicDegrees",
      _next: "msg-graduation-situation",
      waitTime: 0
    },
    {
      id: "msg-graduation-situation",
      bot: true,
      msg: getBotMessage("msg-graduation-situation", null, userAge),
      options: () => {
        return new Promise((resolve, reject) => {
          doLoadEducationSituations(state.login.token).then(data => {
            resolve(data);
          });
        });
      },
      _next: "msg-other-course",
      valueKey: ["QUALIFICACAO", "_INSTRUCAO"],
      evalValue: async (opt, replies) => {
        let graduation = replies
          .slice(0)
          .reverse()
          .find(el => el.valueKey === "GRADUACAO").value;
        let graduationId = graduation ? graduation.id : null;
        let graduationText = graduation ? graduation.text : null;

        let degree = replies
          .slice(0)
          .reverse()
          .find(el => el.valueKey === "GRADUACAO-DEGREE");
        if (degree) degree = degree.value;
        else degree = graduation.academicDegrees[0].value;

        return {
          __TIPO: "GRADUACAO",
          __CURSO_ID: graduationId,
          __CURSO_NOME: graduationText,
          __NIVEIS: [
            {
              __NIVEL: degree,
              __SITUACAO: opt.value
            }
          ]
        };
      },
      waitTime: 0
    },
    {
      id: "msg-master",
      bot: true,
      msg: getBotMessage("msg-master", null, userAge),
      autocompleteOptions: firstChars => {
        return new Promise((resolve, reject) => {
          if (!firstChars || firstChars.length < 3) {
            resolve([]);
          } else {
            doLoadCoursesByType(state.login.token, "MESTRADO", firstChars).then(
              data => {
                if (data.length === 0) {
                  data.push({
                    text: firstChars
                  });
                }
                resolve(data);
              }
            );
          }
        });
      },
      _next: "msg-master-situation",
      valueKey: "MESTRADO",
      evalValue: async opt => {
        return opt;
      },
      waitTime: 0
    },
    {
      id: "msg-master-situation",
      bot: true,
      msg: getBotMessage("msg-master-situation", null, userAge),
      options: () => {
        return new Promise((resolve, reject) => {
          doLoadEducationSituations(state.login.token).then(data => {
            resolve(data);
          });
        });
      },
      _next: "msg-other-course",
      valueKey: ["QUALIFICACAO", "_INSTRUCAO"],
      evalValue: async (opt, replies) => {
        let master = replies
          .slice(0)
          .reverse()
          .find(el => el.valueKey === "MESTRADO").value;

        return {
          __TIPO: "MESTRADO",
          __CURSO_ID: master.id,
          __CURSO_NOME: master.text,
          __NIVEIS: [
            {
              __NIVEL: "",
              __SITUACAO: opt.value
            }
          ]
        };
      },
      waitTime: 0
    },
    {
      id: "msg-master-professional",
      bot: true,
      msg: getBotMessage("msg-master-professional", null, userAge),
      autocompleteOptions: firstChars => {
        return new Promise((resolve, reject) => {
          if (!firstChars || firstChars.length < 3) {
            resolve([]);
          } else {
            doLoadCoursesByType(
              state.login.token,
              "MESTRADO_PROFISSIONAL",
              firstChars
            ).then(data => {
              if (data.length === 0) {
                data.push({
                  text: firstChars
                });
              }
              resolve(data);
            });
          }
        });
      },
      _next: "msg-master-professional-situation",
      valueKey: "MESTRADO_PROFISSIONAL",
      evalValue: async opt => {
        return opt;
      },
      waitTime: 0
    },
    {
      id: "msg-master-professional-situation",
      bot: true,
      msg: getBotMessage("msg-master-professional-situation", null, userAge),
      options: () => {
        return new Promise((resolve, reject) => {
          doLoadEducationSituations(state.login.token).then(data => {
            resolve(data);
          });
        });
      },
      _next: "msg-other-course",
      valueKey: ["QUALIFICACAO", "_INSTRUCAO"],
      evalValue: async (opt, replies) => {
        let master = replies
          .slice(0)
          .reverse()
          .find(el => el.valueKey === "MESTRADO_PROFISSIONAL").value;

        return {
          __TIPO: "MESTRADO_PROFISSIONAL",
          __CURSO_ID: master.id,
          __CURSO_NOME: master.text,
          __NIVEIS: [
            {
              __NIVEL: "",
              __SITUACAO: opt.value
            }
          ]
        };
      },
      waitTime: 0
    },
    {
      id: "msg-other-specialization",
      bot: true,
      msg: getBotMessage("msg-other-specialization", null, userAge),
      autocompleteOptions: firstChars => {
        return new Promise((resolve, reject) => {
          if (!firstChars || firstChars.length < 3) {
            resolve([]);
          } else {
            doLoadCoursesByType(
              state.login.token,
              "OUTRAS_ESPECIALIZACOES",
              firstChars
            ).then(data => {
              if (data.length === 0) {
                data.push({
                  text: firstChars
                });
              }
              resolve(data);
            });
          }
        });
      },
      _next: "msg-other-specialization-situation",
      valueKey: "OUTRAS_ESPECIALIZACOES",
      evalValue: async opt => {
        return opt;
      },
      waitTime: 0
    },
    {
      id: "msg-other-specialization-situation",
      bot: true,
      msg: getBotMessage("msg-other-specialization-situation", null, userAge),
      options: () => {
        return new Promise((resolve, reject) => {
          doLoadEducationSituations(state.login.token).then(data => {
            resolve(data);
          });
        });
      },
      _next: "msg-other-course",
      valueKey: ["QUALIFICACAO", "_INSTRUCAO"],
      evalValue: async (opt, replies) => {
        let master = replies
          .slice(0)
          .reverse()
          .find(el => el.valueKey === "OUTRAS_ESPECIALIZACOES").value;

        return {
          __TIPO: "OUTRAS_ESPECIALIZACOES",
          __CURSO_ID: master.id,
          __CURSO_NOME: master.text,
          __NIVEIS: [
            {
              __NIVEL: "",
              __SITUACAO: opt.value
            }
          ]
        };
      },
      waitTime: 0
    },
    {
      id: "msg-doctorate",
      bot: true,
      msg: getBotMessage("msg-doctorate", null, userAge),
      autocompleteOptions: firstChars => {
        return new Promise((resolve, reject) => {
          if (!firstChars || firstChars.length < 3) {
            resolve([]);
          } else {
            doLoadCoursesByType(
              state.login.token,
              "DOUTORADO",
              firstChars
            ).then(data => {
              if (data.length === 0) {
                data.push({
                  text: firstChars
                });
              }
              resolve(data);
            });
          }
        });
      },
      _next: "msg-doctorate-situation",
      valueKey: "DOUTORADO",
      evalValue: async opt => {
        return opt;
      },
      waitTime: 0
    },
    {
      id: "msg-doctorate-situation",
      bot: true,
      msg: getBotMessage("msg-doctorate-situation", null, userAge),
      options: () => {
        return new Promise((resolve, reject) => {
          doLoadEducationSituations(state.login.token).then(data => {
            resolve(data);
          });
        });
      },
      _next: "msg-other-course",
      valueKey: ["QUALIFICACAO", "_INSTRUCAO"],
      evalValue: async (opt, replies) => {
        let master = replies
          .slice(0)
          .reverse()
          .find(el => el.valueKey === "DOUTORADO").value;

        return {
          __TIPO: "DOUTORADO",
          __CURSO_ID: master.id,
          __CURSO_NOME: master.text,
          __NIVEIS: [
            {
              __NIVEL: "",
              __SITUACAO: opt.value
            }
          ]
        };
      },
      waitTime: 0
    },
    {
      id: "msg-continue-4",
      bot: true,
      msg: getBotMessage("msg-continue-4", null, userAge),
      next: "msg-continue-5",
      waitTime: 1000
    },
    {
      id: "msg-continue-5",
      bot: true,
      msg: getBotMessage("msg-continue-5", null, userAge),
      next: "msg-job",
      waitTime: 1200
    },
    {
      id: "msg-job",
      bot: true,
      msg: getBotMessage("msg-job", null, userAge),
      autocompleteOptions: firstChars => {
        return new Promise((resolve, reject) => {
          if (!firstChars || firstChars.length < 3) {
            resolve([]);
          } else {
            doLoadOcupations(state.login.token, firstChars).then(data => {
              if (data.length === 0) {
                data.push({
                  text: firstChars
                });
              } else {
                resolve(data);
              }
            });
          }
        });
      },
      _next: "msg-experience-period",
      valueKey: "PROFISSAO",
      evalValue: async opt => {
        return opt;
      },
      waitTime: 0
    },
    {
      id: "msg-experience-period",
      bot: true,
      msg: replies => {
        return new Promise((resolve, reject) => {
          let profession = replies
            .slice(0)
            .reverse()
            .find(el => el.valueKey === "PROFISSAO").value;
          resolve(
            getBotMessage(
              "msg-experience-period",
              {
                profession: profession.text
              },
              userAge
            )
          );
        });
      },
      options: () => {
        return new Promise((resolve, reject) => {
          doLoadExperiencePeriods(state.login.token).then(data => {
            resolve(data);
          });
        });
      },
      _next: "msg-experience-segment",
      valueKey: "PROFISSAO-TEMPO",
      evalValue: async opt => {
        return opt;
      },
      waitTime: 0
    },
    {
      id: "msg-experience-segment",
      bot: true,
      msg: replies => {
        return new Promise((resolve, reject) => {
          let profession = replies
            .slice(0)
            .reverse()
            .find(el => el.valueKey === "PROFISSAO").value;
          resolve(
            getBotMessage(
              "msg-experience-segment",
              { profession: profession.text },
              userAge
            )
          );
        });
      },
      multipleOptions: () => {
        return new Promise((resolve, reject) => {
          doLoadSegments(state.login.token).then(data => {
            resolve(data);
          });
        });
      },
      _next: "msg-other-job",
      valueKey: ["PROFISSAO", "_PROFISSOES"],
      evalValue: async (value, replies) => {
        let profession = replies
          .slice(0)
          .reverse()
          .find(el => el.valueKey === "PROFISSAO").value;
        let professionTime = replies
          .slice(0)
          .reverse()
          .find(el => el.valueKey === "PROFISSAO-TEMPO").value;

        return {
          __CODIGO_CBO: profession.id,
          __NOME: profession.text,
          __TEMPO_EXPERIENCIA: professionTime.value,
          __SEGMENTOS: value
        };
      },
      waitTime: 0
    },
    {
      id: "msg-other-job",
      bot: true,
      msg: getBotMessage("msg-other-job", null, userAge),
      options: [
        {
          text: "Sim"
        },
        {
          text: "Não"
        }
      ],
      _next: opt => {
        if (opt.text === "Sim") {
          return "msg-job";
        } else {
          return "msg-contracting-modality";
        }
      },
      valueKey: undefined,
      evalValue: undefined,
      waitTime: 0
    },
    {
      id: "msg-contracting-modality",
      bot: true,
      msg: getBotMessage("msg-contracting-modality", null, userAge),
      multipleOptions: () => {
        return new Promise((resolve, reject) => {
          doLoadContractingModalities(state.login.token).then(data => {
            resolve(data);
          });
        });
      },
      _next: "msg-shift-preferences",
      valueKey: ["FORMA_CONTRATACAO"],
      evalValue: async opt => {
        return opt;
      },
      waitTime: 100
    },
    {
      id: "msg-shift-preferences",
      bot: true,
      msg: getBotMessage("msg-shift-preferences", null, userAge),
      multipleOptions: () => {
        return new Promise((resolve, reject) => {
          doLoadShiftPreferences(state.login.token).then(data => {
            resolve(data);
          });
        });
      },
      _next: "msg-skills",
      valueKey: ["DISPONIBILIDADE"],
      evalValue: async opt => {
        return opt;
      },
      waitTime: 0
    },
    {
      id: "msg-skills",
      bot: true,
      msg: getBotMessage("msg-skills", null, userAge),
      multipleOptions: () => {
        return new Promise((resolve, reject) => {
          doLoadSkills(state.login.token).then(data => {
            resolve(data);
          });
        });
      },
      maxSelected: 3,
      _next: "msg-special-needs",
      valueKey: ["HABILIDADE"],
      evalValue: async opt => {
        return opt;
      },
      waitTime: 0
    },
    {
      id: "msg-special-needs",
      bot: "true",
      msg: getBotMessage("msg-special-needs", null, userAge),
      options: [
        {
          text: "Sim"
        },
        {
          text: "Não"
        }
      ],
      _next: opt => {
        if (opt.text === "Sim") {
          return "msg-special-needs-1";
        } else {
          return "msg-finish-1";
        }
      },
      valueKey: undefined,
      evalValue: undefined,
      waitTime: 0
    },
    {
      id: "msg-special-needs-1",
      bot: "true",
      msg: getBotMessage("msg-special-needs-1", null, userAge),
      options: () => {
        return new Promise((resolve, reject) => {
          doLoadSpecialNeeds(state.login.token).then(data => {
            resolve(data);
          });
        });
      },
      _next: "msg-special-needs-2",
      valueKey: "NECESSIDADE_ESPECIAL",
      evalValue: async opt => {
        return opt;
      },
      waitTime: 0
    },
    {
      id: "msg-special-needs-2",
      bot: "true",
      msg: getBotMessage("msg-special-needs-2", null, userAge),
      freeText: true,
      _next: "msg-finish-1",
      valueKey: ["NECESSIDADES_ESPECIAIS"],
      evalValue: async (opt, replies) => {
        let lastSpecialNeed = replies
          .slice(0)
          .reverse()
          .find(el => el.valueKey === "NECESSIDADE_ESPECIAL").value;

        let countSpecialNeed = replies
          .filter(el => el.valueKey === "NECESSIDADE_ESPECIAL")
          .map(el => el.value.value)
          .filter(el => el === lastSpecialNeed.value).length;

        return countSpecialNeed === 1 ? lastSpecialNeed.value : null;
      },
      waitTime: 0
    },
    {
      id: "msg-finish-1",
      bot: true,
      msg: () => {
        return new Promise((resolve, reject) => {
          doLoadUser(state.login.token).then(data => {
            resolve(
              getBotMessage("msg-finish-1", { name: data.name }, userAge)
            );
          });
        });
      },
      next: "msg-finish-2",
      waitTime: 1000
    },
    {
      id: "msg-finish-2",
      bot: true,
      msg: getBotMessage("msg-finish-2", null, userAge),
      next: "msg-policies",
      waitTime: 1500
    },
    {
      id: "msg-policies",
      bot: true,
      msg: getBotMessage("msg-policies", null, userAge),
      options: [
        {
          text: "Aceito"
        }
      ],
      links: [
        {
          name: "\nTermos de Uso",
          value: "https://jobspot.com.br/termos-de-uso/"
        },
        {
          name: "\nPolítica de Privacidade",
          value: "https://jobspot.com.br/politica-de-privacidade/"
        },
        {
          name: "\nPolítica de Cookies",
          value: "https://jobspot.com.br/politica-de-cookies/"
        }
      ],
      _next: "msg-end",
      waitTime: 0
    },
    {
      id: "msg-resume-conversation",
      bot: true,
      msg: getBotMessage('msg-resume-conversation', null, userAge),
      options: [
        {
          text: "Continuar"
        }
      ],
      _next: () => {
        const currentItemRaw = localStorage.getItem('currentItem');
         //const parsedPipico = JSON.parse(currentItemRaw);
        const parsedCurrentItem = JSON.parse(currentItemRaw);
        return parsedCurrentItem[parsedCurrentItem.length - 2];
      }
    },
    {
      id: "msg-end",
      bot: true,
      msg: getBotMessage("msg-end", null, userAge),
      options: [
        {
          text: "Continuar"
        }
      ],
      _next: () => {

        // this.setaVariavelGlobalEAvancaParaProfile();
        localStorage.setItem('conversa_finalizada', true);
        localStorage.setItem('currentItem', []);
        localStorage.setItem('currentReplies', []);
        localStorage.setItem('currentItemObject', []);
        // localStorage.setItem('current_userState_profile', {});
        localStorage.setItem('current_userData_profile', {});

        // history.replace("/profile");
      },
      waitTime: 0
    },
    {
      id: "msg-already-choose",
      bot: true,
      msg: getBotMessage("msg-already-choose", null, userAge),
      next: "msg-start-education",
      waitTime: 500,
      valueKey: undefined,
      evalValue: undefined
    }
  ];
}
