// @flow
import React, { Component } from "react";

import Persona from "../../components/Persona";
import Grid from '@mui/material/Grid';
import { withWidth } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import { bindActionCreators } from "redux";
import styles from "./styles";

import {
    SubmitButton
} from "./styles";

import {
    doGetAllPlans
} from "../../modules/user";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import CardActions from '@mui/material/CardActions';

class Plans extends Component {
  constructor(props) {
    super(props);

    this.state = {
      jobs: [],
      loading: true,
      plans: [],
      plansTexts: [],
      plansImages: []
    };
  }

  componentDidMount() {
    this.loadPlans();
  }

  loadPlans = async () => {
    const allPlans = await doGetAllPlans(this.props.token);
    this.setState({ plans: allPlans });
  }

  render() {

    const { classes, width } = this.props;
    const isMobile = ["xs", "sm", "md"].includes(width);

    return (
        <div
            className={classNames(classes.root, {
              [classes.rootFull]: isMobile
            })}
        >
            <Persona
              text={`Nessa seção você pode alterar o plano de uso contratado para visualização dos perfis dos candidatos!`}
            />

        <Grid sx={{ flexGrow: 1 }} container spacing={3}>
            <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={3}>
                {this.state.plans.map((value) => (
                <Grid key={value} item>
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>

        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Plano {value.name}  { value.price === 0.0 ? '(Grátis)' : `(R$ ${value.price})` }
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Benefícios:
            <li>Gatos</li>
            <li>Cachorros</li>
            <li>Papagaios</li>
            <li>Coalas</li>
            <li>Golfinhos</li>
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <SubmitButton
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          disabled={ value.id === 1 ? true : false }
        >
          { value.id === 1 ? 'Plano atual' : 'Quero contratar!' }
        </SubmitButton>
      </CardActions>
    </Card>
    
                </Grid>
            ))}
            </Grid>
        </Grid>
        </Grid>
        </div>
    )
  }
}

const mapStateToProps = state => ({
    token: state.login.token,
    cloudMessagingToken: state.login.cloudMessagingToken,
    userInitialized: true,
    subscribedToOneSignal: state.user.subscribedToOneSignal,
    userData: state
  });
  
  const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(compose(withStyles(styles), withWidth())(Plans))
  );
