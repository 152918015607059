import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styles from "./styles";

class AudioPlayer extends Component {

  constructor(props) {
    super(props);
    this.audioPlayerRef = React.createRef();
  }

  state = {
    isPlaying: false,
    audioPlayer: {
      current: {
        duration: 0
      }
    },
    audioDuration: 0
  };

  updateDuration = () => {
    this.setState({ audioDuration: this.state.audioPlayer.current.duration });
  }

  handlePlayPause = () => {

    const preValue = this.state.isPlaying;

    this.setState({ isPlaying : !preValue });

    if (!preValue) {
      this.state.audioPlayer.current.play();
    } else {
      this.state.audioPlayer.current.pause();
    }
  }

  render() {

    const {
      audioPlayer
    } = this.state;

    return (
      <div>
          <audio ref={audioPlayer} src={this.props.reference} preload="metadata" controls controlsList="nodownload" />
      </div>
    )
  }
}

AudioPlayer.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  refreshData: PropTypes.func,
  loading: PropTypes.bool
};

const mapStateToProps = state => ({
  token: state.login.token,
  userInitialized: true,
  profile: state.profile.profile
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AudioPlayer))
);