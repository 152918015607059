export default theme => ({
  root: {},
  title: {
    fontWeight: "bold",
    fontSize: "16px"
    //color: theme.palette.background.topbar
  },
  secondaryHeading: {
    padding: "0px 15px"
  },
  summary: {
    height: "60px",
    "& div": {
      alignItems: "center"
    }
  },
  avatar: {
    color: "#fff",
    backgroundColor: "#bdcadc"
  },
  details: {
    padding: 0,
    paddingBottom: "15px",
    position: "relative",
    flexDirection: "column",
    justifyContent: "center"
  },
  fab: {
    margin: "0px 7px",
    padding: "0px 10px",
    maxWidth: "135px",
    boxShadow: "unset"
    // position:"absolute",
    // right: "10px",
    // bottom: "10px",
    // zIndex: "999"
  },
  editIcon: {
    marginRight: "8px"
  },
  chip: {
    margin: "3px 3px 0 0",
    fontSize: "11px",
    padding: "0px",
    height: "25px",
    backgroundColor: "#989ca0",
    borderRadius: "5px"
  },
  subListListItem: {
    padding: 0
  },
  subList: {
    padding: 0,
    width: "100%",
    "& li:last-child": {
      borderBottom: "0px"
    }
  },
  subListItem: {
    padding: "5px 0px 10px 0px",
    marginBottom: "4px",
    borderBottom: "1px solid #f1f1f1",
    width: "100%"
  },
  subListTitle: {
    marginBottom: 0,
    fontWeight: "bold",
    fontSize: "13px",
    color: "#444"
  }
});
